import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-warranty-history',
  templateUrl: './warranty-history.component.html',
  styleUrls: ['./warranty-history.component.css'],
})
export class WarrantyHistoryComponent implements OnInit {
  selectedTabIndex = 0;

  constructor() {}

  ngOnInit(): void {}

  onTabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }
}
