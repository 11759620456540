import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CosteventService } from '../costevent.service';
// import { NgForm, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
// import { File } from '../costeventModel';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
//import { SortByPipe } from '../../../sort-by.pipe';
import _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
import { ViewprojectinfoService } from '@app/pages/projects/projectinfo/viewprojectinfo/viewprojectinfo.service';
import { MESSAGE } from '@app/pages/projects/Task-management/constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
const swal: SweetAlert = _swal as any;
@Component({
  selector: 'app-cereview-log',
  templateUrl: './cereview-log.component.html',
  styleUrls: ['./cereview-log.component.css'],
})
export class CereviewLogComponent implements OnInit, OnDestroy {
  chats: any;
  files = [];
  isCollapsed = true;
  actions = [];
  viewPermission = [];
  allowedActions = [];
  allowComment = [];
  comments = [];
  commentsArray = [];
  hightlightStatus = [];
  array = [];
  collapse = [];
  myAction = '';
  selectedAction;
  filesArray;
  costeventID: string;
  formData: FormData;
  location: Location;

  placements: string[] = ['top', 'left', 'right', 'bottom'];
  confirmClicked = false;
  cancelClicked = false;
  spinnerstatus: boolean;
  subscriptions: Subscription[] = [];
  isAmountOverBudget = false;
  budgetItems = [];
  availableBudgetAmount = 0;
  actionForm: FormGroup;

  constructor(
    private _infoService: CosteventService,
    private route: ActivatedRoute,
    private viewProjectInfoService: ViewprojectinfoService,
    private fb: FormBuilder,
  ) {
    this.route.params.subscribe((res) => (this.costeventID = res.id));
  }

  @ViewChild('downloadFileLink') private downloadFileLink: ElementRef;

  ngOnInit() {
    this.getCeInfo();
    this.actionForm = this.fb.group({
      action: ['', Validators.required],
    });
  }

  onSelectionChange(action) {
    this.selectedAction = action;
  }

  async getBudgetInfo(projectID, budgetItemId, requestedAmount) {
    this.viewProjectInfoService
      .getProjectsBudgetInfo(projectID)
      .subscribe((responseList) => {
        if (responseList?.success) {
          if (responseList?.budgetInformation?.length > 0) {
            const budgetInfo = responseList?.budgetInformation.find(
              (budget) => budget.budgetItemId == budgetItemId,
            );
            this.availableBudgetAmount = budgetInfo?.availableAmount ?? 0;
            if (this.availableBudgetAmount) {
              this.availableBudgetAmount =
                this.availableBudgetAmount + requestedAmount;
              if (this.availableBudgetAmount < requestedAmount) {
                this.isAmountOverBudget = true;
              }
            }
          }
        }
      });
  }

  getCeInfo() {
    this.subscriptions.push(
      this._infoService.getCeInfo(this.costeventID).subscribe(async (chat) => {
        this.chats = chat;
        await this.getBudgetInfo(
          this.chats.projectID,
          this.chats.budgetItemId,
          this.chats.requestedAmount,
        );
        this.AllowedActions(this.chats.posts);
        this.FilesArray(this.chats.posts);
        this.Comments(this.chats.posts);
      }),
    );
  }

  updateCeInfo() {
    this.subscriptions.push(
      this._infoService.getCeInfo(this.costeventID).subscribe(
        (chat) => {
          this.chats = chat;
          this.AllowedActions(this.chats.posts);
          this.FilesArray(this.chats.posts);
          this.Comments(this.chats.posts);
          this.spinnerstatus = false;
          location.reload();
        },
        () => {},
      ),
    );
  }

  Comments(comments) {
    for (let i = 0; i < comments.length; i++) {
      this.comments.push(comments[i].comments);
    }
    for (let i = 0; i < comments.length; i++) {
      this.commentsArray.push(this.array);
      for (let j = 0; j < comments[i].comments.length; j++) {
        this.commentsArray[i].push(true);
        this.array = [];
      }
    }
  }

  AllowedActions(actions) {
    this.viewPermission = [];
    this.allowedActions = [];
    this.actions = [];
    this.allowComment = [];
    for (let i = 0; i < actions.length; i++) {
      this.actions.push(actions[i].allowedActions);
      this.hightlightStatus.push(false);
    }
    for (let j = 0; j < this.actions.length; j++) {
      for (let i = 0; i < this.actions[j].length; i++) {
        if (this.actions[j][i].actionName === 'View') {
          this.viewPermission.push(true);
          break;
        } else if (i === this.actions[j].length - 1) {
          this.viewPermission.push(false);
          break;
        }
      }
      for (let i = 0; i < this.actions[j].length; i++) {
        if (
          this.actions[j][i].actionName === 'Reply' ||
          this.actions[j][i].actionName === 'Approve' ||
          this.actions[j][i].actionName === 'Void' ||
          this.actions[j][i].actionName === 'Reset'
        ) {
          this.allowedActions.push(true);
          break;
        } else if (i === this.actions[j].length - 1) {
          this.allowedActions.push(false);
          break;
        }
      }

      for (let i = 0; i < this.actions[j].length; i++) {
        if (this.actions[j][i].actionName === 'Reply') {
          this.allowComment.push(true);
          break;
        } else if (i === this.actions[j].length - 1) {
          this.allowComment.push(false);
          break;
        }
      }
      for (let i = 0; i < this.actions[j].length; i++) {
        if (this.actions[j][i].actionName === 'Reply') {
          this.selectedAction = this.actions[j][i].actionId;
        }
      }
    }
  }

  FilesArray(files) {
    for (let i = 0; i < files.length; i++) {
      this.filesArray = [];
      this.files.push(this.filesArray);
    }
  }

  fileAdd(event, i) {
    const file = event.target.files[0];
    if (this.files[i].indexOf(file) === -1) {
      this.files[i].push(file);
    }
  }

  fileRemove(item, i) {
    const index: number = this.files[i].indexOf(item);
    if (index !== -1) {
      this.files[i].splice(index, 1);
    }
  }

  public async downloadFile(file): Promise<void> {
    const blob = await this._infoService.downloadResource(file.fileID);
    // if (window.navigator.msSaveOrOpenBlob) {
    //   window.navigator.msSaveBlob(blob, file.fileName);
    // }
    const url = window.URL.createObjectURL(blob);
    const link = this.downloadFileLink.nativeElement;
    link.href = url;
    link.download = file.fileName;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  async checkAmountOverBudget(): Promise<boolean> {
    if (this.selectedAction === 2) {
      if (this.isAmountOverBudget) {
        this.spinnerstatus = false;
        const value = await swal(MESSAGE.OVERBUDGET_MESSAGE, {
          buttons: {
            cancel: true,
            catch: {
              text: 'Yes',
              value: 'Yes',
            },
          },
        });
        if (value === 'Yes') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
    return true;
  }

  async Respond(reviewTypeId, commentTypeId, parentId, comment, i) {
    const isCheckAmountOverBudget = await this.checkAmountOverBudget();
    if (!isCheckAmountOverBudget) {
      return;
    }
    this.spinnerstatus = true;
    const formData = new FormData();
    formData.append('CostEventId', this.costeventID);
    formData.append('ReviewRequestTypeId', reviewTypeId);
    formData.append('Comment', comment.value);
    formData.append('CommentTypeId', commentTypeId);
    formData.append('ParentId', parentId);
    formData.append('ActionId', this.selectedAction);
    this.files[i].forEach((file) => formData.append('CommentFiles', file));
    this.subscriptions.push(
      this._infoService
        .respond(formData) // Send the FormData object with all data fields
        .subscribe(
          () => {
            if (this.selectedAction === 4) {
              location.reload();
            } else {
              this.updateCeInfo();
            }
          },
          () => {},
        ),
    );
  }

  ngOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }
}
