<div *ngIf="attachment.length > 0" class="detail-wrapper col-md-12">
  <div class="mt-1 no-error-message ccr-files">
    <span class="color"><b>ATTACHMENTS</b></span>
    <div class="panel panel-inverse mt-5">
      <div class="panel-body p-0" [hidden]="!showTable">
        <table
          id="dt-table"
          datatable
          [dtOptions]="dtOptions"
          [dtTrigger]="dtTrigger"
          class="table w-100 table-bordered table-hover overflow-hidden"
        >
          <thead>
            <tr>
              <th class="text-nowrap">File Name</th>
              <th class="text-nowrap">Uploaded By</th>
              <th class="text-nowrap">Uploaded Date</th>
              <th class="text-nowrap">Download</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let file of attachment">
              <td>{{ file.fileName }}</td>
              <td>{{ file.uploadedBy }}</td>
              <td>{{ file.uploadedOn | date: 'MM/dd/yyyy' }}</td>
              <td>
                <a #downloadFileLink></a>
                <button
                  class="dt-button buttons-print btn-color"
                  (click)="downloadFile(file)"
                >
                  <span>Download</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="detail-wrapper col-md-12">
  <span>Response(s)</span>
  <div class="col-md-2"></div>
  <div class="col-md-12 comment_box">
    <section class="mt-4 width">
      <div class="w-100">
        <div class="p-0 w-100 view-sc">
          <div class="panel-body border border-bottom p-0 w-100">
            <ul class="chat list-unstyled mb-0">
              <li
                *ngFor="let comment of comments"
                class="bg-post"
                [ngClass]="{
                  comment: comment.commentTypeId == 'Comment',
                  post: comment.commentTypeId == 'Post ',
                }"
              >
                <div
                  *ngIf="comment.commentTypeId == 'Post '"
                  class="review-head"
                >
                  <span> {{ comment.commentedBy }} </span>
                  <span> {{ comment.comment }} </span>
                  <span class="ml-2 commentedBy"> </span>
                  <span class="ml-2">
                    {{ comment.commentedOn | date: 'h: mm a, EEEE, MMM d, y' }}
                  </span>
                </div>
                <div
                  *ngIf="comment.commentTypeId == 'Comment'"
                  class="row"
                  [ngClass]="{ odd: odd, even: even }"
                >
                  <div
                    class="chat-body pl-4 pr-4 ml-4 w-100 mr-4 pt-2 pb-2"
                    id="summary"
                  >
                    <small class="float-right text-muted comment-box-width">
                      <span class="mr-2 comment-background">
                        {{ comment.commentedBy }}
                      </span>
                      <span class="commentedBy">{{
                        comment.commentedOn | date: 'h: mm a, EEEE, MMM d, y'
                      }}</span>
                    </small>
                    <div>
                      <p class="mt-2 mb-2 float-left">{{ comment.comment }}</p>
                    </div>
                    <div
                      class="comment-image"
                      *ngIf="
                        comment.commentFiles && comment.commentFiles.length > 0
                      "
                    >
                      <img
                        *ngFor="let url of comment.commentFiles; let i = index"
                        [src]="url.filePath"
                        class="imege-select"
                        height="90"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-footer bg-white border">
        <div class="row">
          <div class="col-12">
            <div class="input-group row justify-content-between m-0">
              <div class="file-uploading-wrapper col-8 comment_input">
                <span class="input-group-btn mt-4"> </span>
                <textarea
                  contenteditable="true"
                  data-gramm_editor="false"
                  spellcheck="false"
                  type="text"
                  class="form-control textarea-style"
                  name="comment"
                  [(ngModel)]="comment"
                  (ngModelChange)="checkCommentValue()"
                  rows="4"
                ></textarea>
                <div class="image-picker-wrapper">
                  <div class="image-picker1">
                    <i
                      class="fa fa-paperclip comment-color"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <input
                    name="imageUrl"
                    capture="camera"
                    class="form-control-file min-width"
                    name="picture"
                    type="file"
                    accept="image/*"
                    (change)="handleInputChange($event)"
                    multiple
                  />
                </div>
                <div class="image-wrapper">
                  <span *ngFor="let url of previewURL; let i = index">
                    <img [src]="url" class="imege-select rounded" height="90" />
                    <span class="delete-image" (click)="imageClick(i)">+</span>
                  </span>
                </div>
              </div>
              <div>
                <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  [(ngModel)]="isApproved"
                  class="example-radio-group"
                >
                  <mat-radio-button
                    *ngIf="
                      warrantyClaimXApprovalHistory.length > 0
                        ? isAllowedToApprove()
                        : ''
                    "
                    [value]="1"
                    class="example-margin mt-3 comment-width"
                    (click)="approveWarrantyClaim()"
                    >{{ approveButtonText }}</mat-radio-button
                  >
                  <mat-radio-button
                    *ngIf="
                      warrantyClaimXApprovalHistory.length > 0
                        ? isAllowedToReject()
                        : ''
                    "
                    [value]="2"
                    class="example-margin comment-width"
                    (click)="rejectWarrantyClaim()"
                    >{{ rejectButtonText }}</mat-radio-button
                  >
                </mat-radio-group>
              </div>
              <button
                type="button"
                mat-raised-button
                class="btn btn-info col-2 mt-4 margin-top"
                [disabled]="isCommentEmpty"
                (click)="addComment()"
              >
                Add Comment
                <i
                  class="fas fa-spinner fa-spin button-loader ml-5"
                  *ngIf="spinnerstatus"
                ></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div>
<div class="col-md-12 pr-0 detail-wrapper">
  <div class="panel-body border border-bottom p-0 w-100">
    <ul class="chat list-unstyled mb-0">
      <li *ngFor="let claimStatus of status" class="bg-post">
        <div class="review-head">
          <span> {{ claimStatus }} </span>
        </div>
      </li>
    </ul>
  </div>
</div>
