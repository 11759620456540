import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule, Routes } from '@angular/router';

import { DataTablesModule } from 'angular-datatables';

import { HeaderService } from '../../header/header.service';
import { MaterialModule } from 'app/material.module';
import { SidebarService } from 'app/sidebar/sidebar.service';
import { UsersService } from '../users/users.service';
import { LoginComponent } from './login.component';
import { LoginService } from './login.service';
const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    data: { title: 'Sign in Page' },
  },
];

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    MaterialModule,

    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    RouterModule.forChild(routes),
  ],
  providers: [LoginService, UsersService, SidebarService, HeaderService],
})
export class LoginModule {
  constructor() {}
}
