import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DocumentService } from '@app/pages/projects/Additional-feature/document.service';
import {
  DATE_FORMAT,
  MESSAGE,
} from '@app/pages/projects/Task-management/constants';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { WarrantyManagementStatusEnum } from '@app/enum/WarrantyClaimStatusEnum';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-common-comment-section',
  templateUrl: './common-comment-section.component.html',
  styleUrls: ['./common-comment-section.component.css'],
})
export class CommonCommentSectionComponent {
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions = {};
  dtTrigger: Subject<any> = new Subject();
  @Input() projectId = '';
  @Input() apiEndPoint = 'warranty';
  @Input() footer = '';
  comment = '';
  filesForUpload = [];
  previewURL = [];
  arrayOfSelectedFile = [];
  subscriptions: Subscription[] = [];
  comments = [];
  attachment = [];
  showTable = false;
  spinnerstatus = false;
  message = '';
  isCommentEmpty = false;
  files = [];
  uploadImage = false;
  id = '';
  isApproved = 0;
  userId: string;
  nextApproverUserId: string;
  nextApproverUserRole = [];
  shouldDisplayApproveAndRejectButton = true;
  status = [];
  currentStatus;
  showRadiobutton = false;
  statusType: any;
  statusEnum: WarrantyManagementStatusEnum;
  warrantyClaimXApprovalHistory = [];
  currentClaimApprovalStatus = -1;
  roleName: string;
  approveButtonText = 'Approve';
  rejectButtonText = 'Reject';

  constructor(
    private _documentService: DocumentService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.projectId = params['projectId'];
    });
    this.userId = localStorage.getItem('userID');
    this.roleName = localStorage.getItem('roles');
    this.getDocumentCommentsWithFilesList();
    this.getWarrantyClaimStatusHistory();
  }

  getDocumentCommentsWithFilesList() {
    this.subscriptions.push(
      this._documentService
        .getClaimCommentsWithFile(this.id)
        .subscribe((res) => {
          this.comments = res.claimCommentsWithFilesItemList;
          this.attachment = res.claimFilesList;
          this.showTable = true;
          this.isCommentEmpty = true;
        }),
    );
  }

  async addComment() {
    try {
      const formData = new FormData();
      if (this.filesForUpload && this.filesForUpload.length) {
        for (let i = 0; i < this.filesForUpload.length; i++) {
          formData.append('files', this.filesForUpload[i]);
          formData.append('claimId', this.id);
        }
      }

      const commentResponse = await this._documentService
        .addClaimComments(this.id, this.comment)
        .toPromise();

      this.comments = commentResponse.commentList;
      if (this.filesForUpload.length > 0) {
        await this._documentService.addClaimFiles(formData).toPromise();
        this.uploadImage = true;
        this.message = MESSAGE.FILE_UPLOADED;
        this.filesForUpload = [];
        this.previewURL = [];
        this.files = [];
        this.getDocumentCommentsWithFilesList();
      } else {
        this.message = MESSAGE.COMMENT_ADDED;
        this.comment = '';
        this.files = [];
        this.previewURL = [];
        this.isCommentEmpty = true;
        this.getDocumentCommentsWithFilesList();
      }
      this.checkCommentValue();
      this.comment = '';
      this.filesForUpload = [];
      this.previewURL = [];
    } catch (error) {}
  }

  checkCommentValue(): void {
    this.isCommentEmpty = !this.comment.trim();
  }

  public downloadFile(file): void {
    this._documentService
      .downloadWarrantyDocument(file.fileId, file.claimId, file.projectId)
      .subscribe(
        (blob: Blob) => {
          this.initiateFileDownload(blob, file.fileName);
        },
        (error) => {
          console.error('Error downloading file:', error);
        },
      );
  }

  onSelectFile(event) {
    if (
      event.target.files &&
      event.target.files?.length > 0 &&
      event.target.files[0]
    ) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.previewURL.push(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  handleInputChange(event) {
    this.onSelectFile(event);
    this.arrayOfSelectedFile = [];
    this.filesForUpload = [...this.filesForUpload, ...event.target.files];
    this.arrayOfSelectedFile.push(event.target.files);
  }

  imageClick(index) {
    this.previewURL.splice(index, 1);
    this.filesForUpload.splice(index, 1);
  }

  private initiateFileDownload(blob: Blob, fileName: string): void {
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    document.body.appendChild(link);
    link.setAttribute('style', 'display: none');
    link.href = url;
    link.download = fileName;
    link.click();
    window.URL.revokeObjectURL(url);
    link.remove();
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      this.filesForUpload = Array.from(input.files);
    }
  }

  approveWarrantyClaim() {
    if (this.warrantyClaimXApprovalHistory.length > 0) {
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 2 &&
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'] == 'Construction Manager / General Contractor'
      ) {
        this.currentClaimApprovalStatus = 4;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 3 &&
        (this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'].includes('Architect / Engineer') ||
          this.warrantyClaimXApprovalHistory[
            this.warrantyClaimXApprovalHistory.length - 1
          ]['currentAssigneeRole'].includes('Project Manager'))
      ) {
        this.currentClaimApprovalStatus = 1;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 1 &&
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'] == 'Construction Manager / General Contractor'
      ) {
        this.currentClaimApprovalStatus = 2;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 4 &&
        (this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'].includes('Architect / Engineer') ||
          this.warrantyClaimXApprovalHistory[
            this.warrantyClaimXApprovalHistory.length - 1
          ]['currentAssigneeRole'].includes('Project Manager'))
      ) {
        this.currentClaimApprovalStatus = 5;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 6 &&
        (this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'].includes('Architect / Engineer') ||
          this.warrantyClaimXApprovalHistory[
            this.warrantyClaimXApprovalHistory.length - 1
          ]['currentAssigneeRole'].includes('Project Manager'))
      ) {
        this.currentClaimApprovalStatus = 2;
      }
    }
    this.subscriptions.push(
      this._documentService
        .approveRejectWarrantyClaim(
          this.id,
          this.userId,
          this.currentClaimApprovalStatus,
          (this.statusType = 1),
          this.projectId,
        )
        .subscribe(() => {
          this.isApproved = 0;
          this._documentService.currentWarrantyClaimStatus.emit(
            this.currentClaimApprovalStatus,
          );
          this.getWarrantyClaimStatusHistory();
          if (
            this.warrantyClaimXApprovalHistory[
              this.warrantyClaimXApprovalHistory.length - 1
            ]['currentStatus'] == 2 &&
            this.warrantyClaimXApprovalHistory[
              this.warrantyClaimXApprovalHistory.length - 1
            ]['currentAssigneeRole'] ==
              'Construction Manager / General Contractor'
          ) {
            this.shouldDisplayApproveAndRejectButton = true;
          } else {
            this.shouldDisplayApproveAndRejectButton = false;
          }
          this.showRadiobutton = false;
          this.message = 'Approved';
        }),
    );
  }

  rejectWarrantyClaim() {
    if (this.warrantyClaimXApprovalHistory.length > 0) {
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 2 &&
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'] == 'Construction Manager / General Contractor'
      ) {
        this.currentClaimApprovalStatus = 3;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 1 &&
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'] == 'Construction Manager / General Contractor'
      ) {
        this.currentClaimApprovalStatus = 3;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 3 &&
        (this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'].includes('Architect / Engineer') ||
          this.warrantyClaimXApprovalHistory[
            this.warrantyClaimXApprovalHistory.length - 1
          ]['currentAssigneeRole'].includes('Project Manager'))
      ) {
        this.currentClaimApprovalStatus = 1;
      }
      if (
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'] == 4 &&
        (this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssigneeRole'].includes('Architect / Engineer') ||
          this.warrantyClaimXApprovalHistory[
            this.warrantyClaimXApprovalHistory.length - 1
          ]['currentAssigneeRole'].includes('Project Manager'))
      ) {
        this.currentClaimApprovalStatus = 6;
      }
    }
    this.subscriptions.push(
      this._documentService
        .approveRejectWarrantyClaim(
          this.id,
          this.userId,
          this.currentClaimApprovalStatus,
          (this.statusType = 2),
          this.projectId,
        )
        .subscribe(() => {
          this.isApproved = 0;
          this._documentService.currentWarrantyClaimStatus.emit(
            this.currentClaimApprovalStatus,
          );
          this.getWarrantyClaimStatusHistory();
          this.shouldDisplayApproveAndRejectButton = false;
          this.showRadiobutton = false;
          this.message = 'Rejected';
        }),
    );
  }

  shouldDisplayRadioButton(): boolean {
    if (this.warrantyClaimXApprovalHistory.length > 0) {
      this.showRadiobutton = true;
      return true;
    }
  }

  getWarrantyClaimStatusHistory() {
    this.status = [];
    this.warrantyClaimXApprovalHistory = [];
    this.subscriptions.push(
      this._documentService
        .getWarrantyClaimStatusHistory(this.id)
        .subscribe((res) => {
          this.warrantyClaimXApprovalHistory = res.warrantyClaimApprovalHistory;
          const datePipe = new DatePipe('en-US');

          if (
            this.warrantyClaimXApprovalHistory &&
            this.warrantyClaimXApprovalHistory.length > 0
          ) {
            this.warrantyClaimXApprovalHistory.forEach((history) => {
              let sentence = '';
              const createdOn = datePipe.transform(
                history.createdOn,
                DATE_FORMAT,
              );
              switch (history.currentStatus) {
                case WarrantyManagementStatusEnum['Review Pending']:
                  sentence = `Claim is Created By ${history.createdByRole} On ${createdOn}`;
                  this.status.push(sentence);
                  this.approveButtonText = 'Send for Review';
                  break;

                case WarrantyManagementStatusEnum['Claim In Review']:
                  sentence = `${history.previousAssigneeRole} sent Claim for CM/GC Review On ${createdOn}`;
                  this.status.push(sentence);
                  this.approveButtonText = 'Accepts Claim';
                  this.rejectButtonText = 'Rejects Claim';
                  break;

                case WarrantyManagementStatusEnum['Work In Progress']:
                  sentence = `${history.previousAssigneeRole} Accepted the Claim On ${createdOn}`;
                  this.status.push(sentence);
                  this.approveButtonText = 'Work Completed';
                  this.rejectButtonText = 'Rejects Claim';
                  break;

                case WarrantyManagementStatusEnum['Claim Rejected']:
                  sentence = `${history.previousAssigneeRole} Rejected the Claim On ${createdOn}`;
                  this.status.push(sentence);
                  this.approveButtonText = 'Send for Review';
                  break;

                case WarrantyManagementStatusEnum['Work In Review']:
                  sentence = `${history.previousAssigneeRole} has updated the Claim & sent it for PM/AE Review On ${createdOn}`;
                  this.status.push(sentence);
                  this.approveButtonText = 'Work is Satisfactory';
                  this.rejectButtonText = 'Work is Not Satisfactory';
                  break;

                case WarrantyManagementStatusEnum['Completed']:
                  sentence = `${history.previousAssigneeRole} has accepted the work as completed according to Quality Standards On ${createdOn}`;
                  this.status.push(sentence);
                  break;

                case WarrantyManagementStatusEnum['Cancel Claim']:
                  sentence = `${history.previousAssigneeRole} has cancelled the Claim On ${createdOn}`;
                  this.status.push(sentence);
                  break;

                case WarrantyManagementStatusEnum['Work Rejected']:
                  sentence = `${history.previousAssigneeRole} has rejected the work according to Quality Standards On ${createdOn}`;
                  this.status.push(sentence);
                  this.approveButtonText = 'Work In Progress';
                  break;
              }
            });

            // Handle the current claim approval status based on the latest entry
            const latestStatus =
              this.warrantyClaimXApprovalHistory[
                this.warrantyClaimXApprovalHistory.length - 1
              ].currentStatus;
            switch (latestStatus) {
              case WarrantyManagementStatusEnum['Review Pending']:
                this.currentClaimApprovalStatus = 1;
                break;
              case WarrantyManagementStatusEnum['Claim In Review']:
                this.currentClaimApprovalStatus = 2;
                break;
              case WarrantyManagementStatusEnum['Work Rejected']:
                this.currentClaimApprovalStatus = 2;
                break;
            }
          }
        }),
    );
  }

  isAllowedToApprove(): boolean {
    if (
      this.shouldDisplayApproveAndRejectButton &&
      this.warrantyClaimXApprovalHistory &&
      this.warrantyClaimXApprovalHistory.length > 0
    ) {
      const lastAssignee =
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssignee'];
      const assigneeArray = lastAssignee.split(',');
      return assigneeArray.includes(this.userId);
    }
    return false;
  }

  isAllowedToReject(): boolean {
    if (
      (this.shouldDisplayApproveAndRejectButton &&
        this.warrantyClaimXApprovalHistory &&
        this.warrantyClaimXApprovalHistory.length > 0 &&
        this.roleName.includes('Construction Manager / General Contractor')) ||
      this.roleName.includes('Project Manager') ||
      this.roleName.includes('Architect')
    ) {
      const lastAssignee =
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentAssignee'];
      const status =
        this.warrantyClaimXApprovalHistory[
          this.warrantyClaimXApprovalHistory.length - 1
        ]['currentStatus'];
      const assigneeArray = lastAssignee.split(',');

      if (
        assigneeArray.includes(this.userId) &&
        status === 1 &&
        this.roleName.includes('Construction Manager / General Contractor')
      ) {
        return true;
      }
      if (
        assigneeArray.includes(this.userId) &&
        status === 4 &&
        (this.roleName.includes('Architect') ||
          this.roleName.includes('Project Manager'))
      ) {
        return true;
      }
    }
    return false;
  }
}
