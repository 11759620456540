import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MatSnackBar,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { AssetsService } from '@app/pages/master/assets/assets.service';
import { DocumentService } from '@app/pages/projects/Additional-feature/document.service';
import { MESSAGE } from '@app/pages/projects/Task-management/constants';
import { SearchModel } from '@app/shared/common/search-request-model';
import { Subscription } from 'rxjs';
import _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = _swal as any;

@Component({
  selector: 'app-warranty-claim-model',
  templateUrl: './warranty-claim-model.component.html',
  styleUrls: ['./warranty-claim-model.component.css'],
  providers: [AssetsService],
})
export class WarranytyClaimModelComponent implements OnInit, OnDestroy {
  claimForm: FormGroup;
  filesForUpload = [];
  message = '';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  imageSrc = '';
  fileinput = false;
  subscriptions: Subscription[] = [];
  availableAssets = [];
  projectId = '';
  previewURL = [];
  assetDetail = [];
  arrayOfSelectedFile = [];
  uploadImage = false;
  files = [];
  searchModel: SearchModel = new SearchModel();
  assetListSetting = {};
  selectedAsset = [];
  assetId = '';
  isAddedFromHistory = false;
  assetName = '';
  roleName = '';
  isMaintenanceManager = false;

  constructor(
    private dialogRef: MatDialogRef<WarranytyClaimModelComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      projectId: string;
      assetId?: string;
      assetName: string;
      isAllowedToEdit: boolean;
      claimId: string;
      isMaintenanceManager: boolean;
      mode: number;
    },
    private fb: FormBuilder,
    private _documentService: DocumentService,
    private _assetsService: AssetsService,
    private snackBar: MatSnackBar,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.projectId = this.data.projectId;
    this.assetId = this.data.assetId;
    this.isAddedFromHistory = this.data.isAllowedToEdit;
    this.assetName = this.data.assetName;
    this.assetListSetting = {
      singleSelection: true,
      idField: 'assetId',
      textField: 'name',
      allowSearchFilter: true,
    };
    this.roleName = localStorage.getItem('roles');
    if (this.roleName.includes('Maintenance Manager')) {
      this.getProjectAssetDetail();
    }
    this.getProjectAsset(event);
    this.claimForm = this.fb.group({
      assetId: ['', Validators.required],
      assignee: ['', Validators.required],
      description: ['', Validators.required],
      dueDate: ['', Validators.required],
      claimId: ['', Validators.required],
      type: ['', Validators.required],
      repairCost: [{ value: '', disabled: true }],
    });
    if (this.data.assetId) {
      this.selectedAsset = [
        { assetId: this.assetId, name: this.data?.assetName },
      ];
    }
    if (this.data?.mode === 3 || this.data?.mode === 1) {
      this.subscriptions.push(
        this._documentService
          .getWarrantyClaimsDetail(
            this.data?.claimId != null ? this.data?.claimId : this.data.assetId,
          )
          .subscribe((claim: any) => {
            this.claimForm.patchValue({
              // assetId: claim.claimDetail.assetId,
              assetId: [
                {
                  assetId: claim.claimDetail.assetId,
                  name: claim.claimDetail.assetName,
                },
              ],
              assignee: claim.claimDetail.assignee,
              description: claim.claimDetail.claimDescription,
              dueDate: new Date(claim.claimDetail.dueDate), // Assuming claim.dueDate is a valid date string
              claimId: claim.claimDetail.claimNumber,
              type: claim.claimDetail.type,
              repairCost: claim.claimDetail.repairCost,
            });
            this.onClaimTypeChange(this.claimForm.get('type')?.value);
            // this.selectedAsset = claim.claimDetail.assetId;
          }),
      );
    }
    if (this.roleName.includes('Maintenance Manager')) {
      this.isMaintenanceManager = true;
    }
    if (
      !this.data?.isMaintenanceManager &&
      this.data?.isMaintenanceManager !== undefined
    ) {
      this.isMaintenanceManager = false;
    }
  }

  getProjectAssetDetail() {
    if (this.data.assetId) {
      this._assetsService
        .getProjectAssetDetail(this.data.assetId)
        .subscribe((res) => {
          if (res) {
            this.assetDetail = res.assetDetail;
            this.selectedAsset = [
              {
                assetId: this.data?.assetId,
                name: this.assetDetail?.['assetName'],
              },
            ];
            this.claimForm.patchValue({
              assetId: [
                {
                  assetId: this.data?.assetId,
                  name: this.assetDetail?.['assetName'],
                },
              ],
            });
          }
        });
    }
  }

  getProjectAsset(event) {
    this.searchModel.PageSize = 0;
    if (event?.first !== undefined) {
      this.searchModel.PageNumber =
        Math.floor(event.first / this.searchModel.PageSize) + 1;
    } else {
      this.searchModel.PageNumber = this.searchModel.PageNumber || 1; // Default to 1 if not set
    }
    this._documentService
      .getProjectAsset(
        this.searchModel.PageSize,
        this.searchModel.SearchText,
        this.searchModel.SortBy,
        this.searchModel.PageNumber,
        this.searchModel.SortOrder,
        this.searchModel.Filter,
        this.projectId,
      )
      .subscribe((res) => {
        if (res) {
          this.availableAssets = res['assets'];
          if (this.availableAssets && this.availableAssets.length > 0)
            this.availableAssets = this.availableAssets.filter(
              (a) => a.isActive,
            );
          if (res['pageInfo']) {
            this.searchModel.PageNumber = res['pageInfo'].currentPage; // Current page is already correct
            this.searchModel.PageSize = res['pageInfo'].pageSize;
            this.searchModel.TotalPages = res['pageInfo'].totalPages;
            this.searchModel.TotalCount = res['pageInfo'].totalCount;
          }
        }
      });
  }

  onsubmit() {
    if (this.claimForm.valid) {
      const claimData = this.claimForm.value;
      claimData.dueDate = this.datePipe.transform(
        claimData.dueDate,
        'yyyy-MM-dd',
      );
      claimData.projectId = this.data.projectId;
      claimData.assetId = this.selectedAsset[0]?.assetId;
      if (this.data?.mode === 3 || this.data?.mode === 1) {
        this._documentService
          .updateClaim(
            claimData,
            this.data?.assetId ? this.data?.assetId : this.data?.claimId,
          )
          .subscribe((res) => {
            if (res && res?.success) {
              this.dialogRef.close(true);
              this.snackBar.open(MESSAGE.FORM_UPDATED, '', {
                duration: 500,
                verticalPosition: 'top',
                panelClass: 'custom-snackbar-class',
              });
            } else {
              swal('No Active warranty found for selected asset.');
            }
          });
      } else {
        this._documentService.submitClaim(claimData).subscribe((res) => {
          if (res && res?.success) {
            this.dialogRef.close(true);
            this.snackBar
              .open(MESSAGE.FORM_SUBMITTED, '', {
                duration: 500,
                verticalPosition: 'top',
                panelClass: 'custom-snackbar-class',
              })
              .afterDismissed()
              .subscribe(() => {
                this.dialogRef.close(true); // Closing the dialog after snackbar is dismissed
              });
          } else {
            swal('No Active warranty found for selected asset.');
          }
        });
      }
      this.addComment();
      this.getProjectAsset(null);
    }
  }

  onSelectFile(event) {
    if (
      event.target.files &&
      event.target.files?.length > 0 &&
      event.target.files[0]
    ) {
      for (let i = 0; i < event.target.files.length; i++) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.previewURL.push(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  handleInputChange(event) {
    this.onSelectFile(event);
    this.arrayOfSelectedFile = [];
    this.filesForUpload = [...this.filesForUpload, ...event.target.files];
    this.arrayOfSelectedFile.push(event.target.files);
  }

  imageClick(index) {
    this.previewURL.splice(index, 1);
    this.filesForUpload.splice(index, 1);
  }

  async addComment() {
    try {
      const formData = new FormData();
      if (this.filesForUpload && this.filesForUpload.length) {
        for (let i = 0; i < this.filesForUpload.length; i++) {
          formData.append('files', this.filesForUpload[i]);
        }
      }
      if (this.filesForUpload.length > 0) {
        await this._documentService.addClaimFiles(formData).toPromise();
        this.uploadImage = true;
        this.message = MESSAGE.FILE_UPLOADED;
        this.filesForUpload = [];
        this.previewURL = [];
        this.files = [];
      }
      this.filesForUpload = [];
      this.previewURL = [];
    } catch (error) {}
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }
  }

  onClaimTypeChange(claimType: number): void {
    const costControl = this.claimForm.get('repairCost');
    if (claimType === 0) {
      costControl?.enable();
    } else if (claimType === 1) {
      costControl?.disable();
    }
  }
}
