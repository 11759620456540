import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dollerSign',
})
export class DollerSign implements PipeTransform {
  transform(value: any): any {
    let finalValue =
      '$' + (value * 1).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return finalValue;
  }
}
