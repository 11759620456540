import { Component, OnInit, OnDestroy } from '@angular/core';
import { WarrantyClaimSearchModel } from './claim-search-request';
import { ActivatedRoute, Router } from '@angular/router';
import { AssetsService } from '@app/pages/master/assets/assets.service';
import { Subscription } from 'rxjs';
import {
  MESSAGE,
  ROUTE_CONSTANT,
} from '@app/pages/projects/Task-management/constants';
import { WorkBook, utils, writeFile, WorkSheet } from 'xlsx';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import jsPDF from 'jspdf';
import { MatDialog } from '@angular/material/dialog';
import { SendemailComponent } from '@app/pages/GeneralItems/sendemail/sendemail.component';
import { FILE_NAMES } from '@app/pages/GeneralItems/sendemail/fileNames.helper';
import * as Function from '../../../../../../../shared/common/function';
import { DatePipe } from '@angular/common';
import { Permissions } from '@app/pages/utils.model';
import { WarranytyClaimModelComponent } from '@app/pages/projects/document-pay-application-category/warranty-documents/warranty-forms/warranty-claim-model/warranty-claim-model.component';
require('jspdf-autotable');

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrls: ['./claim.component.css'],
})
export class ClaimComponent implements OnInit, OnDestroy {
  searchModel: WarrantyClaimSearchModel = new WarrantyClaimSearchModel();
  assetId = '';
  assetDetailWarranties = [];
  warrantyRepairHistory = [];
  projectId: string;
  subscriptions: Subscription[] = [];
  function = Function;
  assetName = '';
  searchInput = '';
  isAlloweToCreateClaim = true;
  isAllowViewClaim = false;
  isAllowToEditClaim = false;
  permissions: Permissions;

  constructor(
    private route: ActivatedRoute,
    private _assetService: AssetsService,
    private router: Router,
    public dialog: MatDialog,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.projectId = params['id'] ? params['id'] : MESSAGE.EMPTY_GUID;
        this.assetId = params['assetId'];
      }),
    );
    this.getAssetWarrantyRepairHistory();
    this.isAlloweToCreateClaim = this.permissions.Maintenence_Manager_Add_Claim;
    this.isAllowViewClaim = this.permissions.VIEW_WARRANTY_CLAIM;
    this.isAllowToEditClaim = this.permissions.EDIT_WARRANTY_CLAIM;
  }

  viewWarrantyClaimsDetails(claimid: string) {
    this.router.navigate([ROUTE_CONSTANT.WARRANTY_CLAIMS_DETAILS, claimid]);
  }

  getAssetWarrantyRepairHistory() {
    this.assetId = this.route.snapshot.paramMap.get('assetId');
    this.subscriptions.push(
      this._assetService
        .getAssetWarrantyRepairHistory(this.assetId)
        .subscribe((list) => {
          this.assetDetailWarranties = list.warrantyClaimHistory;
          this.warrantyRepairHistory = this.assetDetailWarranties['claims'];
          this.assetName = this.warrantyRepairHistory[0]?.assetName;
        }),
    );
  }

  addWarrantyClaimToAsset() {
    let roles = localStorage?.getItem('roles').includes('Maintenance Manager');
    if (roles) {
      projectId = MESSAGE.EMPTY_GUID;
    } else {
      var projectId = JSON.parse(localStorage?.getItem('projectDetails'))[
        'projectID'
      ];
    }
    const dialogRef = this.dialog.open(WarranytyClaimModelComponent, {
      width: '800px',
      height: '450px',
      panelClass: 'custom-modalbox',
      data: {
        projectId: projectId,
        assetId: this.assetId,
        isAllowedToEdit: false,
        assetName: this.assetName,
        isMaintenanceManager: false,
        mode: 2,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getAssetWarrantyRepairHistory();
      }
    });
  }

  exportData(format: 'csv' | 'excel'): void {
    const finalList = this.getFinalList();
    if (finalList.length > 0) {
      if (format === 'csv') {
        const options = {
          fieldSeparator: ',',
          quoteStrings: '"',
          decimalseparator: '.',
          showLabels: true,
          showTitle: true,
          title: 'Warranty History Info',
          useBom: true,
          headers: [
            'Claim Number',
            'Type',
            'Status',
            'Due Date / Completed Date',
            'Ball In Court',
            'Cost',
          ],
        };
        new Angular5Csv(finalList, 'Warranty History', options);
      } else if (format === 'excel') {
        const fileName = 'WarrantyHistoryExcelSheet.xlsx';
        const ws: WorkSheet = utils.json_to_sheet(finalList);
        const wb: WorkBook = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Sheet1');
        writeFile(wb, fileName);
      }
    }
  }

  getFinalList(): any[] {
    return this.warrantyRepairHistory.map((warranty) => ({
      'Claim Number': warranty.claimNumber,
      Type: this.getTypeText(warranty.type),
      Status: this.getStatus(warranty.status),
      'Due Date / Completed Date': this.datePipe.transform(
        warranty.dueDate,
        'MM/dd/YYYY',
      ),
      'Ball In Court': warranty.responsibleParty,
      Cost: warranty.repairCost,
    }));
  }

  getTypeText(type: number): string {
    switch (type) {
      case 0:
        return 'Repair';
      case 1:
        return 'Warranty';
      default:
        return 'Unknown Type';
    }
  }

  pdf() {
    const finalList = this.getFinalList();
    const currentdate = new Date();
    const datetime = `${currentdate.getMonth() + 1}/${currentdate.getDate()}/${currentdate.getFullYear()}`;
    const runDate = `Run Date: ${datetime}`;
    let doc = new jsPDF('l', 'pt');
    doc.setFontSize(5);
    const columns = [
      'Claim Number',
      'Type',
      'Status',
      'Due Date / Completed Date',
      'Ball In Court',
      'Cost',
    ];
    const rows = finalList.map((item) => [
      item['Claim Number'],
      item['Type'],
      item['Status'],
      item['Due Date / Completed Date'],
      item['Ball In Court'],
      item['Cost'],
    ]);

    const headerFooter = (data) => {
      doc.setFontSize(9);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.text(
        `${runDate}\nWarranty Claim History`,
        data.settings.margin.left,
        50,
      );
    };

    const options = {
      beforePageContent: headerFooter,
      headerStyles: { fontSize: 9 },
      bodyStyles: { fontSize: 8 },
      margin: { top: 80 },
    };

    (doc as any).autoTable(columns, rows, options);

    const totalPages = doc.internal.getNumberOfPages();
    for (let i = 1; i <= totalPages; i++) {
      doc.setFontSize(9);
      doc.setTextColor(40);
      doc.setPage(i);
      doc.text(750, 70, `Page: ${i} of ${totalPages}`);
      if (i === totalPages) {
        doc.text(210, 820, 'IFM360 Program Management Module');
      }
    }

    doc.save('WarrantyClaimHistory.pdf');
  }

  onPreview() {
    const finalList = this.getFinalList();
    let printContents = `<table>
                           <tr>
                             <th>Claim Number</th>
                             <th>Type</th>
                             <th>Status</th>
                             <th>Due Date / Completed Date</th>
                             <th>Ball In Court</th>
                             <th>Cost</th>
                           </tr>`;
    finalList.forEach((data) => {
      printContents += `<tr>
                         <td>${data['Claim Number']}</td>
                         <td>${data['Type']}</td>
                         <td>${data['Status']}</td>
                         <td>${data['Due Date / Completed Date']}</td>
                         <td>${data['Ball In Court']}</td>
                         <td>${data['Cost']}</td>
                       </tr>`;
    });
    printContents += '</table>';
    const WindowObject = window.open(
      '',
      'PrintWindow',
      'width=auto,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes',
    );
    const htmlData = `<html>
                        <head>
                          <title>OFM</title>
                          <style type="text/css">
                            table th, table td {
                              font-size: 8px;
                              border:1px solid #000;
                            }
                            th, td {
                              text-align:center;
                            }
                          </style>
                        </head>
                        <body>${printContents}</body>
                      </html>`;
    WindowObject.document.writeln(htmlData);
    WindowObject.document.close();
    WindowObject.focus();
    WindowObject.print();
    setTimeout(() => {
      WindowObject.close();
    }, 5000);
  }

  copy() {
    const element = document.querySelector('#docreview') as HTMLElement | null;
    if (element) {
      this.selectNode(element);
    } else {
      console.error('Element not found');
    }
  }

  selectNode(node: HTMLElement) {
    let range = document.createRange();
    range.selectNodeContents(node);
    let select = window.getSelection();
    if (select) {
      select.removeAllRanges();
      select.addRange(range);
      document.execCommand('copy');
    }
  }

  sendMail() {
    const finalList = this.getFinalList();
    const header = 'Warranty Claim History Information';
    const sendMailModal = this.dialog.open(SendemailComponent, {
      width: '550px',
      data: {
        fileName: FILE_NAMES.WARRANTY_HISTORY,
        selectedData: finalList,
        header,
      },
    });
    sendMailModal.afterClosed().subscribe(() => {
      // Handle after the modal is closed
    });
  }

  getStatus(currentStatus) {
    let status = '';
    switch (currentStatus) {
      case 0:
        status = 'Review Pending';
        break;
      case 1:
        status = 'Claim In Review';
        break;
      case 2:
        status = 'Work In Progress';
        break;
      case 3:
        status = 'Claim Rejected';
        break;
      case 4:
        status = 'Work In Review';
        break;
      case 5:
        status = 'Completed';
        break;
      case 6:
        status = 'Work Rejected';
        break;
      case 7:
        status = 'Claim Cancelled';
        break;
    }

    return status;
  }

  editWarrantyClaim(event) {
    this.dialog
      .open(WarranytyClaimModelComponent, {
        data: {
          projectId: this.projectId,
          claimId: event?.claimId,
          isAllowedToEdit: true,
          mode: 3,
        },
        width: '800px',
        height: '450px',
        panelClass: 'custom-modalbox',
        autoFocus: false,
        closeOnNavigation: true,
        disableClose: true,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.getAssetWarrantyRepairHistory();
        }
      });
  }

  viewClaimsDetails(claimNumber) {
    this.router.navigateByUrl(
      ROUTE_CONSTANT.WARRANTY_CLAIMS_DETAILS +
        this.projectId +
        '/' +
        claimNumber,
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
