<section class="mt-4" *ngIf="chats && chats.posts" id="comments">
  <div *ngFor="let chat of chats.posts; let i = index" class="w-100 mt-5">
    <div
      title="Activity Review Log- {{ chat.comments[0].requestTypeName }}"
      *ngIf="viewPermission[i]"
      class="w-100"
    >
      <div class="p-0 w-100">
        <div class="panel-body border border-bottom p-0 w-100">
          <ul class="chat list-unstyled mb-0">
            <li
              *ngFor="let comment of chat.comments"
              class="bg-post"
              [ngClass]="{
                comment: comment.commentTypeName == 'Comment',
                post: comment.commentTypeName == 'Post',
                'p-0 m-0': hightlightStatus[i],
              }"
            >
              <!-- <span class="chat-img col-1">
                          <img src="{{chat.image}}" alt="User Avatar" class="rounded-circle">
                        </span> -->
              <div
                *ngIf="comment.commentTypeName == 'Post'"
                class="review-head"
                [ngClass]="{ highlight: hightlightStatus[i] }"
                (click)="hightlightStatus[i] = !hightlightStatus[i]"
              >
                <span> {{ comment.commentedByName }} </span>
                <span> {{ comment.comment }} </span>
                <span class="ml-2"> {{ comment.actionDisplayName }} </span>
                <span class="ml-2">
                  {{ comment.commentedOn | date: 'MM/dd/YYYY' }}
                </span>
              </div>
              <div
                *ngIf="comment.commentTypeName == 'Comment'"
                class="row"
                [ngClass]="{
                  odd: odd,
                  even: even,
                  'd-none': hightlightStatus[i],
                }"
              >
                <!--  <div class="chat-img col-md-1 text-center mb-sm-2 mb-md-0">
                             <img src="#" alt="User Avatar" class="rounded-circle">
                          </div> -->
                <div
                  class="chat-body pl-4 pr-4 ml-4 w-100 mr-4 pt-2 pb-2"
                  id="summary"
                >
                  <small class="float-right text-muted">
                    <span class="mr-2" style="color: #ff6000">
                      {{ comment.actionDisplayName }}
                    </span>
                    {{ comment.commentedOn | date: 'MM/dd/YYYY' }}
                  </small>
                  <strong class="primary-font">{{
                    comment.commentedByName
                  }}</strong>

                  <p
                    class="mt-2 mb-2"
                    [ngClass]="{ collapse: !commentsArray[i][j] }"
                  >
                    {{ comment.comment }}
                  </p>
                  <a
                    class="text-primary read"
                    *ngIf="comment.comment"
                    style="cursor: pointer"
                    [ngClass]="{
                      collapsed: !commentsArray[i][j],
                      'd-xl-none': comment.comment.length < 900,
                      'd-lg-none': comment.comment.length < 180,
                      'd-md-none': comment.comment.length < 150,
                      'd-sm-none': comment.comment.length < 130,
                      'd-xs-none': comment.comment.length < 60,
                    }"
                    data-toggle="collapse"
                    (click)="commentsArray[i][j] = !commentsArray[i][j]"
                    aria-expanded="false"
                    aria-controls="collapseSummary"
                  ></a>

                  <ul
                    class="list-unstyled list-inline pb-1 border-top pt-1 mt-1"
                    *ngIf="comment.files.length"
                  >
                    <li
                      class="list-inline-item p-0"
                      *ngFor="let file of comment.files"
                    >
                      <a #downloadFileLink></a>
                      <button
                        class="bg-transparent border-0 buttons-print file"
                      >
                        {{ file.fileName }}
                      </button>
                      <img
                        class="caption-files-layout"
                        src="../../../../../assets/icon/download.svg"
                        alt="download"
                        (click)="downloadFile(file)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="card-footer bg-white border"
          *ngIf="chat.allowedActions.length > 1"
        >
          <div class="row">
            <div class="col-12">
              <div
                class="input-group row justify-content-between m-0"
                *ngIf="allowedActions[i]"
              >
                <span class="input-group-btn mt-4" [hidden]="!allowComment[i]">
                  <button
                    class="browse btn btn-default input-lg mr-2 border-0 bg-white"
                  >
                    <i class="fas fa-paperclip"></i>
                    <input
                      type="file"
                      name="file"
                      id="file"
                      multiple=""
                      (change)="fileAdd($event, i)"
                    />
                  </button>
                </span>
                <textarea
                  type="text"
                  class="form-control col-9"
                  name="comment"
                  #comment="ngModel"
                  ngModel
                  rows="4"
                  [hidden]="!allowComment[i]"
                ></textarea>
                <form [formGroup]="actionForm">
                  <mat-radio-group class="m-4 mt-5" formControlName="action">
                    <span *ngFor="let action of chat.allowedActions">
                      <mat-radio-button
                        class="example-margin ml-2"
                        *ngIf="
                          action.actionName == 'Approve' ||
                          action.actionName == 'Void' ||
                          action.actionName == 'Reset'
                        "
                        [value]="action.actionId"
                        (change)="onSelectionChange(action.actionId)"
                      >
                        {{ action.actionName }}
                      </mat-radio-button>
                    </span>
                  </mat-radio-group>
                  <button
                    type="button"
                    mat-raised-button
                    class="btn btn-info mr-3"
                    [disabled]="!actionForm.valid"
                    (click)="
                      Respond(
                        chat.comments[0].reviewRequestTypeId,
                        chat.comments[0].commentTypeId,
                        chat.comments[0].parentCommentId,
                        comment,
                        i
                      )
                    "
                  >
                    Submit
                    <i
                      class="fas fa-spinner fa-spin button-loader"
                      *ngIf="spinnerstatus"
                    ></i>
                  </button>
                </form>
                <ul
                  class="list-unstyled list-inline col-12 mb-0 ml-4 pl-4"
                  *ngIf="files[i].length"
                >
                  <li
                    class="text-secondary list-inline-item mt-2 ml-0 d-inline-block"
                    *ngFor="let file of files[i]"
                  >
                    <span> {{ file.name }} </span>
                    <span (click)="fileRemove(file, i)" class="mr-2 files">
                      <i class="fas fa-times"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
