import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { environment } from 'environments/environment';
// import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { LoaderService } from './core/core-loader.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  dateF: string;
  // page settings
  pageSettings: any = {
    pageSidebarMinified: false,
    pageContentFullHeight: false,
    pageContentFullWidth: false,
    pageContentInverseMode: false,
    pageWithFooter: false,
    pageWithoutSidebar: false,
    pageSidebarRight: false,
    pageSidebarRightCollapsed: false,
    pageSidebarTwo: false,
    pageSidebarWide: false,
    pageSidebarTransparent: false,
    pageSidebarLight: false,
    pageTopMenu: false,
    pageEmpty: false,
    pageBodyWhite: false,
    pageMobileSidebarToggled: false,
    pageMobileSidebarFirstClicked: false,
    pageMobileSidebarRightToggled: false,
    pageMobileSidebarRightFirstClicked: false,
  };
  pageHasScroll;
  showLoader: boolean;
  isLogin = true;

  constructor(
    // private slimLoadingBarService: SlimLoadingBarService,
    private router: Router,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private changeDedectionRef: ChangeDetectorRef,
    private titleService: Title,
    // private autoLogoutService: AutoLogoutService
  ) {
    this.loaderService.status.subscribe((val) => {
      this.showLoader = val;
    });
    // this.autoLogoutService.initInterval();

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.clearSettings();
        this.isLogin = e.url.includes('login');
      }
    });
  }

  ngOnInit() {
    console.log(
      'Environment Name ==> ',
      environment.env + ' ' + environment.apiBaseUrl,
    );
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let route: ActivatedRoute = this.router.routerState.root;
          let routeTitle = '';
          while (route!.firstChild) {
            route = route.firstChild;
          }
          if (route.snapshot.data['title']) {
            routeTitle = route!.snapshot.data['title'];
          }
          return routeTitle;
        }),
      )
      .subscribe((title: string) => {
        if (title) {
          this.titleService.setTitle(`IFM360 | ${title}`);
        }
      });
  }
  // window scroll
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 0) {
      this.pageHasScroll = true;
    } else {
      this.pageHasScroll = false;
    }
  }

  // clear settings to default
  clearSettings() {
    this.pageSettings.pageSidebarMinified = false;
    (this.pageSettings.pageContentFullHeight = false),
      (this.pageSettings.pageContentFullWidth = false);
    this.pageSettings.pageWithFooter = false;
    this.pageSettings.pageWithoutSidebar = false;
    this.pageSettings.pageSidebarRight = false;
    this.pageSettings.pageSidebarRightCollapsed = false;
    this.pageSettings.pageSidebarTwo = false;
    this.pageSettings.pageSidebarWide = false;
    this.pageSettings.pageSidebarTransparent = false;
    this.pageSettings.pageSidebarLight = false;
    this.pageSettings.pageTopMenu = false;
    this.pageSettings.pageEmpty = false;
    this.pageSettings.pageBodyWhite = false;
    this.pageSettings.pageContentInverseMode = false;
    this.pageSettings.pageMobileSidebarToggled = false;
    this.pageSettings.pageMobileSidebarFirstClicked = false;
    this.pageSettings.pageMobileRightSidebarToggled = false;
    this.pageSettings.pageMobileRightSidebarFirstClicked = false;
    this.renderer.removeClass(document.body, 'bg-white');
  }

  // set page settings
  setPageSettings(settings) {
    // tslint:disable-next-line:forin
    for (const option in settings) {
      this.pageSettings[option] = settings[option];
      if (option === 'pageBodyWhite' && settings[option] === true) {
        this.renderer.addClass(document.body, 'bg-white');
      }
    }
  }

  // set page minified
  onToggleSidebarMinified(): void {
    if (this.pageSettings.pageSidebarMinified) {
      this.pageSettings.pageSidebarMinified = false;
    } else {
      this.pageSettings.pageSidebarMinified = true;
    }
  }

  // set page right collapse
  onToggleSidebarRight(): void {
    if (this.pageSettings.pageSidebarRightCollapsed) {
      this.pageSettings.pageSidebarRightCollapsed = false;
    } else {
      this.pageSettings.pageSidebarRightCollapsed = true;
    }
  }

  // hide mobile sidebar
  onHideMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      if (this.pageSettings.pageMobileSidebarFirstClicked) {
        this.pageSettings.pageMobileSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileSidebarToggled = false;
      }
    }
  }

  // toggle mobile sidebar
  onToggleMobileSidebar(): void {
    if (this.pageSettings.pageMobileSidebarToggled) {
      this.pageSettings.pageMobileSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileSidebarToggled = true;
      this.pageSettings.pageMobileSidebarFirstClicked = true;
    }
  }

  // hide right mobile sidebar
  onHideMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      if (this.pageSettings.pageMobileRightSidebarFirstClicked) {
        this.pageSettings.pageMobileRightSidebarFirstClicked = false;
      } else {
        this.pageSettings.pageMobileRightSidebarToggled = false;
      }
    }
  }

  // toggle right mobile sidebar
  onToggleMobileRightSidebar(): void {
    if (this.pageSettings.pageMobileRightSidebarToggled) {
      this.pageSettings.pageMobileRightSidebarToggled = false;
    } else {
      this.pageSettings.pageMobileRightSidebarToggled = true;
      this.pageSettings.pageMobileRightSidebarFirstClicked = true;
    }
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }
}
