<p-table
  styleClass="p-datatable-gridlines"
  class="table w-100"
  #dt
  id="warrantyhistory"
  [value]="warrantyRepairHistory"
  [paginator]="true"
  [rows]="10"
  [rowsPerPageOptions]="[5, 10, 15, 20]"
  [globalFilterFields]="[
    'claimNumber',
    'type',
    'status',
    'dueDate',
    'responsibleParty',
    'repairCost',
  ]"
  [totalRecords]="searchModel.TotalCount"
  pageLinks="3"
  sortField="claimNumber,type,status,dueDate,responsibleParty,repairCost"
>
  <ng-template pTemplate="caption">
    <div class="row funding-wrapper" data-html2canvas-ignore="true">
      <div class="right_content">
        <div>
          <div class="h-100 col search-container">
            <input
              class="h-100"
              type="text"
              pInputText
              size="20"
              placeholder="Search"
              [(ngModel)]="searchInput"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
            />
          </div>
        </div>
        <div>
          <div class="share_btn ml-3">
            <button
              class="btn_share mr-3 text-white"
              mat-flat-button
              [matMenuTriggerFor]="menu"
            >
              Share <i class="fa fa-angle-down"></i>
            </button>
            <button
              *ngIf="isAlloweToCreateClaim"
              class="btn-create"
              color="primary"
              mat-raised-button
              (click)="addWarrantyClaimToAsset()"
            >
              Add New Claim
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                type="button"
                class="btn-system-layout share-action-items"
                pButton
                iconPos="left"
                label="CSV"
                (click)="exportData('csv')"
              >
                CSV
              </button>
              <button
                mat-menu-item
                type="button"
                class="btn-system-layout share-action-items"
                pButton
                iconPos="left"
                label="Excel"
                (click)="exportData('excel')"
              >
                EXCEL
              </button>
              <button
                mat-menu-item
                type="button"
                class="btn-system-layout share-action-items"
                pButton
                iconPos="left"
                label="PDF"
                (click)="pdf()"
              >
                PDF
              </button>
              <button
                mat-menu-item
                type="button"
                class="btn-system-layout share-action-items"
                pButton
                iconPos="left"
                label="Print"
                (click)="onPreview()"
              >
                PRINT
              </button>
              <button
                mat-menu-item
                type="button"
                class="btn-system-layout share-action-items"
                pButton
                iconPos="left"
                label="Copy"
                (click)="copy()"
              >
                COPY
              </button>
              <button
                mat-menu-item
                type="button"
                class="btn-system-layout share-action-items"
                pButton
                iconPos="left"
                label="Email"
                (click)="sendMail()"
              >
                EMAIL
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr class="text-center">
      <th class="text-center" [pSortableColumn]="'claimNumber'">
        Claim Number
        <p-sortIcon [field]="'claimNumber'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'type'">
        Type
        <p-sortIcon [field]="'type'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'repairCost'">
        Cost
        <p-sortIcon [field]="'repairCost'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'status'">
        Status
        <p-sortIcon [field]="'status'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'dueDate'">
        Due Date / Completed Date
        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'responsibleParty'">
        Ball In Court
        <p-sortIcon [field]="'responsibleParty'"></p-sortIcon>
      </th>
      <th
        class="text-center"
        *ngIf="isAllowViewClaim || isAllowToEditClaim"
        data-html2canvas-ignore="true"
      >
        Action
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-warranty let-rowIndex="rowIndex">
    <tr class="text-align">
      <td class="text-align">
        <a (click)="viewWarrantyClaimsDetails(warranty?.claimNumber)">{{
          warranty?.claimNumber
        }}</a>
      </td>
      <td class="text-align">
        {{
          warranty?.type === 0
            ? 'Repair'
            : warranty?.type === 1
              ? 'Warranty'
              : ''
        }}
      </td>
      <td class="text-align">
        {{ warranty?.repairCost }}
      </td>
      <td class="text-align">
        {{ getStatus(warranty?.status) }}
      </td>
      <td class="text-align">{{ warranty?.dueDate | date: 'MM/dd/yyyy' }}</td>
      <td
        class="text-align"
        [title]="warranty?.responsibleParty"
        [ngClass]="'truncate-text'"
      >
        {{ warranty?.responsibleParty }}
      </td>
      <td *ngIf="isAllowViewClaim || isAllowToEditClaim">
        <i
          *ngIf="isAllowViewClaim"
          class="fa fa-eye text-success cursor-pointer ml-4"
          aria-hidden="true"
          (click)="viewClaimsDetails(warranty?.claimId)"
        ></i>
        <i
          *ngIf="isAllowToEditClaim"
          class="fa fa-pen text-success cursor-pointer ml-4"
          aria-hidden="true"
          (click)="editWarrantyClaim(warranty)"
        ></i>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">No data available</td>
    </tr>
  </ng-template>
</p-table>
