import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import _swal from 'sweetalert';
import { SweetAlert } from 'sweetalert/typings/core';
const swal: SweetAlert = _swal as any;

@Injectable()
export class CoreHttpService {
  constructor(private http: HttpClient) {}
  httpGetRequest<TResponse>(
    url: string,
    header?: HttpHeaders,
  ): Observable<TResponse> {
    return this.http.get(url, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  httpGetWithoutLoaderRequest<TResponse>(
    url: string,
    header?: HttpHeaders,
  ): Observable<TResponse> {
    // this.loderService.display(false);
    return this.http.get(url, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }
  httpGetWithoutLoaderRequestForCloseLoader<TResponse>(
    url: string,
    header?: HttpHeaders,
  ): Observable<TResponse> {
    return this.http.get(url, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  httpPostWithoutLoaderRequest<TRequest, TResponse>(
    url: string,
    user: TRequest,
    header?: any,
  ): Observable<TResponse> {
    return this.http.post(url, user, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  httpPostRequest<TRequest, TResponse>(
    url: string,
    user: TRequest,
    header?: any,
  ): Observable<TResponse> {
    return this.http.post(url, user, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  httpPostRequestWithParams<TRequest, TResponse>(
    url: string,
    user: TRequest,
    header?: any,
    params?: any,
  ): Observable<TResponse> {
    return this.http.post(url, user, { headers: header, params: params }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }
  httpPostRequestWithParamsWithoutLoader<TRequest, TResponse>(
    url: string,
    user: TRequest,
    header?: any,
    params?: any,
  ): Observable<TResponse> {
    return this.http.post(url, user, { headers: header, params: params }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  httpPutRequestWithParams<TRequest, TResponse>(
    url: string,
    user: TRequest,
    header?: any,
    params?: any,
  ): Observable<TResponse> {
    return this.http.put(url, user, { headers: header, params: params }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  httpPutRequest<TRequest, TResponse>(
    url: string,
    user: TRequest,
    header?: any,
  ): Observable<TResponse> {
    return this.http.put(url, user, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  //  httpDownloadRequest<Blob>(url: string, header?: HttpHeaders): Observable<Blob> {
  //     this.loderService.display(true);
  //     return this.http.get(url, {
  //         responseType: 'blob' as 'json'
  //       })
  //         .pipe(
  //             map(res => {
  //                 this.loderService.display(false);
  //                 // .toPromise();
  //                 return <Blob>res
  //             }),
  //             catchError(this.customErrorHandlors));
  //  }

  httpDeleteRequest<TRequest, TResponse>(
    url: string,
    id?: TRequest,
    header?: any,
  ): Observable<TResponse> {
    return this.http.delete(url + '/' + id, { headers: header }).pipe(
      map((res) => {
        return <TResponse>res;
      }),
      catchError(this.customErrorHandlors),
    );
  }

  customErrorHandlors = (error: HttpErrorResponse | any) => {
    if (!!error.error) {
      swal(error.error.message);
    }
    return observableThrowError(error.message);
  };
}
