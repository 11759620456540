<h2 mat-dialog-title class="title">Send Email</h2>
<mat-divider></mat-divider>
<section class="selection-menu px-4">
  <h5>Choose files</h5>
  <mat-checkbox (change)="changed()" [(ngModel)]="selectAll"
    >Select All</mat-checkbox
  >
  <mat-checkbox
    *ngIf="
      !(
        isFromPayApplication ||
        isCCRPdf ||
        isAssetDetailPdf ||
        isWarrantyClaimPdf ||
        isOrganizationDetailPdf ||
        isPurchaseRequestPdf ||
        isPaymentRequestPdf
      )
    "
    (change)="changed()"
    [(ngModel)]="csvCheck"
    [(indeterminate)]="selectAll"
    >CSV</mat-checkbox
  >
  <mat-checkbox
    *ngIf="
      !(
        isFromPayApplication ||
        isCCRPdf ||
        isAssetDetailPdf ||
        isWarrantyClaimPdf ||
        isOrganizationDetailPdf ||
        isPurchaseRequestPdf ||
        isPaymentRequestPdf
      )
    "
    (change)="changed()"
    [(ngModel)]="excelCheck"
    [(indeterminate)]="selectAll"
    >Excel</mat-checkbox
  >
  <mat-checkbox
    (change)="changed()"
    [(ngModel)]="pdfCheck"
    [(indeterminate)]="selectAll"
    >PDF</mat-checkbox
  >
</section>
<form [formGroup]="mailForm" (submit)="onSendEmail()" class="px-4">
  <div class="row">
    <div class="col-sm-6 col-6 col-md-6 col-lg-6 col-xl-6">
      <mat-form-field
        *ngIf="
          !(
            isFromPayApplication ||
            isCCRPdf ||
            isAssetDetailPdf ||
            isWarrantyClaimPdf ||
            isPurchaseRequestPdf ||
            isPaymentRequestPdf
          )
        "
      >
        <mat-label>Email</mat-label>
        <input
          type="email"
          matInput
          formControlName="email"
          [class.is-invalid]="
            mailForm.get('email').invalid && mailForm.get('email').touched
          "
        />
        <!-- <input type="email" matInput formControlName="email"> -->
        <!-- <mat-error *ngIf="mailForm.get('email').hasError('email') && !mailForm.get('email').hasError('required')">
          Please enter a valid email address
        </mat-error> -->
        <mat-error *ngIf="mailForm.get('email').hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
      <mat-form-field
        *ngIf="
          isFromPayApplication ||
          isCCRPdf ||
          isAssetDetailPdf ||
          isWarrantyClaimPdf ||
          isPurchaseRequestPdf ||
          isPaymentRequestPdf
        "
      >
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="email" />
        <mat-error *ngIf="mailForm.get('email').hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>
      <div
        *ngIf="
          (!(
            isFromPayApplication ||
            isCCRPdf ||
            isAssetDetailPdf ||
            isWarrantyClaimPdf ||
            isPurchaseRequestPdf ||
            isPaymentRequestPdf
          ) &&
            mailForm.get('email').invalid && mailForm.get('email').touched) ||
          mailForm.get('email').dirty
        "
      >
        <mat-error
          class="text-danger"
          style="font-size: smaller"
          *ngIf="mailForm.get('email').errors?.pattern"
          >Enter Valid Email Address</mat-error
        >
      </div>
    </div>
    <div class="col-sm-6 col-6 col-md-6 col-lg-6 col-xl-6">
      <mat-form-field
        *ngIf="
          !(
            isFromPayApplication ||
            isCCRPdf ||
            isAssetDetailPdf ||
            isWarrantyClaimPdf ||
            isPurchaseRequestPdf ||
            isPaymentRequestPdf
          )
        "
      >
        <mat-label>CC</mat-label>
        <input type="email" matInput formControlName="cc" />
      </mat-form-field>
      <mat-form-field
        *ngIf="
          isFromPayApplication ||
          isCCRPdf ||
          isAssetDetailPdf ||
          isWarrantyClaimPdf ||
          isPurchaseRequestPdf ||
          isPaymentRequestPdf
        "
      >
        <mat-label>CC</mat-label>
        <input type="text" matInput formControlName="cc" />
      </mat-form-field>

      <!-- <mat-form-field>
        <mat-label>BCC</mat-label>
        <input type="email" [class.is-invalid] = "mailForm.get('bcc').invalid && mailForm.get('bcc').touched" matInput formControlName="bcc">
      </mat-form-field>
      <div *ngIf="(mailForm.get('bcc').invalid && mailForm.get('bcc').touched) || mailForm.get('bcc').dirty">
        <mat-error class="text-danger" style="font-size: smaller;" *ngIf="mailForm.get('bcc').errors?.pattern">Enter Valid Email Address</mat-error>
      </div> -->
    </div>
    <div
      class="col-sm-12 col-12 col-md-12 col-lg-12 col-xl-12"
      *ngIf="
        !(
          isFromPayApplication ||
          isCCRPdf ||
          isAssetDetailPdf ||
          isWarrantyClaimPdf ||
          isPurchaseRequestPdf ||
          isPaymentRequestPdf
        )
      "
    >
      <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" />
      </mat-form-field>
    </div>
    <div
      class="col-sm-12 col-12 col-md-12 col-lg-12 col-xl-12"
      *ngIf="
        !(
          isFromPayApplication ||
          isCCRPdf ||
          isAssetDetailPdf ||
          isWarrantyClaimPdf ||
          isPurchaseRequestPdf ||
          isPaymentRequestPdf
        )
      "
    >
      <mat-form-field>
        <mat-label>Email Content</mat-label>
        <textarea matInput formControlName="content" rows="3"></textarea>
      </mat-form-field>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button
      mat-stroked-button
      *ngIf="showForm"
      class="btn-primary mr-2"
      type="submit"
      [disabled]="mailForm.invalid"
    >
      Submit
    </button>
    <button
      mat-stroked-button
      class="btn-primary mr-2"
      [disabled]="true"
      *ngIf="!showForm"
    >
      Submit
    </button>
    <button mat-button class="btn-primary" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
