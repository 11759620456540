import { BillReportColumn } from './adhoc-report/adhoc-report.model';

export class Permissions {
  // set default to false for all properties to begin with.
  isCeAddAllowed = false;
  isCeEditAllowed = false;
  isProjAddAllowed = false;
  isProjEditAllowed = false;
  isProjFileAddAllowed = false;
  isProjFileDelAllowed = false;
  isContactAddAllowed = false;
  isContactEditAllowed = false;
  isIssueAddAllowed = false;
  isIssueEditAllowed = false;
  isTodoViewAllowed = false;
  IsViewUserTask = false;
  IsProjectCostCount = false;
  IsViewKPI = false;
  isUerTaskAllowed = false;
  isScheduleTargetViewAllowed = false;
  isIssueViewAllowed = false;
  isProjViewAllowed = false;
  isDashboardPgmView = false;
  Program_Edit = false;
  Program_Add = false;
  CM_Add = false;
  CM_Edit = false;
  CM_Delete = false;
  Vendor_Edit = false;
  Vendor_Add = false;
  Executive = false;
  Project_Contacts_View = false;
  Project_Issues_View = false;
  Project_StatusReport_View = false;
  Get_Files_List = false;
  viewSchedule = false;
  viewCE = false;
  viewPaid = false;
  CE_Contract_View = false;
  CE_ChangeOrder_View = false;
  CE_Contigency_View = false;
  Project_Contacts_Add = false;
  Project_Contacts_Edit = false;
  Project_Contact_Delete = false;
  Project_Issues_Edit = false;
  Project_Issues_Add = false;
  Project_StatusReport_Add = false;
  Project_StatusReport_Edit = false;
  Project_File_Add = false;
  Project_File_Delete = false;
  Project_File_Download = false;
  Customer_Config_View = false;
  Budget_Add_Item = false;
  Budget_Adjustment_Edit = false;
  Budget_Original_Edit = false;
  invoice_edit = false;
  Invoice_ApproveOrReject = false;
  Invoice_Submit = false;
  Budget_Item_Add = false;
  Budget_Template_View = false;
  Budget_Planned_Edit = false;
  Customer_Add = false;
  Directory = false;
  Program_Delete = false;
  Project_Delete = false;
  ContractType_Add = false;
  ContractType_Edit = false;
  ContractType_Activate = false;
  ContractType_Deactivate = false;
  ProgramType_Add = false;
  ProgramType_Edit = false;
  ProgramType_Activate = false;
  ProgramType_Deactivate = false;
  ProjectType_Add = false;
  ProjectType_Edit = false;
  ProjectType_Activate = false;
  ProjectType_Deactivate = false;
  CostEventType_Add = false;
  CostEventType_Edit = false;
  CostEventType_Activate = false;
  CostEventType_Deactivate = false;
  Budget_Template_Add = false;
  Budget_Template_Edit = false;
  Budget_Template_Delete = false;
  ServiceType_Add = false;
  ServiceType_Edit = false;
  ServiceType_Activate = false;
  ServiceType_Deactivate = false;
  CostEventReason_View = false;
  CostEventReason_Add = false;
  CostEventReason_Edit = false;
  CostEventReason_Activate = false;
  CostEventReason_Deactivate = false;
  Funding_Source_Edit = false;
  Funding_Source_Add = false;
  Budget_Edit_IsAppAdmin = false;
  IsAllowToSeeComments = false;
  IsAppAdmin_Edit = false;
  IsAppAdmin_Add = false;
  File_Category_edit = false;
  File_Category_Add = false;
  Invoice_Category_Edit = false;
  Invoice_Category_Add = false;
  Funding_Category_Add = false;
  Funding_Category_Edit = false;
  Campus_Add = false;
  Campus_Edit = false;
  Status_Type_Add = false;
  Status_Type_Edit = false;
  Territory_Add = false;
  Territory_Edit = false;
  projectviewSchedule = false;
  PunchListComments_View = false;
  Assigned_PunchListItems_View = false;
  All_PunchListItems_View = false;
  PunchListItem_Approve_View = false;
  PunchListItem_Approve_Edit = false;
  PunchListItem_Complete_View = false;
  PunchListItem_Complete_Edit = false;
  PunchListComments_Edit = false;
  PunchList_Create = false;
  Punchlist_Invite_User = false;
  Create_Project_Document = false;
  View_Project_Document = false;
  Edit_Project_Document = false;
  Add_project_document_comment = false;
  Edit_project_document_comment = false;
  IsApproved_Document = false;
  View_project_document_comment = false;
  isApprovedDocument = false;
  isRejecetedDocument = false;
  isWithDrawnDocument = false;
  Project_task_create = false;
  Project_task_edit = false;
  Project_task_comment_add = false;
  Project_task_comment_view = false;
  TempLabor_Dashboard_view = false;
  TempLabor_Graph_view = false;
  TempLabor_Line_Graph_view = false;
  TempLabor_Dashboard_Table_view = false;
  Applied_Request_List_For_Vendor = false;
  Timesheet_List_For_Vendor = false;
  Timesheet_List_For_CrewLeader = false;
  Invoice_List_For_Vendor = false;
  Timesheet_List_For_TempCoordinator = false;
  Invoice_List_For_TempCoordinator = false;
  Invoice_List_For_TempCoordinators = false;
  Create_Request_Allowed = false;
  Approve_Request_Allowed = false;
  Cancel_Request_Allowed = false;
  edit_request_allowed = false;
  create_timesheet_allowed = false;
  approve_timesheet_allowed = false;
  generate_invoice_allowed = false;
  showvendorname_list_allowed = false;
  approve_invoice_allowed = false;
  IsTempCoordinator = false;
  IsAreaManager = false;
  App_Admin = false;
  Project_Manager = false;
  Import_Project_Schedules = false;
  CanAddCloseoutToProject = false;
  Pay_Application_Enter_Amount_CM_Permission = false;
  Pay_Application_CM_PM_AND_IM_Permission = false;
  Pay_Application_Enter_Amount_PM_AND_IM_Permission = false;
  User_Edit = false;
  Create_Asset_Category = false;
  Create_Zone = false;
  Create_Building = false;
  Create_Wing = false;
  Create_Room = false;
  Create_Asset_Type = false;
  Create_Asset = false;
  CREATE_WARRANTY_CLAIM = false;
  Maintenence_Manager_Add_Claim = false;
  VIEW_CANCEL_CLAIM = false;
  VIEW_WARRANTY_CLAIM = false;
  EDIT_WARRANTY_CLAIM = false;
  Edit_Cost_Event_Additional_Fields = false;
  Add_Edit_Purchase_Request = false;
  Edit_PO_Number = false;
  Add_Edit_Payment_Request = false;
}

export class ConfigDetails {
  dateTimeFormat: string;
  dateFormat: string;
  reportDateTimeFormat: string;
  currencySymbol: string;
  currencyName: string;
  timeFormat: string;
  language: string;
  timeZone: string;
}
export interface DateTimeFormat {
  dateTimeFormat: string;
  dateFormat: string;
  timeFormat: string;
}
export interface Currency {
  currencySymbol: string;
  currencyName: string;
}
export interface BillReportView {
  id: number;
  title: string;
  allColumns: BillReportColumn[];
  selectedColumns: BillReportColumn[];
  previewHtml: string;
  workbookjson: any;
  reportFormat: string;
  viewType: number;
  billReportTypeId: number; // 1 = blog type & 2 = tabular type
  filterCriteria: string[];
}
