<p-table
  styleClass="p-datatable-gridlines"
  class="table w-100"
  #dt
  id="docreview"
  [value]="warrantyArray"
  [paginator]="true"
  [rows]="10"
  [rowsPerPageOptions]="[5, 10, 15, 20]"
  [globalFilterFields]="[
    'type',
    'cost',
    'warrantyEndDate',
    'warrantyStartDate',
  ]"
  pageLinks="3"
  sortMode="single"
  sortField="warrantyStartDate,cost,warrantyEndDate,type"
>
  <ng-template pTemplate="caption">
    <div class="row funding-wrapper" data-html2canvas-ignore="true">
      <div class="right_content">
        <div>
          <div class="h-100 col search-container">
            <input
              class="h-100"
              type="text"
              pInputText
              size="20"
              placeholder="Search"
              [(ngModel)]="searchInput"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
            />
          </div>
        </div>
        <div>
          <div class="share_btn button-rad btn-alignment">
            <button
              class="font-w-500 btn btn-primary"
              mat-flat-button
              color="primary"
              [matMenuTriggerFor]="menu"
            >
              Share <i class="fa fa-angle-down"></i>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                type="button"
                class="share-action-items"
                pButton
                iconPos="left"
                label="CSV"
                (click)="exportData('csv')"
              >
                CSV
              </button>
              <button
                mat-menu-item
                type="button"
                class="share-action-items"
                pButton
                iconPos="left"
                label="Excel"
                (click)="exportData('excel')"
              >
                EXCEL
              </button>
              <button
                mat-menu-item
                type="button"
                class="share-action-items"
                pButton
                iconPos="left"
                label="PDF"
                (click)="pdf()"
              >
                PDF
              </button>
              <button
                mat-menu-item
                type="button"
                class="share-action-items"
                pButton
                iconPos="left"
                label="PRINT"
                (click)="onPreview()"
              >
                PRINT
              </button>
              <button
                mat-menu-item
                type="button"
                class="share-action-items"
                pButton
                iconPos="left"
                label="COPY"
                (click)="copy()"
              >
                COPY
              </button>
              <button
                mat-menu-item
                type="button"
                class="share-action-items"
                pButton
                iconPos="left"
                label="EMAIL"
                (click)="sendMail()"
              >
                EMAIL
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr class="text-center">
      <th class="text-center" [pSortableColumn]="'type'">
        Type
        <p-sortIcon [field]="'type'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'warrantyStartDate'">
        Start Date
        <p-sortIcon [field]="'warrantyStartDate'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'warrantyEndDate'">
        End Date
        <p-sortIcon [field]="'warrantyEndDate'"></p-sortIcon>
      </th>
      <th class="text-center" [pSortableColumn]="'cost'">
        Cost
        <p-sortIcon [field]="'cost'"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-warranty let-rowIndex="rowIndex">
    <tr class="text-align">
      <td class="text-align">{{ warranty?.type }}</td>
      <td class="text-align">
        {{ warranty?.warrantyStartDate | date: 'MM/dd/yyyy' }}
      </td>
      <td class="text-align">
        {{ warranty?.warrantyEndDate | date: 'MM/dd/yyyy' }}
      </td>
      <td
        class="text-align right-alignment"
        [ngClass]="{
          'positive-value': warranty?.cost >= 0,
          'negative-value': warranty?.cost < 0,
        }"
      >
        <span *ngIf="warranty?.cost >= 0">$</span
        >{{ function.formatNumber(warranty?.cost) }}
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">No data available</td>
    </tr>
  </ng-template>
</p-table>
