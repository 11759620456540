import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CoreHttpService } from '../../../core/core-http.service';
import { ROUTE_CONSTANT } from '@app/pages/projects/Task-management/constants';
import { API_ENDPOINTS } from '@app/pages/projects/Task-management/constants';

@Injectable()
export class AssetsService {
  constructor(private coreHttpService: CoreHttpService) {}

  getAssetsList(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Asset/GetAllAssets',
    );
  }

  getAssetsCategory(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'AssetCategory/GetAllAssetCategories',
    );
  }
  getactiveAssetsCategory(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'AssetCategory/GetActiveAssetCategories',
    );
  }
  getAssetsType(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'AssetType/GetAllAssetTypes',
    );
  }

  addAssetsList(
    assetId,
    name,
    amount,
    modelNo,
    serialNo,
    tagNo,
    purchaseDate,
    lastRepairedDate,
    nextReplacementDate,
    category,
    type,
    description,
    assetImage,
    campus,
    building,
    department,
    room,
    owner,
    nextMaintenanceDate,
    notes,
    installationDate,
    warrantyEnd,
    warrantyStart,
    warrantyArray,
  ) {
    const body = new HttpParams()
      .set('AssetId', assetId)
      .set('name', name)
      .set('amount', amount)
      .set('modelNo', modelNo)
      .set('serialNo', serialNo)
      .set('tagNo', tagNo)
      .set('purchaseDate', purchaseDate)
      .set('lastRepairedDate', lastRepairedDate)
      .set('nextReplacementDate', nextReplacementDate)
      .set('category', category)
      .set('type', type)
      .set('description', description)
      .set('assetImage', assetImage)
      .set('CampusID', campus)
      .set('BuildingID', building)
      .set('DepartmentID', department)
      .set('RoomID', room)
      .set('Owner', owner)
      .set('NextMaintenanceDate', nextMaintenanceDate)
      .set('Notes', notes)
      .set('InstallationDate', installationDate)
      .set('WarrantyEndDate', warrantyEnd)
      .set('WarrantyStartDate', warrantyStart)
      .set('AssetWarranty', warrantyArray);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + ROUTE_CONSTANT.ADD_ASSET,
      body,
    );
  }

  editAssetsList(
    assetId,
    name,
    amount,
    modelNo,
    serialNo,
    tagNo,
    purchaseDate,
    lastRepairedDate,
    nextReplacementDate,
    category,
    type,
    description,
    assetImage,
    campus,
    building,
    department,
    room,
    owners,
    nextMaintenanceDate,
    notes,
    installationDate,
    warrantEndDate,
    warrantStartDate,
    extendedWarranty,
  ) {
    const body = new HttpParams()
      .set('assetId', assetId)
      .set('name', name)
      .set('amount', amount)
      .set('modelNo', modelNo)
      .set('serialNo', serialNo)
      .set('tagNo', tagNo)
      .set('purchaseDate', purchaseDate)
      .set('lastRepairedDate', lastRepairedDate)
      .set('nextReplacementDate', nextReplacementDate)
      .set('category', category)
      .set('type', type)
      .set('description', description)
      .set('assetImage', assetImage)
      .set('CampusID', campus)
      .set('BuildingID', building)
      .set('DepartmentID', department)
      .set('RoomID', room)
      .set('Owner', owners)
      .set('NextMaintenanceDate', nextMaintenanceDate)
      .set('Notes', notes)
      .set('InstallationDate', installationDate)
      .set('WarrantyEndDate', warrantEndDate)
      .set('WarrantyStartDate', warrantStartDate)
      .set('AssetWarranty', extendedWarranty);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + ROUTE_CONSTANT.EDIT_ASSET,
      body,
    );
  }

  GetAssetTypesByCategory(categoryId): Observable<any> {
    const body = {
      categoryId: categoryId.length > 0 ? categoryId.join(',') : '',
    };
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'AssetType/GetAssetTypesByCategory',
      body,
    );
  }

  GetAssetCount(categoryId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Asset/GetAssetCount?CategoryID=' + categoryId,
    );
  }
  GetRoomsByBuilding(buildingId): Observable<any> {
    const body = {
      buildingId: buildingId,
    };
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'EntityConfig/GetRoomsByBuilding',
      body,
    );
  }
  Search(
    campusId,
    buildingId,
    departmentId,
    roomId,
    categoryId,
    typeId,
  ): Observable<any> {
    const body = {
      campusId: campusId.length > 0 ? campusId.join(',') : '',
      buildingId: buildingId.length > 0 ? buildingId.join(',') : '',
      departmentId: departmentId.length > 0 ? departmentId.join(',') : '',
      roomId: roomId.length > 0 ? roomId.join(',') : '',
      categoryId: categoryId.length > 0 ? categoryId.join(',') : '',
      typeId: typeId.length > 0 ? typeId.join(',') : '',
    };
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Asset/GetAssetsByFilters',
      body,
    );
  }

  GetDepartmentsByBuilding(buildingId): Observable<any> {
    const body = {
      buildingId: buildingId.length > 0 ? buildingId.join(',') : '',
    };
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Department/GetDepartmentsByBuilding',
      body,
    );
  }

  getAssetByID(assetId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ASSETS_BY_ID + assetId,
    );
  }

  getProjectAssetDetail(assetId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PROJECT_DETAILS_BY_ID +
        assetId,
    );
  }

  getAssetWarrantyHistory(assetId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ASSETS_WARRANTY_HISTORY_BY_ID +
        assetId,
    );
  }

  getAssetWarrantyRepairHistory(assetId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_WARRANTY_CLAIM_HISTORY_BY_ID +
        assetId,
    );
  }

  activateAssetsList(assetId): Observable<any> {
    const params = new HttpParams().set('assetId', assetId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Asset/' + assetId + '/Activate',
      params,
    );
  }

  deactivateAssetList(assetId): Observable<any> {
    const params = new HttpParams().set('assetId', assetId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Asset/' + assetId + '/Deactivate',
      params,
    );
  }

  addAssetsReportList(
    Territory,
    BuildingID,
    CampusId,
    CategoryId,
    TypeId,
    PurchaseDateRangeStart,
    PurchaseDateRangeEnd,
    LastRepairedDateRangeStart,
    LastRepairedDateRangeEnd,
    ReplacementDate,
    ReplacementDateend,
    MaintananceDateStart,
    MaintananceDateEnd,
  ) {
    const body = new HttpParams()
      .set('Territory', Territory.length > 0 ? Territory.join(',') : '')
      .set('BuildingID', BuildingID.length > 0 ? BuildingID.join(',') : '')
      .set('CampusId', CampusId.length > 0 ? CampusId.join(',') : '')
      .set('CategoryId', CategoryId.length > 0 ? CategoryId.join(',') : '')
      .set('TypeId', TypeId.length > 0 ? TypeId.join(',') : '')
      .set('PurchaseDateRangeStart', PurchaseDateRangeStart)
      .set('PurchaseDateRangeEnd', PurchaseDateRangeEnd)
      .set('LastRepairedDateRangeStart', LastRepairedDateRangeStart)
      .set('LastRepairedDateRangeEnd', LastRepairedDateRangeEnd)
      .set('ReplacementDateStart', ReplacementDate)
      .set('ReplacementDateEnd', ReplacementDateend)
      .set('NextMaintenanceDateStart', MaintananceDateStart)
      .set('NextMaintenanceDateEnd', MaintananceDateEnd);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Asset/GetAssetsDetailsByFilters',
      body,
    );
  }

  getAvailableAssetsList() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Asset/' + API_ENDPOINTS.GET_AVAILABLE_ASSET,
    );
  }

  getAssetDetailPDF(assetId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GENERATE_ASSET_DETAIL_PDF +
        '/' +
        assetId,
    );
  }
}
