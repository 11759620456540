import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CoreHttpService } from '../../../core/core-http.service';

const httpOptions = {
  headers: new HttpHeaders({
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json; charset=UTF-8',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class SendmailService {
  fileTypes = new Subject();

  constructor(private coreHttpService: CoreHttpService) {}

  sendEmail(fileName, exportFileTypes, email, subject, contet, cc, header) {
    const body = {
      listData: fileName,
      modules: exportFileTypes,
      // 'emailID': email.length > 0 ? email.join(',') : '',
      emailID: email,
      subject: subject,
      message: contet,
      // 'cc': cc.length > 0 ? cc.join(',') : '',
      cc: cc,
      header: header,
    };
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'SendDocumentsEmail',
      body,
      httpOptions,
    );
  }

  setExportItems(fileTypes) {
    this.fileTypes.next(fileTypes);
  }

  getExportItems() {
    return this.fileTypes.asObservable();
  }
}
