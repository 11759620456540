import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { AuthUser, LoginService } from '../pages/login/login.service';
import { ConfigDetails, Permissions } from '../pages/utils.model';
import { HeaderService } from './header.service';
import { TaskManagementService } from '../../app/pages/projects/Task-management/task-management-list/task-management-list.service';
import { STORAGE } from '../pages/projects/Task-management/constants';
import { UsersService } from 'app/pages/users/users.service';
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  userName: string;
  profilePicturedata: string;
  profilePicture: string;
  logo: string;
  logoUrl: any;
  permissions: Permissions;
  authResult: AuthUser;
  viewCustomerInfo = false;
  Roles;
  buttonClicked = false;
  isLoginerror = false;
  Directory = false;
  directory_list = false;
  roleId: any = [];
  @Input() pageSidebarTwo;
  @Output() toggleSidebarRightCollapsed = new EventEmitter<boolean>();
  @Output() toggleMobileSidebar = new EventEmitter<boolean>();
  @Output() toggleMobileRightSidebar = new EventEmitter<boolean>();

  mobileSidebarToggle() {
    this.toggleMobileSidebar.emit(true);
  }
  mobileRightSidebarToggle() {
    this.toggleMobileRightSidebar.emit(true);
  }
  toggleSidebarRight() {
    this.toggleSidebarRightCollapsed.emit(true);
  }

  userManual() {
    window.open(environment.userManualUrl);
  }
  releasenote() {
    this.router.navigate(['ReleaseNotes']);
  }
  profile() {
    this.router.navigate(['changepassword']);
  }

  constructor(
    private router: Router,
    private _login: LoginService,
    public domSanitizer: DomSanitizer,
    public nav: HeaderService,
    public _taskManagementService: TaskManagementService,
    private _users: UsersService,
  ) {
    this.userName = localStorage.getItem('userName');
  }
  ngOnInit() {
    this.profilePicturedata = localStorage.getItem('profilePicture');
    if (this.profilePicturedata != null)
      this.profilePicture =
        'data:image;base64,' + this.profilePicturedata.replace(/ /g, '+');
    this.permissions = JSON.parse(localStorage.getItem('permissions'));
    if (this.permissions) {
      this.viewCustomerInfo = this.permissions.Customer_Config_View;
      this.Directory = this.permissions.Directory;
    }
    if (!this.logo) {
      this._login.getLogo().subscribe((res) => {
        this.logoUrl = res;
        this.logoUrl = this.logoUrl.logo;
        if (this.logoUrl !== null) {
          this.logo = 'data:image;base64,' + this.logoUrl.replace(/ /g, '+');
        }
      });
    }
    if (this.Directory) {
      this.directory_list = false;
      this.getRoles();
    }
  }

  getRoles() {
    this._login.getRoles().subscribe((res) => {
      this.Roles = res;
      if (this.Roles.directory.length > 1) {
        this.directory_list = true;
      }
    });
  }
  switch(clientID) {
    this._login.switchDirectory(clientID).subscribe(
      (authResult) => {
        this.authResult = authResult;
        localStorage.clear();
        localStorage.setItem('id_token', authResult.token);
        localStorage.setItem('userID', authResult.userID);
        localStorage.setItem('userName', authResult.displayName);
        localStorage.setItem('userName_email', authResult.userName);
        localStorage.setItem('profilePicture', authResult.profilePicture);

        // localStorage.setItem('customerLogo', authResult.Customer_Configuration_View);
        if (authResult.config && authResult.config.dateTimeFormat) {
          localStorage.setItem(
            'dateFormat',
            authResult.config.dateTimeFormat.dateFormat,
          );
          localStorage.setItem(
            'dateTimeFormat',
            authResult.config.dateTimeFormat.dateTimeFormat,
          );
          localStorage.setItem(
            'ReportDateTimeFormat',
            authResult.config.dateTimeFormat.reportDateTimeFormat,
          );
        }
        if (authResult.config) {
          this.setConfig(authResult.config);
        }
        if (authResult.roles && authResult.roles.length > 0) {
          var distinctRoles = [...new Set(authResult.roles.map((obj) => obj))];
          localStorage.setItem('roles', distinctRoles.join(', '));
        }
        if (authResult.clientNumber) {
          localStorage.setItem(
            'clientNumber',
            authResult.clientNumber.toString(),
          );
        }
        if (authResult.permissions) {
          this.setPermissions(authResult.permissions);
        }
        if (
          authResult.permissions &&
          authResult.permissions.includes('Client_Admin')
        ) {
          this.router.navigate(['users']);
        } else {
          this.getRoles();
          this.router.navigate(['dashboard']);
          this.getUserRole();
        }
      },
      () => {
        this.buttonClicked = false;
        this.isLoginerror = true;
      },
    );
  }
  getUserRole() {
    this._users.getRoleId().subscribe((res: any) => {
      console.log(res);
      if (res) {
        res.roleList.forEach((element) => {
          const a = element.roleID;
          this.roleId.push(a);
        });
        if (res.roleList && res.roleList.length > 0) {
          localStorage.setItem('roleWithNames', this.roleId);
          window.location.reload();
        }
      }
    });
  }

  // setPermissions(permission: string[]) {
  //   const perm = new Permissions;

  //   // TODO: Removed for IE 11
  //   perm.isCeAddAllowed = permission.includes('CE_Submit');
  //   perm.isCeEditAllowed = permission.includes('CE_Edit');
  //   perm.isProjAddAllowed = permission.includes('Project_Add');
  //   perm.isProjEditAllowed = permission.includes('Project_Edit');
  //   // perm.isProjContactAddAllowed = permissions.includes
  //   perm.isContactEditAllowed = permission.includes('Project_Contacts_Edit');
  //   perm.isProjFileAddAllowed = permission.includes('Project_File_Add');
  //   perm.isProjFileDelAllowed = permission.includes('Project_File_Delete');
  //   perm.isIssueAddAllowed = permission.includes('Project_Issues_Add');
  //   perm.isProjViewAllowed = permission.includes('Active_Projects_Widget');
  //   perm.isTodoViewAllowed = permission.includes('ToDo_Widget');
  //   perm.isUerTaskAllowed = permission.includes('User_Task_allow');
  //   perm.isScheduleTargetViewAllowed = permission.includes('Upcoming_Schedules_Target_Widget');
  //   perm.isIssueViewAllowed = permission.includes('Open_Issues_Widget');
  //   perm.isDashboardPgmView = permission.includes('Program_Widget');
  //   perm.IsViewUserTask = permission.includes('IsViewUserTask');
  //   perm.IsProjectCostCount = permission.includes('IsProjectCostCount');
  //   perm.IsViewKPI  = permission.includes('IsViewKPI');
  //   perm.isScheduleTargetViewAllowed = permission.includes('Upcoming_Schedules_Target_Widget');
  //   perm.isIssueViewAllowed = permission.includes('Open_Issues_Widget');
  //   perm.isDashboardPgmView = permission.includes('Program_Widget');
  //   perm.Program_Edit = permission.includes('Program_Edit');
  //   perm.Program_Add = permission.includes('Program_Add');
  //   perm.CM_Add = permission.includes('CM_Add');
  //   perm.CM_Edit = permission.includes('CM_Edit');
  //   perm.CM_Delete = permission.includes('CM_Delete');
  //   perm.Vendor_Edit = permission.includes('Vendor_Edit');
  //   perm.Vendor_Add = permission.includes('Vendor_Add');
  //   perm.Executive = permission.includes('Executive');
  //   perm.Project_Contacts_View = permission.includes('Project_Contacts_View');
  //   perm.Project_Issues_View = permission.includes('Project_Issues_View');
  //   perm.Project_StatusReport_View = permission.includes('Project_StatusReport_View');
  //   perm.Get_Files_List = permission.includes('Get_Files_List');
  //   perm.viewSchedule = permission.includes('Project_Schedules_View');
  //   perm.viewCE = permission.includes('CE_View');
  //   perm.invoice_edit = permission.includes('Invoice_ApproveOrReject');

  //   perm.CE_Contract_View = permission.includes('CE_Contract_View');
  //   perm.CE_ChangeOrder_View = permission.includes('CE_ChangeOrder_View');
  //   perm.CE_Contigency_View = permission.includes('CE_Contigency_View');
  //   perm.Project_Contacts_Add = permission.includes('Project_Contacts_Add');
  //   perm.Project_Contacts_Edit = permission.includes('Project_Contacts_Edit');
  //   perm.Project_Contact_Delete = permission.includes('Project_Contact_Delete');
  //   perm.Project_Issues_Edit = permission.includes('Project_Issues_Edit');
  //   perm.Project_Issues_Add = permission.includes('Project_Issues_Add');
  //   perm.Project_StatusReport_Add = permission.includes('Project_StatusReport_Add');
  //   perm.Project_StatusReport_Edit = permission.includes('Project_StatusReport_Edit');
  //   perm.Project_File_Add = permission.includes('Project_File_Add');
  //   perm.Project_File_Delete = permission.includes('Project_File_Delete');
  //   perm.Project_File_Download = permission.includes('Project_File_Download');
  //   perm.Customer_Config_View = permission.includes('Customer_Configuration_View');
  //   perm.Budget_Add_Item = permission.includes('Add_Budget_Item_During_Project_Edit');
  //   perm.Budget_Adjustment_Edit = permission.includes('Project_Budget_Item_BudgetAdjustmentAmount_Edit');
  //   perm.Budget_Original_Edit = permission.includes('Project_Budget_Item_OriginalBudgetAmount_Edit');
  //   perm.Budget_Planned_Edit = permission.includes('Project_Budget_Item_PlannedAmount_Edit');
  //   perm.Invoice_Submit = permission.includes('Invoice_Submit');
  //   perm.Budget_Item_Add = permission.includes('Budget_Item_Add');
  //   perm.Budget_Template_View = permission.includes('Budget_Template_View');
  //   perm.Customer_Add = permission.includes('Customer_Add');
  //   perm.Directory = permission.includes('Directory');
  //   perm.Budget_Edit_IsAppAdmin=permission.includes('Budget_Edit_IsAppAdmin');
  //   perm.File_Category_edit=permission.includes('File_Category_edit');
  //   perm.File_Category_Add=permission.includes('File_Category_Add');
  //   perm.Funding_Source_Add=permission.includes('Funding_Source_Add');
  //   perm.Funding_Source_Edit=permission.includes('Funding_Source_Edit');
  //   perm.Invoice_Category_Add=permission.includes('Invoice_Category_Add');
  //   perm.Invoice_Category_Edit=permission.includes('Invoice_Category_Edit');
  //   perm.Funding_Category_Add=permission.includes('Funding_Category_Add');
  //   perm.Funding_Category_Edit=permission.includes('Funding_Category_Edit');
  //   perm.Campus_Add=permission.includes('Campus_Add');
  //   perm.Campus_Edit=permission.includes('Campus_Edit');
  //   perm.Status_Type_Add=permission.includes('Status_Type_Add');
  //   perm.Status_Type_Edit=permission.includes('Status_Type_Edit');
  //   perm.Territory_Add=permission.includes('Territory_Add');
  //   perm.Territory_Edit=permission.includes('Territory_Edit');
  //   perm.PunchListComments_View = permission.includes('PunchListComments_View');
  //   perm.Assigned_PunchListItems_View = permission.includes('Assigned_PunchListItems_View');
  //   perm.All_PunchListItems_View = permission.includes('All_PunchListItems_View');
  //   perm.PunchListItem_Approve_View = permission.includes('PunchListItem_Approve_View');
  //   perm.PunchListItem_Approve_Edit = permission.includes('PunchListItem_Approve_Edit');
  //   perm.PunchListItem_Complete_View = permission.includes('PunchListItem_Complete_View');
  //   perm.PunchListItem_Complete_Edit = permission.includes('PunchListItem_Complete_Edit');
  //   perm.PunchListComments_Edit = permission.includes('PunchListComments_Edit');
  //   perm.PunchList_Create = permission.includes('PunchList_Create');
  //   perm.Punchlist_Invite_User = permission.includes('Punchlist_Invite_User');
  //   perm.Create_Project_Document = permission.includes('Create_Project_Document');
  //   perm.View_Project_Document = permission.includes('View_Project_Document');
  //   perm.Edit_Project_Document = permission.includes('Edit_Project_Document');
  //   perm.Add_project_document_comment = permission.includes('Add_project_document_comment');
  //   perm.Edit_project_document_comment = permission.includes('Edit_project_document_comment');
  //   perm.View_project_document_comment = permission.includes('View_project_document_comment');

  //   // this._utils.setPermissions(perm);
  //   localStorage.setItem('permissions', JSON.stringify(perm));
  // }

  //   // this._utils.setPermissions(perm);
  //   localStorage.setItem('permissions', JSON.stringify(perm));
  // }
  setPermissions(permission: string[]) {
    const perm = new Permissions();
    // TODO: Removed for IE 11
    perm.isCeAddAllowed = permission.includes('CE_Submit');
    perm.isCeEditAllowed = permission.includes('CE_Edit');
    perm.isProjAddAllowed = permission.includes('Project_Add');
    perm.isProjEditAllowed = permission.includes('Project_Edit');
    // perm.isProjContactAddAllowed = permissions.includes
    perm.isContactEditAllowed = permission.includes('Project_Contacts_Edit');
    perm.isProjFileAddAllowed = permission.includes('Project_File_Add');
    perm.isProjFileDelAllowed = permission.includes('Project_File_Delete');
    perm.isIssueAddAllowed = permission.includes('Project_Issues_Add');
    perm.isProjViewAllowed = permission.includes('Active_Projects_Widget');
    perm.isTodoViewAllowed = permission.includes('ToDo_Widget');
    perm.isUerTaskAllowed = permission.includes('User_Task_allow');
    perm.isScheduleTargetViewAllowed = permission.includes(
      'Upcoming_Schedules_Target_Widget',
    );
    perm.isIssueViewAllowed = permission.includes('Open_Issues_Widget');
    perm.isDashboardPgmView = permission.includes('Program_Widget');
    perm.Program_Edit = permission.includes('Program_Edit');
    perm.Program_Add = permission.includes('Program_Add');
    perm.IsViewKPI = permission.includes('IsViewKPI');
    perm.IsProjectCostCount = permission.includes('IsProjectCostCount');
    perm.IsViewUserTask = permission.includes('IsViewUserTask');

    perm.CM_Add = permission.includes('CM_Add');
    perm.CM_Edit = permission.includes('CM_Edit');
    perm.CM_Delete = permission.includes('CM_Delete');
    perm.Vendor_Edit = permission.includes('Vendor_Edit');
    perm.Vendor_Add = permission.includes('Vendor_Add');
    perm.Executive = permission.includes('Executive');
    perm.Project_Contacts_View = permission.includes('Project_Contacts_View');
    perm.Project_Issues_View = permission.includes('Project_Issues_View');
    perm.Project_StatusReport_View = permission.includes(
      'Project_StatusReport_View',
    );
    perm.Get_Files_List = permission.includes('Get_Files_List');
    perm.viewSchedule = permission.includes('Project_Schedules_View');
    perm.Import_Project_Schedules = permission.includes(
      'Import_Project_Schedules',
    );
    perm.viewCE = permission.includes('CE_View');
    perm.Invoice_ApproveOrReject = permission.includes(
      'Invoice_ApproveOrReject',
    );
    perm.invoice_edit = permission.includes('Invoice_Edit');

    perm.CE_Contract_View = permission.includes('CE_Contract_View');
    perm.CE_ChangeOrder_View = permission.includes('CE_ChangeOrder_View');
    perm.CE_Contigency_View = permission.includes('CE_Contigency_View');
    perm.Project_Contacts_Add = permission.includes('Project_Contacts_Add');
    perm.Project_Contacts_Edit = permission.includes('Project_Contacts_Edit');
    perm.Project_Contact_Delete = permission.includes('Project_Contact_Delete');
    perm.Project_Issues_Edit = permission.includes('Project_Issues_Edit');
    perm.Project_Issues_Add = permission.includes('Project_Issues_Add');
    perm.Project_StatusReport_Add = permission.includes(
      'Project_StatusReport_Add',
    );
    perm.Project_StatusReport_Edit = permission.includes(
      'Project_StatusReport_Edit',
    );
    perm.Project_File_Add = permission.includes('Project_File_Add');
    perm.Project_File_Delete = permission.includes('Project_File_Delete');
    perm.Project_File_Download = permission.includes('Project_File_Download');
    perm.Customer_Config_View = permission.includes(
      'Customer_Configuration_View',
    );
    perm.Budget_Add_Item = permission.includes(
      'Add_Budget_Item_During_Project_Edit',
    );
    perm.Budget_Adjustment_Edit = permission.includes(
      'Project_Budget_Item_BudgetAdjustmentAmount_Edit',
    );
    perm.Budget_Original_Edit = permission.includes(
      'Project_Budget_Item_OriginalBudgetAmount_Edit',
    );
    perm.Budget_Planned_Edit = permission.includes(
      'Project_Budget_Item_PlannedAmount_Edit',
    );
    perm.Invoice_Submit = permission.includes('Invoice_Submit');
    perm.Budget_Item_Add = permission.includes('Budget_Item_Add');
    perm.Budget_Template_View = permission.includes('Budget_Template_View');
    perm.Customer_Add = permission.includes('Customer_Add');
    perm.Directory = permission.includes('Directory');
    perm.Program_Delete = permission.includes('Deleted_Program_View');
    perm.Project_Delete = permission.includes('Deleted_Project_View');
    perm.ContractType_Add = permission.includes('ContractType_Add');
    perm.ContractType_Edit = permission.includes('ContractType_Edit');
    perm.ContractType_Activate = permission.includes('ContractType_Activate');
    perm.ContractType_Deactivate = permission.includes(
      'ContractType_Deactivate',
    );
    perm.ProgramType_Add = permission.includes('ProgramType_Add');
    perm.ProgramType_Edit = permission.includes('ProgramType_Edit');
    perm.ProgramType_Activate = permission.includes('ProgramType_Activate');
    perm.ProgramType_Deactivate = permission.includes('ProgramType_Deactivate');
    perm.ProjectType_Add = permission.includes('ProjectType_Add');
    perm.ProjectType_Edit = permission.includes('ProjectType_Edit');
    perm.ProjectType_Activate = permission.includes('ProjectType_Activate');
    perm.ProjectType_Deactivate = permission.includes('ProjectType_Deactivate');
    perm.CostEventType_Add = permission.includes('CostEventType_Add');
    perm.CostEventType_Edit = permission.includes('CostEventType_Edit');
    perm.CostEventType_Activate = permission.includes('CostEventType_Activate');
    perm.CostEventType_Deactivate = permission.includes(
      'CostEventType_DeActivate',
    );
    perm.Budget_Template_Add = permission.includes('Budget_Template_Add');
    perm.Budget_Template_Edit = permission.includes('Budget_Template_Edit');
    perm.Budget_Template_Delete = permission.includes('Budget_Template_Delete');
    perm.ServiceType_Add = permission.includes('ServiceType_Add');
    perm.ServiceType_Edit = permission.includes('ServiceType_Edit');
    perm.ServiceType_Activate = permission.includes('ServiceType_Activate');
    perm.ServiceType_Deactivate = permission.includes('ServiceType_Deactivate');
    perm.CostEventReason_View = permission.includes('CostEventReason_View');
    perm.CostEventReason_Add = permission.includes('CostEventReason_Add');
    perm.CostEventReason_Edit = permission.includes('CostEventReason_Edit');
    perm.CostEventReason_Activate = permission.includes(
      'CostEventReason_Activate',
    );
    perm.CostEventReason_Deactivate = permission.includes(
      'CostEventReason_Deactivate',
    );
    perm.Budget_Edit_IsAppAdmin = permission.includes('Budget_Edit_IsAppAdmin');
    perm.Funding_Source_Edit = permission.includes('Funding_Source_Edit');
    perm.IsAllowToSeeComments = permission.includes(
      'IsAllowToSeeInvoiceComments',
    );
    perm.File_Category_edit = permission.includes('File_Category_edit');
    perm.File_Category_Add = permission.includes('File_Category_Add');
    perm.Funding_Source_Add = permission.includes('Funding_Source_Add');
    perm.Funding_Source_Edit = permission.includes('Funding_Source_Edit');
    perm.Invoice_Category_Add = permission.includes('Invoice_Category_Add');
    perm.Invoice_Category_Edit = permission.includes('Invoice_Category_Edit');
    perm.Funding_Category_Add = permission.includes('Funding_Category_Add');
    perm.Funding_Category_Edit = permission.includes('Funding_Category_Edit');
    perm.Campus_Add = permission.includes('Campus_Add');
    perm.Campus_Edit = permission.includes('Campus_Edit');
    perm.Status_Type_Add = permission.includes('Status_Type_Add');
    perm.Status_Type_Edit = permission.includes('Status_Type_Edit');
    perm.Territory_Add = permission.includes('Territory_Add');
    perm.Territory_Edit = permission.includes('Territory_Edit');
    perm.PunchListComments_View = permission.includes('PunchListComments_View');
    perm.Assigned_PunchListItems_View = permission.includes(
      'Assigned_PunchListItems_View',
    );
    perm.All_PunchListItems_View = permission.includes(
      'All_PunchListItems_View',
    );
    perm.PunchListItem_Approve_View = permission.includes(
      'PunchListItem_Approve_View',
    );
    perm.PunchListItem_Approve_Edit = permission.includes(
      'PunchListItem_Approve_Edit',
    );
    perm.PunchListItem_Complete_View = permission.includes(
      'PunchListItem_Complete_View',
    );
    perm.PunchListItem_Complete_Edit = permission.includes(
      'PunchListItem_Complete_Edit',
    );
    perm.PunchListComments_Edit = permission.includes('PunchListComments_Edit');
    perm.PunchList_Create = permission.includes('PunchList_Create');
    perm.Punchlist_Invite_User = permission.includes('Punchlist_Invite_User');
    perm.Create_Project_Document = permission.includes(
      'Create_Project_Document',
    );
    perm.View_Project_Document = permission.includes('View_Project_Document');
    perm.Edit_Project_Document = permission.includes('Edit_Project_Document');
    perm.Add_project_document_comment = permission.includes(
      'Add_project_document_comment',
    );
    perm.Edit_project_document_comment = permission.includes(
      'Edit_project_document_comment',
    );
    perm.View_project_document_comment = permission.includes(
      'View_project_document_comment',
    );
    perm.isApprovedDocument = permission.includes('isApprovedDocument');
    perm.isRejecetedDocument = permission.includes('isRejecetedDocument');
    perm.isWithDrawnDocument = permission.includes('isWithDrawnDocument');
    perm.All_PunchListItems_View = permission.includes(
      'All_PunchListItems_View',
    );
    perm.Project_task_edit = permission.includes('Project_task_edit');
    perm.Project_task_comment_add = permission.includes(
      'Project_task_comment_add',
    );
    perm.Project_task_comment_view = permission.includes(
      'Project_task_comment_view',
    );
    perm.CanAddCloseoutToProject = permission.includes(
      'CanAddCloseoutToProject',
    );
    perm.Project_task_create = permission.includes('Project_task_create');
    perm.Pay_Application_Enter_Amount_CM_Permission = permission.includes(
      'Pay_Application_Enter_Amount_CM_Permission',
    );
    perm.Pay_Application_CM_PM_AND_IM_Permission = permission.includes(
      'Pay_Application_CM_PM_AND_IM_Permission',
    );
    perm.Pay_Application_Enter_Amount_PM_AND_IM_Permission =
      permission.includes('Pay_Application_Enter_Amount_PM_AND_IM_Permission');
    perm.User_Edit = permission.includes('User_Edit');
    perm.Create_Asset_Category = permission.includes('Create_Asset_Category');
    perm.Create_Zone = permission.includes('Create_Zone');
    perm.Create_Building = permission.includes('Create_Building');
    perm.Create_Wing = permission.includes('Create_Wing');
    perm.Create_Room = permission.includes('Create_Room');
    perm.Create_Asset_Type = permission.includes('Create_Asset_Type');
    perm.Create_Asset = permission.includes('Create_Asset');
    perm.CREATE_WARRANTY_CLAIM = permission.includes('CREATE_WARRANTY_CLAIM');
    perm.Maintenence_Manager_Add_Claim = permission.includes(
      'Maintenence_Manager_Add_Claim',
    );
    perm.VIEW_CANCEL_CLAIM = permission.includes('VIEW_CANCEL_CLAIM');
    perm.VIEW_WARRANTY_CLAIM = permission.includes('VIEW_WARRANTY_CLAIM');
    perm.EDIT_WARRANTY_CLAIM = permission.includes('EDIT_WARRANTY_CLAIM');
    perm.Edit_Cost_Event_Additional_Fields = permission.includes(
      'Edit_Cost_Event_Additional_Fields',
    );
    perm.Add_Edit_Purchase_Request = permission.includes(
      'Add_Edit_Purchase_Request',
    );
    perm.Add_Edit_Payment_Request = permission.includes(
      'Add_Edit_Payment_Request',
    );
    perm.Edit_PO_Number = permission.includes('Edit_PO_Number');
    // this._utils.setPermissions(perm);
    localStorage.setItem('permissions', JSON.stringify(perm));
  }
  setConfig(config) {
    const configParam = new ConfigDetails();
    configParam.dateTimeFormat = config.dateTimeFormat.dateTimeFormat;
    configParam.dateFormat = config.dateTimeFormat.dateFormat;
    configParam.reportDateTimeFormat =
      config.dateTimeFormat.reportDateTimeFormat;
    configParam.currencyName = config.currency.currencyName;
    configParam.currencySymbol = config.currency.currencySymbol;
    configParam.language = config.language;
    localStorage.setItem('config', JSON.stringify(configParam));
  }

  logout() {
    if (localStorage.getItem(STORAGE.TIMER_DETAILS_WITH_TASK)) {
      this.nav.updateTimerOnLogout.emit(true);
    } else {
      localStorage.clear();
      this.router.navigate(['login']);
    }
  }
}
