import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as global from '../globals';
import { MenuResponse, SidebarService } from './sidebar.service';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
  animations: [
    trigger('expandCollapse', [
      state(
        'expand',
        style({ height: '*', overflow: 'hidden', display: 'block' }),
      ),
      state(
        'collapse',
        style({ height: '0px', overflow: 'hidden', display: 'block' }),
      ),
      state(
        'active',
        style({ height: '*', overflow: 'hidden', display: 'block' }),
      ),
      transition('expand <=> collapse', animate(100)),
      transition('active => collapse', animate(100)),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  navProfileState = 'collapse';
  // menus: MenuItem[];
  menus = [];
  userName: string;
  userRoles: string;
  profilePicture: string;
  profilePicturedata: string;
  slimScrollOptions = global.whiteSlimScrollOptions;
  @Output() toggleSidebarMinified = new EventEmitter<boolean>();
  @Output() hideMobileSidebar = new EventEmitter<boolean>();
  @Input() pageSidebarTransparent;

  constructor(
    private eRef: ElementRef,
    public _sidebar: SidebarService,
    public domSanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.userName = localStorage.getItem('userName');
    this.userRoles = localStorage.getItem('roles');
    this.profilePicturedata = localStorage.getItem('profilePicture');
    if (this.userName) {
      setTimeout(() => {
        this.getMenuItems();
      }, 1000);
    }
    if (this.profilePicturedata != null)
      this.profilePicture =
        'data:image;base64,' + this.profilePicturedata.replace(/ /g, '+');
  }

  toggleNavProfile() {
    if (this.navProfileState == 'collapse') {
      this.navProfileState = 'expand';
    } else {
      this.navProfileState = 'collapse';
    }
  }

  toggleMinified() {
    this.toggleSidebarMinified.emit(true);
  }

  expandCollapseSubmenu(currentMenu, allMenu, active) {
    for (let menu of allMenu) {
      if (menu != currentMenu) {
        menu.state = 'collapse';
      }
    }
    if (
      currentMenu.state == 'expand' ||
      (active.isActive && !currentMenu.state)
    ) {
      currentMenu.state = 'collapse';
    } else {
      currentMenu.state = 'expand';
    }
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.hideMobileSidebar.emit(true);
    }
  }

  getMenuItems() {
    this._sidebar.getMenus().subscribe((menuResp: MenuResponse) => {
      var menus = menuResp.menus;
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].parentMenuID === null) {
          var menuItem = this.addMenu(menus[i]);
          this.menus.push(menuItem);
        }
      }
      this.menus.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      });
      for (let x = 0; x < this.menus.length; x++) {
        for (let j = 0; j < menus.length; j++) {
          if (
            menus[j].parentMenuID !== null &&
            menus[j].parentMenuID === this.menus[x].id
          ) {
            var submenuItem = this.addMenu(menus[j]);
            var submenuArr = this.menus[x].submenu;
            if (submenuArr === undefined) {
              submenuArr = [];
              this.menus[x]['submenu'] = submenuArr;
              this.menus[x]['caret'] = 'true';
            }
            var existingSubmenu = submenuArr.find(
              (item) => item.id === submenuItem['id'],
            );
            if (!existingSubmenu) {
              submenuArr.push(submenuItem);
              this.addSubmenus(submenuItem, menus);
              submenuArr.sort(function (a, b) {
                return a.sortOrder - b.sortOrder;
              });
            }
          }
        }
      }
    });
  }

  addSubmenus(parentItem, menus) {
    for (let l = 0; l < menus.length; l++) {
      if (
        menus[l].parentMenuID !== null &&
        menus[l].parentMenuID === parentItem.id
      ) {
        var submenuValues = this.addMenu(menus[l]);
        if (parentItem.submenu === undefined) {
          parentItem['caret'] = 'true';
          parentItem['submenu'] = [submenuValues];
        } else {
          parentItem.submenu.push(submenuValues);
        }
        this.addSubmenus(submenuValues, menus);
      }
    }
  }

  addMenu(menuItem) {
    var menu = {};
    menu['id'] = menuItem.menuID;
    menu['icon'] = menuItem.menuIcon;
    menu['url'] = menuItem.navigationURL;
    menu['title'] = menuItem.menuTitle;
    menu['sortOrder'] = menuItem.sortOrderId;
    return menu;
  }
}
