import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CoreHttpService } from '../../../../core/core-http.service';
import { API_ENDPOINTS } from '../constants';
import {
  Response,
  Response3,
  Response4,
} from '../view-task-list/View-task-list-Model';
import { taskResponse } from '../../issues/issue.Model';
import { catchError } from 'rxjs/operators';
import { SweetAlert } from 'sweetalert/typings/core';
import _swal from 'sweetalert';
const swal: SweetAlert = _swal as any;

@Injectable()
export class TaskManagementService {
  clientNumber: string;
  interval;
  logTime = new BehaviorSubject<boolean>(false);
  closeSideViewOnCancel = new EventEmitter<boolean>();
  closeSideViewOnSave = new EventEmitter<boolean>();
  refreshTimeLog = new BehaviorSubject<boolean>(false);
  isAdmin = false;
  constructor(
    private http: HttpClient,
    private coreHttpService: CoreHttpService,
  ) {}

  // GENERAL TASK LIST
  getAlltaskList() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/TaskListGeneralTask',
    );
  }

  getGeneraltaskList(generalTask: any) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'ProjectTask/TaskListGeneralTasks/' +
        generalTask,
    );
  }

  // BYPROJECT TASK LIST
  getTasklistbyProject(projectID: string) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/TaskListByProjectID/' + projectID,
    );
  }

  // FOR VIEW TASK LIST ITEM
  getTaskById(taskId: string): Observable<any> {
    const body = new HttpParams().set('TaskID', taskId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_TASK_DETAIL_WITH_ACTION_ITEMS,
      body,
    );
  }

  // ADD TASK LIST ITEM
  addtaskList(data) {
    const params = new HttpParams()
      .set('ProjectID', data.pId.id)
      .set('GeneralTask', data.generalTask)
      .set('TaskTitle', data.itemName)
      .set('TaskDescription', data.description)
      .set('AssigendTo', data.assigendTo)
      .set('DueDate', data.dueDate);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/AddProjectTask',
      params,
    );
  }

  // EDIT TASK LIST ITEMS
  edittaskList(data) {
    const params = new HttpParams()
      .set('ProjectID', data.pId.id)
      .set('GeneralTask', data.generalTask)
      .set('TaskID', data.taskItemId)
      .set('TaskTitle', data.itemName)
      .set('DueDate', data.dueDate)
      .set('TaskDescription', data.description)
      .set('IsActive', data.isActive)
      .set('AssigendTo', data.assigendTo);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/EditTaskList',
      params,
    );
  }

  // ADD FILES FOR TASK ITEM
  addTaskFiles(formData: FormData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');
    return this.coreHttpService.httpPostRequestWithParamsWithoutLoader(
      environment.apiBaseUrl + 'ProjectTask/AddProjectTask/AddFiles',
      formData,
      { headers },
    );
  }

  addTaskCommentFiles(taskID: string, formData: FormData, commentId: string) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', 'application/json');

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl +
        'ProjectTask/AddProjectTask/' +
        taskID +
        '/' +
        commentId +
        '/AddCommentFiles',
      formData,
      { headers },
    );
  }

  async downloadTaskResource(fileID: string): Promise<Blob> {
    // const tempid = 'DFBE7BF4-7BDC-4232-80A9-0822020F77BA';
    const file = await this.http
      .get<Blob>(environment.apiBaseUrl + 'ProjectTask/GetFile/' + fileID, {
        responseType: 'blob' as 'json',
      })
      .toPromise();
    return file;
  }

  // ADD COMMENT
  addTaskListComment(taskID: string, Comment: string): Observable<any> {
    const body = new HttpParams().set('TaskId', taskID).set('Comment', Comment);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/ProjectTask/AddComment',
      body,
    );
  }

  // ADD TASK LIST FILES
  addTasklistFiles(taskID: string) {
    const body = new HttpParams().set('TaskId', taskID);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl +
        'ProjectTask/AddProjectTask/' +
        'TaskID' +
        '/AddFiles',
      body,
    );
  }

  // REMOVE FILE
  removeFile(fieldId) {
    const params = new HttpParams().set('FileID', fieldId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/InActiveProjectFiles/',
      params,
    );
  }

  activateProjectTask(id): Observable<any> {
    const params = new HttpParams().set('TaskId', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/ActiveProjectTask',
      params,
    );
  }

  deActivateProjectTask(id): Observable<any> {
    const params = new HttpParams().set('TaskId', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/InActiveProjectTask',
      params,
    );
  }

  isMarkAsCompleted(TaskId: any) {
    const body = new HttpParams().set('TaskId', TaskId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/TaskMarkAsCompleted/',
      body,
    );
  }

  addTask(data: any): Observable<any> {
    const params = new HttpParams()
      .set('ProjectID', data.ProjectID)
      .set('TaskTitle', data.TaskTitle)
      .set('TaskDescription', data.TaskDescription)
      .set('AssigendTo', data.AssigendTo)
      .set('ScheduleDate', data.ScheduleDate)
      .set('DueDate', data.DueDate)
      .set('Priority', data.Priority)
      .set('EstimatedHours', data.EstimatedHours)
      .set('GeneralTask', data.GeneralTask)
      .set('CanAssigneeSeeAllTask', data.CanAssigneeSeeAllTask)
      .set('ParentTaskId', data.ParentTaskId)
      .set('ContextId', data.ContextId)
      .set('IsTaskFromMeeting', data.IsTaskFromMeeting)
      .set('ScheduleStartDatetime', data.ScheduleStartDatetime)
      .set('ScheduleEndDatetime', data.ScheduleEndDatetime);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/AddProjectTask',
      params,
    );
  }

  addTaskFromMeeting(data: any, id: string): Observable<any> {
    const params = new HttpParams()
      .set('ProjectID', data.ProjectID)
      .set('TaskTitle', data.TaskTitle)
      .set('TaskDescription', data.TaskDescription)
      .set('AssigendTo', data.AssigendTo)
      .set('ScheduleDate', data.ScheduleDate)
      .set('DueDate', data.DueDate)
      .set('Priority', data.Priority)
      .set('EstimatedHours', data.EstimatedHours)
      .set('GeneralTask', data.GeneralTask)
      .set('CanAssigneeSeeAllTask', data.CanAssigneeSeeAllTask)
      .set('ParentTaskId', data.ParentTaskId)
      .set('ContextId', data.ContextId)
      .set('IsTaskFromMeeting', data.IsTaskFromMeeting)
      .set('MeetingId', id);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/CreateTaskFromMeeting',
      params,
    );
  }

  editTask(data: any): Observable<any> {
    const params = new HttpParams()
      .set('TaskID', data.taskId)
      .set('ProjectID', data.ProjectID)
      .set('TaskTitle', data.TaskTitle)
      .set('TaskDescription', data.TaskDescription)
      .set('ScheduleDate', data.ScheduleDate)
      .set('DueDate', data.DueDate)
      .set('Priority', data.Priority)
      .set('EstimatedHours', data.EstimatedHours)
      .set('Status', data.Status)
      .set('CanAssigneeSeeAllTask', data.CanAssigneeSeeAllTask)
      .set('ContextId', data.ContextId)
      .set('AssigendTo', data.AssigendTo)
      .set('ScheduleStartDatetime', data.ScheduleStartDatetime)
      .set('ScheduleEndDatetime', data.ScheduleEndDatetime);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/EditTaskList',
      params,
    );
  }

  getTaskDocuments(taskId: string) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/TaskDocuments/' + taskId,
    );
  }

  deleteTaskDocument(documentId: string) {
    return this.coreHttpService.httpDeleteRequest(
      environment.apiBaseUrl + 'ProjectTask/TaskDocument/',
      documentId,
    );
  }

  deleteTask(taskId: string) {
    return this.coreHttpService.httpDeleteRequest(
      environment.apiBaseUrl + 'ProjectTask',
      taskId,
    );
  }

  closeSideView() {
    this.closeSideViewOnCancel.emit(true);
  }

  deleteTaskComment(commentId) {
    return this.http.delete(
      environment.apiBaseUrl +
        API_ENDPOINTS.DELETE_TASK_COMMENT_FROM_MAIN_TASK +
        commentId,
    );
  }

  editTaskComment(taskId, Comment, commentId) {
    const body = new HttpParams()
      .set('TaskId', taskId)
      .set('Comment', Comment)
      .set('CommentId', commentId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_TASK_COMMENT_FROM_MAIN_TASK,
      body,
    );
  }

  closeOverlayOnSave() {
    this.closeSideViewOnSave.emit(true);
  }

  addTaskLog(taskId, taskDetails, logDate, hours) {
    const body = new HttpParams()
      .set('TaskId', taskId)
      .set('LogDate', logDate)
      .set('Hours', hours)
      .set('Notes', taskDetails)
      .set('userID', localStorage.getItem('userID'));

    return this.http.post(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_SUBTASK_FROM_MAIN_TASK,
      taskDetails,
      { params: body },
    );
  }

  updateTimeLog(taskId, hours, timeLogId, notes, logDate) {
    const body = new HttpParams()
      .set('TaskId', taskId)
      .set('Hours', hours)
      .set('TimeLogId', timeLogId)
      .set('Notes', notes)
      .set('LogDate', logDate)
      .set('userID', localStorage.getItem('userID'));

    return this.http.post(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_SUBTASK_FROM_MAIN_TASK,
      body,
    );
  }

  getTimeLogs(taskId: string) {
    const url =
      environment.apiBaseUrl + API_ENDPOINTS.LIST_TIME_LOG_API + taskId;

    return this.http.get<Response>(url);
  }

  deleteTimer(timeLogId) {
    return this.http.delete(
      environment.apiBaseUrl + API_ENDPOINTS.DELETE_TIMER + timeLogId,
    );
  }

  checkIsAdminLoggedIn() {
    const roles = localStorage.getItem('roles');
    if (roles) {
      if (roles?.toLowerCase().includes('app admin')) this.isAdmin = true;
    }
  }

  getAssociatedIssuesForTask(taskId: string) {
    return this.http.get<Response>(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ASSOCIATED_ISSUES_Task +
        taskId,
    );
  }

  getAssociatedCostEventsForTask(taskId: string) {
    return this.http.get<Response>(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ASSOCIATED_COST_EVENTS_TASK +
        taskId,
    );
  }

  deAssociateLinkedItems(linkFromId: string, linkToId: string) {
    return this.http.delete(
      environment.apiBaseUrl +
        API_ENDPOINTS.DIASSOCIATED_LINKED_ITEMS +
        linkFromId +
        '&LinkTo=' +
        linkToId,
    );
  }

  taskListByProjectIdWithSearch(
    id: string,
    searchTerms: string,
    issueId: string,
    costId: string,
  ) {
    return this.http.get<taskResponse>(
      environment.apiBaseUrl +
        API_ENDPOINTS.TASK_LIST_PROJECTID_WITH_SEARCH +
        id +
        (issueId ? '&issueId=' + issueId : '&costEventId=' + costId) +
        '&searchTerms=' +
        searchTerms,
    );
  }

  getAllIssuesByProjectIdWithSearch(
    id: string,
    searchTerms: string,
    taskId: string,
    costId: string,
  ) {
    return this.http.get<Response3>(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ISSUES_PROJECTID_SEARCH +
        id +
        (taskId ? '&taskId=' + taskId : '&costEventId=' + costId) +
        '&searchTerms=' +
        searchTerms,
    );
  }

  getAllCostEventsByProjectIdWithSearch(
    id: string,
    searchTerms: string,
    taskId: string,
    issueId: string,
  ) {
    return this.http.get<Response4>(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_COSTEVENTS_PROJECTID_SEARCH +
        id +
        (taskId ? '&taskId=' + taskId : '&issueId=' + issueId) +
        '&searchTerms=' +
        searchTerms,
    );
  }

  linkIssueToCostEvent(linkFromId: string, linkToIds) {
    const requestBody = {
      LinkFrom: linkFromId,
      LinkTo: linkToIds.join(','),
    };

    return this.http
      .post(
        environment.apiBaseUrl +
          API_ENDPOINTS.LINK_ISSUE_COST_EVENT +
          linkFromId +
          '&LinkTo=' +
          requestBody.LinkTo,
        requestBody,
      )
      .pipe(catchError(this.handleError));
  }

  linkTaskToIssue(linkFromId: string, linkToIds) {
    const requestBody = {
      LinkFrom: linkFromId,
      LinkTo: linkToIds.join(','),
    };

    return this.http
      .post(
        environment.apiBaseUrl +
          API_ENDPOINTS.LINK_TASK_ISSUE +
          linkFromId +
          '&LinkTo=' +
          requestBody.LinkTo,
        requestBody,
      )
      .pipe(catchError(this.handleError));
  }

  linkTaskToCostEvent(linkFromId: string, linkToIds) {
    const requestBody = {
      LinkFrom: linkFromId,
      LinkTo: linkToIds.join(','),
    };

    return this.http
      .post(
        environment.apiBaseUrl +
          API_ENDPOINTS.LINK_TASK_COST_EVENT +
          linkFromId +
          '&LinkTo=' +
          requestBody.LinkTo,
        requestBody,
      )
      .pipe(catchError(this.handleError));
  }

  private handleError(errorRes: HttpErrorResponse) {
    swal(errorRes.error.message);
    return throwError(errorRes);
  }

  updateTaskStatus(taskId, status): Observable<any> {
    const params = new HttpParams().set('TaskID', taskId).set('Status', status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/UpdateTaskStatus',
      params,
    );
  }
}
