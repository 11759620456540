import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin as observableForkJoin } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CoreHttpService } from '../../../core/core-http.service';
import {
  ProgramNameResponse,
  VirtualProgramNameResponse,
} from '../projectModel';
export interface Projects {
  project: ProjectList[];
}
export interface ProjectList {
  virtualProgramDetails: Projects[];
}
export interface ProjectList {
  projectNumber: string;
  projectName: string;
  projectManager: string;
  projectStatus: string;
  projectType: string;
  cEs: number;
  issues: number;
  estimatedCost: string;
  isActive: boolean;
  createdOn: string;
}

@Injectable()
export class ProjectlistService {
  constructor(private coreHttpService: CoreHttpService) {}

  getProjectList(programNumber): Observable<Projects> {
    return this.coreHttpService.httpGetRequest<Projects>(
      environment.apiBaseUrl + 'Project?programNumber=' + programNumber,
    );
  }

  getProjectListByProgramId(programId): Observable<Projects> {
    let url = environment.apiBaseUrl + 'Project/GetProjectByProgramId';
    
    if (programId) {
      url += `/?programId=${programId}`;
    }

    return this.coreHttpService.httpGetRequest<Projects>(url);
  }

  GetAllProjects(): Observable<Projects> {
    return this.coreHttpService.httpGetRequest<Projects>(
      environment.apiBaseUrl + '/GetAllProjects',
    );
  }

  getProjectListByStausAndProgram(status, programId): Observable<any[]> {
    const body = {
      programId: programId,
      status: status,
    };
    const response1 = this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectByStausAndProgram',
      body,
      httpOptions,
    );
    return observableForkJoin([response1]);
  }

  getProjectListByStausAndLocation(status, locationId): Observable<any[]> {
    const body = {
      locationId: locationId,
      status: status,
    };
    const response1 = this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectByStausAndLocation',
      body,
      httpOptions,
    );
    return observableForkJoin([response1]);
  }

  getEditableFields() {
    const response1 = this.coreHttpService.httpGetRequest<ProgramNameResponse>(
      environment.apiBaseUrl + 'Program?status=null&isOnlyShortList=true',
    );
    return observableForkJoin([response1]);
  }

  getVirtualProgramList() {
    const response1 =
      this.coreHttpService.httpGetRequest<VirtualProgramNameResponse>(
        environment.apiBaseUrl + 'Program/GetAllVirtualPrograms',
      );
    return observableForkJoin([response1]);
  }
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};
