import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CoreHttpService } from '../../core/core-http.service';

export interface AuthUser {
  token: string;
  success: string;
  message: string;
  userID: string;
  roles: string[];
  displayName: string;
  clientNumber: number;
  userName: string;
  config: config;
  profilePicture: string;
  permissions: string[];
}
export interface config {
  currency: string;
  dateTimeFormat: dateTimeFormat;
  language: string;
  timeZone: string;
}

export interface dateTimeFormat {
  dateTimeFormat: string;
  dateFormat: string;
  timeFormat: string;
  reportDateTimeFormat: string;
}

@Injectable()
export class LoginService {
  Login(username: string, password: string) {
    const body = {
      username: username,
      password: password,
    };
    const reqHeaders = new HttpHeaders({ 'No-Auth': 'true' });
    // return this.http.post<AuthUser>(
    //   environment.apiBaseUrl + 'login',
    //   body,
    //   httpOptions
    // );
    return this.coreHttpService.httpPostRequest<any, any>(
      environment.apiBaseUrl + 'login',
      body,
      reqHeaders,
    );
  }

  GetDefaultImage() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'GetDefaultImage',
    );
  }

  getRoles() {
    //return null;
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Directory',
    );
  }

  switchDirectory(clinetID) {
    const params = new HttpParams().set('clientID', clinetID);
    return this.coreHttpService.httpPutRequest<any, any>(
      environment.apiBaseUrl + 'Directory',
      params,
    );
  }

  constructor(private coreHttpService: CoreHttpService) {}

  getLogo() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Customer/Logo',
    );
  }
}
