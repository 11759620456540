import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SendmailService } from './sendmail.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from '@angular/material/snack-bar';
import { DocumentService } from '../../../pages/projects/Additional-feature/document.service';
import { MESSAGE } from '@app/pages/projects/Task-management/constants';
import { showSnackBar } from '@app/shared/common/function';

@Component({
  selector: 'app-sendemail',
  templateUrl: './sendemail.component.html',
  styleUrls: ['./sendemail.component.css'],
})
export class SendemailComponent implements OnInit, OnDestroy {
  mailForm: FormGroup;
  isLoading = false;
  selectAll = false;
  csvCheck = false;
  excelCheck = false;
  pdfCheck = false;
  exportFileType = [];
  dataList: any;
  message: string;
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  showLoader: boolean;
  showForm = false;
  isFromPayApplication = false;
  isCloseOutPDF = false;
  projectId: string;
  isCCRPdf = false;
  isWarrantyHistoryPdf = false;
  isAssetDetailPdf = false;
  isWarrantyClaimPdf = false;
  isOrganizationDetailPdf = false;
  isPurchaseRequestPdf = false;
  isPaymentRequestPdf = false;

  constructor(
    public dialogRef: MatDialogRef<SendemailComponent>,
    private _mailService: SendmailService,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data,
    private _documentService: DocumentService,
  ) {}

  ngOnInit(): void {
    this.dataList = this.data.selectedData;
    this.isFromPayApplication = this.data.isFromPayApplication;
    this.isCloseOutPDF = this.data['isCloseOutPDF'];
    this.projectId = this.data['projectId'];
    this.isCCRPdf = this.data['isCCRPdf'];
    this.isWarrantyHistoryPdf = this.data['isWarrantyHistoryPdf'];
    this.isAssetDetailPdf = this.data['isAssetDetailPdf'];
    this.isWarrantyClaimPdf = this.data['isWarrantyClaimPdf'];
    this.isOrganizationDetailPdf = this.data['isOrganizationDetailPdf'];
    this.isPurchaseRequestPdf = this.data['isFromOdpPurchase'];
    this.isPaymentRequestPdf = this.data['isFromOdpPayment'];

    if (
      !this.isFromPayApplication &&
      !this.isCCRPdf &&
      !this.isWarrantyHistoryPdf &&
      !this.isAssetDetailPdf &&
      !this.isWarrantyClaimPdf &&
      !this.isOrganizationDetailPdf &&
      !this.isPurchaseRequestPdf &&
      !this.isPaymentRequestPdf
    ) {
      this.mailForm = new FormGroup({
        email: new FormControl(null, {
          validators: [
            Validators.required,
            Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
          ],
        }),
        subject: new FormControl(null, {
          validators: [],
        }),
        cc: new FormControl(null, {}),
        bcc: new FormControl(null, {
          validators: [
            Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
          ],
        }),
        content: new FormControl(null, {}),
      });
    } else {
      this.mailForm = new FormGroup({
        email: new FormControl(null, {
          validators: [Validators.required],
        }),
        subject: new FormControl(null, {}),
        cc: new FormControl(null, {}),
        bcc: new FormControl(null, {
          validators: [
            Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
          ],
        }),
        content: new FormControl(null, {}),
      });
    }
  }

  setPatternValidator() {
    this.mailForm
      .get('email')
      .setValidators(
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'),
      );
  }

  commaSepEmail = (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value.split(',');
    const forbidden = emails.some((email) =>
      Validators.email(new FormControl(email)),
    );
    return forbidden ? { email: { value: control.value } } : null;
  };

  changed() {
    this.exportFileType = [];
    if (this.selectAll) {
      this.exportFileType.push(1); // EXCEL
      this.exportFileType.push(2); // PDF
      this.exportFileType.push(3); // CSV
      this.showForm = true;
    } else {
      if (this.excelCheck) {
        this.exportFileType.push(1); // EXCEL
      }
      if (this.pdfCheck) {
        this.exportFileType.push(2); // PDF
      }
      if (this.csvCheck) {
        this.exportFileType.push(3); // CSV
      }
      this.showForm = true;
    }
    this._mailService.setExportItems(this.exportFileType);
  }

  onSendEmail() {
    const emailContent = {
      fileName: JSON.stringify(this.dataList),
      exportFileTypes: this.exportFileType.toString(),
      email: this.mailForm.value.email,
      subject: this.mailForm.value.subject,
      cc: this.mailForm.value.cc,
      bcc: this.mailForm.value.bcc,
      header: this.data.header,
      contet:
        this.mailForm.value.content == null ? '' : this.mailForm.value.content,
    };

    if (
      !this.isFromPayApplication &&
      !this.isCCRPdf &&
      !this.isWarrantyClaimPdf &&
      !this.isAssetDetailPdf &&
      !this.isWarrantyClaimPdf &&
      !this.isOrganizationDetailPdf &&
      !this.isPurchaseRequestPdf &&
      !this.isPaymentRequestPdf
    ) {
      this._mailService
        .sendEmail(
          emailContent.fileName,
          emailContent.exportFileTypes,
          emailContent.email,
          emailContent.subject,
          emailContent.contet,
          emailContent.cc,
          emailContent.header,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this._mailService.setExportItems(this.exportFileType);
          this.dialogRef.close();
        });
    } else if (
      this.isFromPayApplication &&
      !this.isCloseOutPDF &&
      !this.isCCRPdf &&
      !this.isWarrantyClaimPdf &&
      !this.isAssetDetailPdf &&
      !this.isWarrantyClaimPdf &&
      !this.isOrganizationDetailPdf &&
      !this.isPurchaseRequestPdf &&
      !this.isPaymentRequestPdf
    ) {
      this._documentService
        .sendMailToFinanceManagers(
          emailContent.email,
          emailContent.cc,
          this.data.payApplicaitonId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isCCRPdf) {
      this._documentService
        .sendCCRMail(
          emailContent.email,
          emailContent.cc,
          this.projectId,
          this.data.ccrId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isWarrantyHistoryPdf) {
      this._documentService
        .sendClaimAttachmentEmail(
          emailContent.email,
          emailContent.cc,
          this.data.claimId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isAssetDetailPdf) {
      this._documentService
        .sendProjectAssetAttachmentEmail(
          emailContent.email,
          emailContent.cc,
          this.data.assetId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isWarrantyClaimPdf) {
      this._documentService
        .sendClaimAttachmentEmail(
          emailContent.email,
          emailContent.cc,
          this.data.id,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isOrganizationDetailPdf) {
      this._documentService
        .sendOrganizationAttachmentEmail(
          emailContent.email,
          emailContent.cc,
          this.data.organizationId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isPurchaseRequestPdf) {
      this._documentService
        .sendOdpPurchaseEmail(
          emailContent.email,
          emailContent.cc,
          this.data.purchaseRequestId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    } else if (this.isPaymentRequestPdf) {
      this._documentService
        .sendOdpPaymentEmail(
          emailContent.email,
          emailContent.cc,
          this.data.purchaseRequestId,
        )
        .subscribe(() => {
          showSnackBar(this.snackBar, MESSAGE.EMAIL_CREATED, 'top');
        });
    } else {
      this._documentService
        .sendCloseOutMail(
          emailContent.email,
          emailContent.cc,
          this.projectId,
          this.data.payApplicaitonId,
        )
        .subscribe(() => {
          this.message = MESSAGE.EMAIL_CREATED;
          this.snackBar.open(this.message, '', {
            duration: 1000,
            verticalPosition: this.verticalPosition,
            panelClass: 'custom-snackbar-class',
          });
          this.dialogRef.close();
        });
    }
  }
  ngOnDestroy(): void {}
}
