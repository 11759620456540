export enum WarrantyManagementStatusEnum {
  'Review Pending' = 0,
  'Claim In Review' = 1,
  'Work In Progress' = 2,
  'Claim Rejected' = 3,
  'Work In Review' = 4,
  'Completed' = 5,
  'Work Rejected' = 6,
  'Cancel Claim' = 7,
}
