import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterProject',
})
export class FilterPipe implements PipeTransform {
  constructor() {}
  transform(customers: any = [], arg: boolean): any {
    if (arg === undefined) {
      return customers.filter(
        (customer) => customer.isActive === true || customer.isActive === false,
      );
    } else {
      return customers.filter((customer) => customer.isActive === arg);
    }
  }
}
