import { forkJoin as observableForkJoin, Observable, Subject } from 'rxjs';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import {
  ProjectManagerResponse,
  BudgetInfoResponse,
  ContractResponse,
  ProgramNameResponse,
  ContingencyResponse,
  ChangeOrderResponse,
  TemplateValues,
  InvoiceList,
  InvoiceDetail,
  CeList,
  InvoiceAdditionalField,
  InvoiceManagerResponse,
  InvoiceInfo,
  AEManagerResponse,
  CMManagerResponse,
  CloseOutSpecialistResponse,
  ProjectResponse,
} from '../../projectModel';
import { CoreHttpService } from '../../../../core/core-http.service';
import { API_ENDPOINTS } from '../../Task-management/constants';
export interface Invoices {
  invoice: InvoiceInfo[];
}
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
  }),
};
export class FundingSourcesC {
  FundingSourceId: string;
  Amount: number;
}
@Injectable()
export class ViewprojectinfoService {
  clientNumb: string;
  updateProjectInfo = new EventEmitter();

  private _listners = new Subject<any>();

  listen(): Observable<any> {
    return this._listners.asObservable();
  }

  changeView(viewName: string) {
    this._listners.next(viewName);
  }

  constructor(
    private http: HttpClient,
    private coreHttpService: CoreHttpService,
  ) {}
  addFundingSource(FundingSourceData) {
    const body = new HttpParams().set('CategoryName', FundingSourceData);
    // return this.http.post(environment.apiBaseUrl + 'FundingSource/addFundingSource/', body);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl +
        'FundingSourceCategory/AddFundingSourceCategory/',
      body,
    );
  }

  getProjectInfo(projectid: string) {
    this.clientNumb = localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + 'Project/' + projectid,
    );
  }

  getProjectDetailsById(projectid: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'Project/GetShortProjectDetailsByID/' +
        projectid,
    );
  }

  getEditableFields() {
    const response1 = this.coreHttpService.httpGetRequest<ProgramNameResponse>(
      environment.apiBaseUrl + 'Program?status=null&isOnlyShortList=true',
    );
    const response2 = this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectType?status=null&isOnlyShortList=true',
    );
    const response3 = this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'User/Client/' +
        this.clientNumb +
        '/ProjectManager',
    );
    const response4 = this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'User/Client/' +
        this.clientNumb +
        '/AssociateProjectManager',
    );
    const response5 = this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'User/Client/' + this.clientNumb + '/AEManager',
    );
    const response6 = this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'User/Client/' + this.clientNumb + '/CMManager',
    );
    return observableForkJoin([
      response1,
      response2,
      response3,
      response4,
      response5,
      response6,
    ]);
  }

  getProjectManager() {
    this.clientNumb = localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<ProjectManagerResponse>(
      environment.apiBaseUrl +
        'User/Client/' +
        this.clientNumb +
        '/ProjectManager',
    );
  }
  getActivateLocations(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Location/Activelocations',
    );
  }
  getInvoiceManager() {
    this.clientNumb = localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<InvoiceManagerResponse>(
      environment.apiBaseUrl +
        'User/Client/' +
        this.clientNumb +
        '/InvoiceManager',
    );
  }

  getAEManager() {
    this.clientNumb = localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<AEManagerResponse>(
      environment.apiBaseUrl + 'User/Client/' + this.clientNumb + '/AEManager',
    );
  }
  getCMManager() {
    this.clientNumb = localStorage.getItem('clientNumber');
    return this.coreHttpService.httpGetRequest<CMManagerResponse>(
      environment.apiBaseUrl + 'User/Client/' + this.clientNumb + '/CMManager',
    );
  }

  getProjectTypes(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectType?status=null&isOnlyShortList=true',
    );
  }

  getProgramNames() {
    return this.coreHttpService.httpGetRequest<ProgramNameResponse>(
      environment.apiBaseUrl + 'Program?status=null&isOnlyShortList=true',
    );
  }

  getProjectKeyDate() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Customer/ProjectKeyDate',
    );
  }

  getProjectAdditionalInfo() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Customer/ProjectAdditionalText',
    );
  }
  getAssosiateManager() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'User/Client/' +
        this.clientNumb +
        '/AssociateProjectManager',
    );
  }
  getScurveData(peojectID): Observable<any> {
    const response1 = this.coreHttpService.httpGetWithoutLoaderRequest<any>(
      environment.apiBaseUrl +
        'KPI/GetSCurveInvoiceData?ProjectID=' +
        peojectID,
    );
    return observableForkJoin([response1]);
  }

  getCloseOutSpecialist() {
    return this.coreHttpService.httpGetRequest<CloseOutSpecialistResponse>(
      environment.apiBaseUrl + 'User/Client' + '/CloseOutSpecialist',
    );
  }

  getDirectors() {
    return this.coreHttpService.httpGetRequest<any>(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_DIRECTORS,
    );
  }

  // New project Information API
  // getProjectInformation(ProjectID) {
  //   this.clientNumb = localStorage.getItem('clientNumber');
  //   return this.coreHttpService.httpGetWithoutLoaderRequestForCloseLoader(environment.apiBaseUrl + 'Project/ProjectInformationByID?ProjectID=' + ProjectID);
  // }
  putProjectInfo(
    ProjectId: string,
    ProjectNumber: string,
    ProjectName: string,
    Projectimage: string,
    ProjectTypeId: string,
    ProgramId: string,
    Address1: string,
    Address2: string,
    City: string,
    county: string,
    country: string,
    State: string,
    ZipCode: string,
    Budget: string | number,
    EstimatedCost: string | number,
    PMId: string,
    AssociatePMId: any,
    CM: string,
    AE: string,
    Description: string,
    PlanningDate: string,
    DesignDate: string,
    ConstructionDate: string,
    CloseOutDate: string,
    KeyDates: string,
    AdditionalTexts: string,
    WbsCode: string,
    IMId: string,
    selectedFundingSourceCategory: string,
    AssociateFCId: any,
    Location: string,
    roomNo: string,
    roomName: string,
    buildingNo: string,
    buildingName: string,
    campusId: string,
    estimatedsoftcostPercent: string | number,
    estimatedsoftcost: string | number,
    estimatedConstructionPercent: string | number,
    estimatedConstruction: string | number,
    approvedsoftcostPercent: string | number,
    approvedsoftcost: string | number,
    approvedConstructionPercent: string | number,
    approvedConstruction: string | number,
    tags: string,
    closeOutSpecialistId: string,
    directorId: string,
    odpReserve: string,
    organizationArchitectEngineer: string,
    architectEngineerPrimaryContact: string,
    architectEngineerSecondaryContact: string,
    organizationConstructionManager: string,
    constructionManagerPrimaryContact: string,
    constructionManagerSecondaryContact: string,
    organizationVendor: string[],
    organizationClient: string,
    salesTaxPercentage: string,
  ) {
    const body = {
      projectID: ProjectId,
      projectName: ProjectName,
      projectNumber: ProjectNumber,
      projectTypeId: ProjectTypeId,
      programId: ProgramId,
      address1: Address1,
      address2: Address2,
      city: City,
      County: county,
      Country: country,
      state: State,
      zipCode: ZipCode,
      budget: Budget,
      estimatedCost: EstimatedCost,
      pmId: PMId,
      associatePMId: AssociatePMId,
      cm: CM,
      ae: AE,
      description: Description,
      projectImage: Projectimage,
      planningDate: PlanningDate,
      designDate: DesignDate,
      constructionDate: ConstructionDate,
      closeOutDate: CloseOutDate,
      keyDates: KeyDates,
      additionalTexts: AdditionalTexts,
      wbsCode: WbsCode,
      IMId: IMId,
      buildingNo: buildingNo,
      buildingName: buildingName,
      roomNo: roomNo,
      roomName: roomName,
      FundingSourceCategory: selectedFundingSourceCategory,
      AssociateFundingSourceIds: AssociateFCId,
      Location: Location,
      CampusId: campusId,
      estimatedSoftCostPercentage: estimatedsoftcostPercent,
      estimatedSoftCostValue: estimatedsoftcost,
      estimatedConstructionCostPercentage: estimatedConstructionPercent,
      estimatedConstructionCostValue: estimatedConstruction,
      approvedSoftBudgetPercentage: approvedsoftcostPercent,
      approvedSoftBudgetValue: approvedsoftcost,
      approvedConstructionBudgetPercentage: approvedConstructionPercent,
      approvedConstructionBudgetValue: approvedConstruction,
      tags: tags,
      CloseOutSpecialistId: closeOutSpecialistId,
      directorId: directorId,
      odpReserve: odpReserve,
      organizationArchitectEngineer: organizationArchitectEngineer,
      architectEngineerPrimaryContact: architectEngineerPrimaryContact,
      architectEngineerSecondaryContact: architectEngineerSecondaryContact,
      organizationConstructionManager: organizationConstructionManager,
      constructionManagerPrimaryContact: constructionManagerPrimaryContact,
      constructionManagerSecondaryContact: constructionManagerSecondaryContact,
      organizationVendor: organizationVendor,
      organizationClient: organizationClient,
      salesTax: salesTaxPercentage,
    };
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Project',
      body,
      httpOptions,
    );
  }

  // create a new  project
  addProjectInfo(submitdata, KeyDates, AdditionalTexts, BudgetData, imagedata) {
    const body = {
      projectNumber: submitdata.projectno,
      projectName: submitdata.projectName,
      projectTypeId: submitdata.projectTypeId,
      programId: submitdata.programId,
      address1: submitdata.address1,
      address2: submitdata.address2,
      city: submitdata.city,
      county: submitdata.county,
      country: submitdata.country,
      state: submitdata.state,
      zipCode: submitdata.zipcode,
      budget: submitdata.budget,
      estimatedCost: submitdata.estimatedCost,
      pmId: submitdata.projectManager,
      associatePMId: submitdata.associatePMId
        ? submitdata.associatePMId.join()
        : '',
      description: submitdata.projectDesc,
      planningDate: submitdata.planningDate,
      designDate: submitdata.designDate,
      constructionDate: submitdata.constructionDate,
      closeOutDate: submitdata.closeoutDate,
      keyDates: KeyDates,
      additionalTexts: AdditionalTexts,
      projectImage: imagedata,
      ae: submitdata.architect,
      cm: submitdata.constructionManager,
      budgetItem: BudgetData,
      wbsCode: submitdata.wbsCode,
      AssociateFundingSourceIds: submitdata.AssociateFundingSourceIds,
      FundingSourceCategory: submitdata.FundingSourceCategory,
      IMId: submitdata.InvoiceManager,
      Location: submitdata.Location,
      buildingNo: submitdata.buildingNo,
      buildingName: submitdata.buildingName,
      roomNo: submitdata.roomNo,
      roomName: submitdata.roomName,
      campusId: submitdata.campusId,
      EstimatedSoftCostPercentage: submitdata.estimatedSoftCostPercentage,
      EstimatedSoftCostValue: submitdata.estimatedSoftCostValue,
      EstimatedConstructionCostPercentage:
        submitdata.estimatedConstructionCostPercentage,
      EstimatedConstructionCostValue: submitdata.estimatedConstructionCostValue,
      ApprovedSoftBudgetPercentage: submitdata.approvedSoftBudgetPercentage,
      ApprovedSoftBudgetValue: submitdata.approvedSoftBudgetValue,
      ApprovedConstructionBudgetPercentage:
        submitdata.approvedConstructionBudgetPercentage,
      ApprovedConstructionBudgetValue:
        submitdata.approvedConstructionBudgetValue,
      Tags: submitdata.Tags,
      CloseOutSpecialistId: submitdata.closeOutSpecialistId,
      directorId: submitdata.directorId,
      odpReserve: submitdata.odpReserve,
      organizationArchitectEngineer: submitdata.OrganizationArchitectEngineer,
      architectEngineerPrimaryContact:
        submitdata.ArchitectEngineerPrimaryContact,
      architectEngineerSecondaryContact:
        submitdata.ArchitectEngineerSecondaryContact,
      organizationConstructionManager:
        submitdata.OrganizationConstructionManager,
      constructionManagerPrimaryContact:
        submitdata.ConstructionManagerPrimaryContact,
      constructionManagerSecondaryContact:
        submitdata.ConstructionManagerSecondaryContact,
      organizationClient: submitdata.OrganizationClient,
      organizationVendor: submitdata.OrganizationVendor,
      salesTax: submitdata.salesTax,
    };

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Project',
      body,
      httpOptions,
    );
  }

  getContractInfo(projectid) {
    // const body = new HttpParams()
    // .set('ProjectName', submitdata.projectname);

    return this.coreHttpService.httpGetRequest<ContractResponse>(
      environment.apiBaseUrl + 'CostEvent/Contract/Project/' + projectid,
    );
  }

  getAllCostevents(projectid) {
    return this.coreHttpService.httpGetRequest<ContractResponse>(
      environment.apiBaseUrl + 'CostEvent/All/Project/' + projectid,
    );
  }

  getChangeOrderInfo(projectid) {
    return this.coreHttpService.httpGetRequest<ChangeOrderResponse>(
      environment.apiBaseUrl + 'Costevent/ChangeOrder/Project/' + projectid,
    );
  }

  getContingencyInfo(projectid) {
    return this.coreHttpService.httpGetRequest<ContingencyResponse>(
      environment.apiBaseUrl + 'CostEvent/Contingency/Project/' + projectid,
    );
  }

  getBudgetItems() {
    return this.coreHttpService.httpGetRequest<ProgramNameResponse>(
      environment.apiBaseUrl + 'BudgetItem',
    );
  }
  //select box
  getActiveBudgetItems() {
    return this.coreHttpService.httpGetWithoutLoaderRequest<ProgramNameResponse>(
      environment.apiBaseUrl + 'ActiveBudgetItem',
    );
  }
  //selectbox
  getBudgetTemplates(): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest<ProgramNameResponse>(
      environment.apiBaseUrl + 'BudgetTemplate',
    );
  }
  getTemplateValues(tempId) {
    return this.coreHttpService.httpGetWithoutLoaderRequestForCloseLoader<TemplateValues>(
      environment.apiBaseUrl + 'BudgetTemplate/' + tempId + '/BudgetItem',
    );
  }

  getBudgetInfo(projectid: string) {
    return this.coreHttpService.httpGetRequest<BudgetInfoResponse>(
      environment.apiBaseUrl + 'Project/' + projectid,
    );
  }

  putBudgetItems(projectid: string, budgetData, isDelete = false) {
    const body = new HttpParams()
      .set('ProjectId', projectid)
      .set('BudgetItem', budgetData)
      .set('IsDelete', isDelete);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Project/BudgetItem',
      body,
    );
  }

  // Update Approved Budget;
  updateApprovedProjectBudget(projectid, budget, estimatedCost) {
    const body = new HttpParams()
      .set('projectID', projectid)
      .set('Budget', budget)
      .set('EstimatedCost', estimatedCost);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Project/' + projectid + '/UpdateApprovedBudget',
      body,
    );
  }
  getInvoiceList(projectid) {
    return this.coreHttpService.httpGetWithoutLoaderRequest<InvoiceList>(
      environment.apiBaseUrl + 'Project/' + projectid + '/Invoice',
    );
  }

  getInvoiceListByBudget(BudgetItemId, ProjectId) {
    const body = new HttpParams()
      .set('BudgetItemId', BudgetItemId)
      .set('ProjectId', ProjectId);
    return this.coreHttpService.httpPostRequest<any, any>(
      environment.apiBaseUrl + 'BudgetIdwiseInvoice/',
      body,
    );
  }

  getInvoiceInfo(invoiceId): Observable<any> {
    return this.coreHttpService.httpGetRequest<InvoiceDetail>(
      environment.apiBaseUrl + 'Invoice/' + invoiceId,
    );
  }

  getInvoiceCreateFields(projectid) {
    const response1 =
      this.coreHttpService.httpGetRequest<InvoiceAdditionalField>(
        environment.apiBaseUrl + 'Customer/InvoiceAdditionalFields',
      );
    const response2 = this.coreHttpService.httpGetRequest<CeList>(
      `${environment.apiBaseUrl}Project/${projectid}/CostEvent?ignoreRoleRestrictionsForInvoice=true`,
    );

    return observableForkJoin([response1, response2]);
  }

  createInvoice(
    costEventId: string,
    addInfo: string,
    inoviceDate: string,
    invoiceAmount: string,
    invoiceNumber: string,
    invoiceNote: string,
    subcontractorName: string,
    invoiceReceivedDate: string,
    datePaid: string,
    InvoiceCategory: string,
    FundingSourceList: any,
    IsAmountOverBudget: any,
  ): Observable<any> {
    const body = new HttpParams()
      .set('InvoiceNumber', invoiceNumber)
      .set('InvoiceNote', invoiceNote)
      .set('SubcontractorName', subcontractorName)
      .set('InvoiceDate', inoviceDate)
      .set('InvoiceAmount', invoiceAmount)
      .set('InvoiceReceivedDate', invoiceReceivedDate)
      .set('CostEventId', costEventId)
      .set('InvoicePaidDate', datePaid)
      .set('InvoiceCategory', InvoiceCategory)
      .set(
        'InvoiceXFundingSources',
        this.formatFundingSources(FundingSourceList),
      )
      .set('IsAmountOverBudget', IsAmountOverBudget);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Invoice',
      body,
    );
    console.log(addInfo);
  }

  formatFundingSources(fundingsources: FundingSourcesC[]) {
    let data = '[';
    for (let i = 0; i < fundingsources.length; i++) {
      data +=
        '{FundingSourceId:"' +
        fundingsources[i].FundingSourceId +
        '",Amount:' +
        fundingsources[i].Amount +
        '}';
      if (fundingsources.length > 1 && i + 1 !== fundingsources.length) {
        data += ',';
      }
    }
    data += ']';
    return data;
  }

  updateInvoice(
    invoiceId: string,
    invoiceAmount: string,
    addInfo: string,
    statusId: string,
    invoiceNumber?: string,
    invoiceNote?: string,
    subcontractorName?: string,
    invoiceReceivedDate?: string,
    datePaid?: string,
    InvoiceCategory?: string,
    inoviceDate?: string,
    costEventId?: string,
  ) {
    const body = new HttpParams()
      .set('InvoiceId', invoiceId)
      .set('InvoiceAmount', invoiceAmount)
      .set('AdditionalField', addInfo)
      .set('InvoiceStatusId', statusId)
      .set('InvoiceNumber', invoiceNumber)
      .set('InvoiceNote', invoiceNote)
      .set('SubcontractorName', subcontractorName)
      .set('InvoiceDate', inoviceDate)
      .set('InvoiceReceivedDate', invoiceReceivedDate)
      .set('CostEventId', costEventId)
      .set('InvoicePaidDate', datePaid)
      .set('InvoiceCategory', InvoiceCategory);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Invoice',
      body,
    );
  }

  deleteProject(projectID) {
    return this.http.delete(environment.apiBaseUrl + 'Project/' + projectID);
  }

  activateProject(projectID) {
    const body = new HttpParams().set('projectID', projectID);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Project/' + projectID + '/Activate',
      body,
    );
  }
  updateinvoicecomment(InvoiceId: string, Comment: string): Observable<any> {
    const body = new HttpParams()
      .set('InvoiceId', InvoiceId)
      .set('Comment', Comment);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Invoice/ReviewComment',
      body,
    );
  }

  getAllOwnerCode(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_OWNER_CODE,
    );
  }

  getAllActiveOwnerCode(): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_ACTIVE_OWNER_CODE,
    );
  }

  getAllCmCgCode(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_CMGC_CODE,
    );
  }

  getActiveCmCgCode(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_Active_CMGC_CODE,
    );
  }

  getAllUniformatDevisionCode() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_UNIFORMAT_DIVISION_CODE,
    );
  }

  getActiveUniformatDevisionCode() {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_UNIFORMAT_DIVISION_CODE,
    );
  }

  getActiveCSIdivisionCode() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CSI_DIVISION_CODE,
    );
  }

  UpdateBudgetXCmGcSource(projectId: string, sourceCode: number) {
    const body = new HttpParams()
      .set('projectId', projectId)
      .set('sourceCode', sourceCode);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.UPDATE_CMGC_SOURCE,
      body,
    );
  }

  liteGetProjectDetailsByProjectId(projectId): Observable<ProjectResponse> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.LITE_GET_PROJECT_DETAILS_BY_PROJECTID +
        '?projectId=' +
        projectId,
    );
  }

  getBudgetAndProjectInfo(projectid: string) {
    return this.coreHttpService.httpGetRequest<BudgetInfoResponse>(
      environment.apiBaseUrl +
        'GetProjectAndBudgetInformationByProjectId?projectId=' +
        projectid,
    );
  }

  getProjectsBudgetInfo(projectid: string) {
    return this.coreHttpService.httpGetRequest<any>(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PROJECTS_BUDGETITEMS +
        projectid,
    );
  }
}
