import {
  API_ENDPOINTS,
  ROUTE_CONSTANT,
} from '../../../app/pages/projects/Task-management/constants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  VendorTypeList,
  VendorList,
  ProgramListResponse,
  Program,
  ContractType,
  ContractTypeList,
  ProgramResponse,
  ProgramList,
  ContractTypeListResponse,
  ServiceTypeListResponse,
  ServiceType,
  ServiceTypeList,
  CostEventReasonResponse,
  CostEventTypeListResponse,
  Reason,
  CostEventTypeCategoriesListResponse,
  VirtualProgramResponse,
  ActiveVirtualProgramResponse,
  BudgetCategoryResponse,
  CmGcInfoResponce,
  PayApplicationCategoryResponse,
  PayApplicationTemplateResponse,
} from '../master/master.model';
import { CostEventType } from '../costevents/costeventModel';
import { CostEventTypeResponse } from '../projects/projectModel';
import { CoreHttpService } from '../../core/core-http.service';
import { Tasklist } from '../projects/Task-management/task-management.model';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class MasterService {
  getBudgetLineItem: any;

  constructor(
    private http: HttpClient,
    private coreHttpService: CoreHttpService,
  ) {}

  getVendorNames() {
    return this.coreHttpService.httpGetRequest<VendorTypeList>(
      environment.apiBaseUrl + 'Vendor',
    );
  }

  getProgramList() {
    return this.coreHttpService.httpGetRequest<ProgramListResponse>(
      environment.apiBaseUrl + 'Program',
    );
  }

  getContractTypeList() {
    return this.coreHttpService.httpGetRequest<ContractTypeListResponse>(
      environment.apiBaseUrl + 'ContractType',
    );
  }

  addCostEventType(costEventType: CostEventType) {
    const body = new HttpParams()
      .set('costEventTypeName', costEventType.costEventTypeName)
      .set('costEventTypeDescription', costEventType.costEventTypeDescription)
      .set('costEventCategoryId', costEventType.costEventCategoryId)
      .set('parentCostEventTypeId', costEventType.parentCostEventTypeId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Project/Issue/AddCostEventType',
      body,
    );
  }

  updateCostEventType(costEventType: CostEventType) {
    const body = new HttpParams()
      .set('costEventTypeId', costEventType.costEventTypeId)
      .set('costEventTypeName', costEventType.costEventTypeName)
      .set('costEventTypeDescription', costEventType.costEventTypeDescription)
      .set('costEventCategoryId', costEventType.costEventCategoryId)
      .set('parentCostEventTypeId', costEventType.parentCostEventTypeId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Project/Issue/UpdateCostEventType',
      body,
    );
  }

  getCostEventTypeList() {
    return this.coreHttpService.httpGetRequest<CostEventTypeListResponse>(
      environment.apiBaseUrl + 'Project/Issue/GetCostEventTypesView',
    );
  }

  getCostEventTypeCategoryList() {
    return this.coreHttpService.httpGetRequest<CostEventTypeCategoriesListResponse>(
      environment.apiBaseUrl + 'Project/Issue/CostEventCategories',
    );
  }

  getServiceTypeList() {
    return this.coreHttpService.httpGetRequest<ServiceTypeListResponse>(
      environment.apiBaseUrl + 'ServiceType',
    );
  }

  getCostEventReasonList() {
    return this.coreHttpService.httpGetRequest<CostEventReasonResponse>(
      environment.apiBaseUrl + 'CostEventReason',
    );
  }

  getCloseoutItemDetails(closeOutItemId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'Closeout/GetCloseoutItemDetailById/' +
        closeOutItemId,
    );
  }

  getActiveBuildingData(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'EntityConfig/GetAllBuildings',
    );
  }

  GetRoomsByBuilding(buildingId): Observable<any> {
    const body = {
      buildingId: buildingId,
    };

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'EntityConfig/GetRoomsByBuilding',
      body,
    );
  }

  getRooms(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'EntityConfig/GetAllRooms',
    );
  }

  // API for creating new user
  createProgram(program: Program) {
    let headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams()
      .set('ProgramName', program.programName)
      .set('ProgramNumber', program.programNumber)
      .set('ProgramBudget', program.programBudget)
      .set('ProgramDescription', program.programDescription)
      .set('IsVirtual', program.IsVirtual);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Program',
      params,
    );
  }
  // API for creating new Closeout

  createCloseOut(model) {
    let headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');

    const params = new HttpParams()
      .set('CloseOutItemName', model.title)
      .set(
        'ParentCloseOutItemId',
        model.selectedParentItem != null && model.selectedParentItem.length > 0
          ? model.selectedParentItem[0].closeOutItemId
          : null,
      )
      .set(
        'DivisonCode',
        model.selectedCsiDivision != null &&
          model.selectedCsiDivision.length > 0
          ? model.selectedCsiDivision[0].id
          : null,
      )
      .set(
        'UniformatDivisonCode',
        model.selectedUniFormatDivision != null &&
          model.selectedUniFormatDivision.length > 0
          ? model.selectedUniFormatDivision[0].id
          : null,
      )
      .set(
        'BuildingNameOrNumber',
        model.buildingnamenumber != null && model.buildingnamenumber.length > 0
          ? model.buildingnamenumber[0].uniqueId
          : null,
      )
      .set(
        'RoomNameOrNumber',
        model.roomNameNumber != null && model.roomNameNumber.length > 0
          ? model.roomNameNumber[0].uniqueId
          : null,
      )
      .set('Description', model.description)
      .set('Drawing', model.drawingDocument)
      .set('SpecificationSection', model.specificationDocument)
      .set('Website', model.websiteUrl)
      .set('Tags', model.statusTags.join(','));

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Closeout/CreateCloseoutItem',
      params,
    );
  }

  editCloseoutItem(model, closeOutItemId) {
    let headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams()
      .set('CloseOutItemId', closeOutItemId)
      .set('CloseOutItemName', model.title)
      .set(
        'ParentCloseOutItemId',
        model.selectedParentItem != null && model.selectedParentItem.length > 0
          ? model.selectedParentItem[0].closeOutItemId
          : null,
      )
      .set(
        'DivisonCode',
        model.selectedCsiDivision != null &&
          model.selectedCsiDivision.length > 0
          ? model.selectedCsiDivision[0].id
          : null,
      )
      .set(
        'UniformatDivisonCode',
        model.selectedUniFormatDivision != null &&
          model.selectedUniFormatDivision.length > 0
          ? model.selectedUniFormatDivision[0].id
          : null,
      )
      .set(
        'BuildingNameOrNumber',
        model.buildingnamenumber != null && model.buildingnamenumber.length > 0
          ? model.buildingnamenumber[0].uniqueId
          : null,
      )
      .set(
        'RoomNameOrNumber',
        model.roomNameNumber != null && model.roomNameNumber.length > 0
          ? model.roomNameNumber[0].uniqueId
          : null,
      )
      .set('Description', model.description)
      .set('Drawing', model.drawingDocument)
      .set('SpecificationSection', model.specificationDocument)
      .set('Website', model.websiteUrl)
      .set('Tags', model.statusTags.join(','));
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Closeout/EditCloseoutItem',
      params,
    );
  }

  // GET CLOSEOUT DETAILS USING
  getCloseOutDetails(
    projectId: string,
    closeoutItemId: string,
  ): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.CLOSEOUT_DETAILS +
        closeoutItemId +
        '?projectId=' +
        projectId,
    );
  }

  getCloseOutDetailsByCloseOut(closeoutItemId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.CLOSEOUT_DETAILS + closeoutItemId,
    );
  }

  createContractType(contracttype: ContractType) {
    const headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams().set('name', contracttype.name);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ContractType',
      params,
    );
  }
  createServiveType(servicetype: ServiceType) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams().set('name', servicetype.name);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ServiceType',
      params,
    );
  }
  createCSReason(reason: Reason) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/x-www-form-urlencoded');
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams().set('name', reason.name);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'CostEventReason',
      params,
    );
  }

  removeProgram(programId: string) {
    return this.http.delete(environment.apiBaseUrl + 'Program/' + programId);
  }

  deactivateVirtualProgram(programId: string) {
    return this.http.delete(
      environment.apiBaseUrl + 'Program/' + programId + '/Deactivate',
    );
  }

  removeContractType(id: string) {
    return this.http.delete(environment.apiBaseUrl + 'ContractType/' + id);
  }
  removeServiceType(id: string) {
    return this.http.delete(environment.apiBaseUrl + 'ServiceType/' + id);
  }

  removeCostEventReason(id: string) {
    return this.http.delete(environment.apiBaseUrl + 'CostEventReason/' + id);
  }

  activateProgram(programId: string) {
    const params = new HttpParams().set('programID', programId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Program/' + programId + '/Activate',
      params,
    );
  }
  activateContractType(id: string) {
    const params = new HttpParams().set('id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ContractType/' + id + '/Activate',
      params,
    );
  }
  activateCostEventType(id: string) {
    const params = new HttpParams().set('costEventTypeId', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl +
        'Project/Issue/ActivateCostEventType/' +
        id +
        '/Activate',
      params,
    );
  }

  removeCostEventType(id: string) {
    return this.http.delete(
      environment.apiBaseUrl + 'Project/Issue/DeactivateCostEventType/' + id,
    );
  }
  // API for activating Vendor
  activateVendor(vendorId: string) {
    const params = new HttpParams().set('VendorId', vendorId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Vendor/' + vendorId + '/Activate',
      params,
    );
  }
  /**
   * Describe API for Activating Service Type
   */
  activateServiceType(id: string) {
    const params = new HttpParams().set('id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ServiceType/' + id + '/Activate',
      params,
    );
  }

  activateCosteventReason(id: string) {
    const params = new HttpParams().set('id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'CostEventReason/' + id + '/Activate',
      params,
    );
  }

  // API for getting selected program details
  getProgramDetails(programID) {
    return this.coreHttpService.httpGetRequest<ProgramResponse>(
      environment.apiBaseUrl + 'Program/' + programID,
    );
  }
  // Api for getting selected contratc Type details
  getContractTypeDetails(ContractTypeID) {
    return this.coreHttpService.httpGetRequest<ContractTypeListResponse>(
      environment.apiBaseUrl + 'ContractType/' + ContractTypeID,
    );
  }
  // Api for getting selected Cost Event Type details
  getCostEventTypeDetails(Id) {
    return this.http.get<CostEventTypeResponse>(
      environment.apiBaseUrl + 'Project/Issue/GetCostEventType/' + Id,
    );
  }
  /**
   * Describe API for geeting selected service type
   */
  getServiceTypeDetails(serviceTypeId) {
    return this.coreHttpService.httpGetRequest<ServiceTypeListResponse>(
      environment.apiBaseUrl + 'ServiceType/' + serviceTypeId,
    );
  }
  /**
   * Describe API for getting selected Reason
   */
  getCostEventReasonDetails(reasonForChangeId) {
    return this.coreHttpService.httpGetRequest<CostEventReasonResponse>(
      environment.apiBaseUrl + 'CostEventReason/' + reasonForChangeId,
    );
  }
  // API for updating new user
  updateUser(program: ProgramList) {
    const params = new HttpParams()
      .set('programID', program.programID)
      .set('programName', program.programName)
      .set('programNumber', program.programNumber)
      .set('programBudget', program.programBudget)
      .set('programDescription', program.programDescription)
      .set('isActive', program.isActive.toString())
      .set('isVirtual', program.isVirtual);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'program',
      params,
    );
  }

  // Api for updating contract Type
  updateContractType(contracttype: ContractTypeList) {
    const params = new HttpParams()
      .set('id', contracttype.id)
      .set('name', contracttype.name);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ContractType',
      params,
    );
  }
  /**
   * Describe Api for updating Service Type
   */
  updateServiceType(servicetype: ServiceTypeList) {
    const params = new HttpParams()
      .set('id', servicetype.id)
      .set('name', servicetype.name);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ServiceType',
      params,
    );
  }

  updateCostEventReason(reason) {
    const params = new HttpParams()
      .set('id', reason.id)
      .set('name', reason.name);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'CostEventReason',
      params,
    );
  }

  getVendorList() {
    return this.coreHttpService.httpGetRequest<VendorTypeList>(
      environment.apiBaseUrl + 'Vendor',
    );
  }

  getVendorLiteList() {
    return this.coreHttpService.httpGetRequest<VendorTypeList>(
      environment.apiBaseUrl + API_ENDPOINTS.LITE_GET_ALL_VENDORS,
    );
  }

  getOdpRequestsByVendor(vendorId: string) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ODP_REQUEST_LIST_BY_VENDOR +
        vendorId,
    );
  }

  saveVendor(vendorresult: VendorList) {
    const body = new HttpParams()
      .set('VendorNumber', vendorresult.vendorNumber)
      .set('VendorName', vendorresult.vendorName)
      .set('organizationType', vendorresult.organizationType)
      .set('tradeType', vendorresult.tradeType)
      .set('AccountingManager', vendorresult.accManageEmail)
      .set('Address_line_1', vendorresult.Addr1)
      .set('Address_line_2', vendorresult.Addr2)
      .set('VendorCity', vendorresult.vendorCity)
      .set('State', vendorresult.vendorState)
      .set('county', vendorresult.county)
      .set('VendorPostalCode', vendorresult.vendorPostalCode)
      .set('VendorContactJson', vendorresult.vendorContactJson)
      .set('Note', vendorresult.note)
      .set('country', vendorresult.country)
      .set('facebookURL', vendorresult.facebookURL)
      .set('linkedinURL', vendorresult.linkedinURL)
      .set('twitterURL', vendorresult.twitterURL)
      // .set('email', vendorresult.email)
      .set('fax', vendorresult.fax)
      .set('phoneNo', vendorresult.phoneNo)
      .set('primaryContact', vendorresult.primaryContact)
      .set('users', vendorresult.users);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Vendor',
      body,
    );
  }

  deleteVendor(vendorid) {
    return this.http.delete(environment.apiBaseUrl + 'Vendor/' + vendorid);
  }

  getVendorId(vendorid) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Vendor/' + vendorid,
    );
  }

  updateVendor(vendorresult: VendorList) {
    const body = new HttpParams()
      .set('VendorId', vendorresult.vendorId)
      .set('VendorNumber', vendorresult.vendorNumber)
      .set('VendorName', vendorresult.vendorName)
      .set('organizationType', vendorresult.organizationType)
      .set('tradeType', vendorresult.tradeType)
      .set('AccountingManager', vendorresult.accManageEmail)
      .set('Address_line_1', vendorresult.Addr1)
      .set('Address_line_2', vendorresult.Addr2)
      .set('VendorCity', vendorresult.vendorCity)
      .set('State', vendorresult.vendorState)
      .set('county', vendorresult.county)
      .set('VendorPostalCode', vendorresult.vendorPostalCode)
      .set('Note', vendorresult.note)
      .set('country', vendorresult.country)
      .set('facebookURL', vendorresult.facebookURL)
      .set('linkedinURL', vendorresult.linkedinURL)
      .set('twitterURL', vendorresult.twitterURL)
      // .set('email', vendorresult.email)
      .set('fax', vendorresult.fax)
      .set('phoneNo', vendorresult.phoneNo)
      .set('primaryContact', vendorresult.primaryContact)
      .set('users', vendorresult.users);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Vendor',
      body,
    );
  }

  getAllCmGcCode(): Observable<CmGcInfoResponce> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_CMGC_CODE,
    );
  }

  getOwnerCode() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_OWNER_CODE,
    );
  }

  getCsiDivisionCode() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CSI_DIVISION_CODE,
    );
  }

  getUniFormatDivisionCode() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_UNIFORMAT_DIVISION_CODE,
    );
  }

  getBudgetItems() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'BudgetItem',
    );
  }

  getActiveBudgetItems() {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + 'ActiveBudgetItem',
    );
  }

  getActiveBudgetItemsV2() {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + 'ActiveBudgetItemsV2',
    );
  }

  addBudgetItem(budgetItem, budgetCategoryId) {
    const body = new HttpParams()
      .set('budgetItemName', budgetItem)
      .set('budgetCategoryId', budgetCategoryId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'BudgetItem',
      body,
    );
  }

  addCmGcItem(item) {
    const body = new HttpParams().set('CMGCCodeName', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.CREATE_CM_GC_CODE,
      body,
    );
  }

  createPurchaseRequest(purchaseRequestData) {
    let body = new HttpParams()
      .set('vendorId', purchaseRequestData.vendorId)
      .set('po_Number', purchaseRequestData.po_Number)
      .set('projectId', purchaseRequestData.projectId)
      .set('salesTax', purchaseRequestData.salesTax)
      .set('status', purchaseRequestData.status)
      .set('totalAmount', purchaseRequestData.totalAmount)
      .set('purchaseRequestNo', purchaseRequestData.purchaseRequestNo)
      .set('totalODPAmount', purchaseRequestData.totalODPAmount)
      .set('freightAmount', purchaseRequestData.freightAmount)
      .set('SubContractor', purchaseRequestData.subContractor)
      .set('Contractor', purchaseRequestData.contractor)
      .set('requestDate', purchaseRequestData.requestDate);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PURCHASE_REQUEST,
      body,
    );
  }

  createPaymentRequest(paymentRequestData) {
    const body = {
      paymentRequestNo: paymentRequestData.paymentRequestNo,
      purchaseRequestId: paymentRequestData.purchaseRequestId,
      vendorId: paymentRequestData.vendorId,
      paymentRequestAmount: paymentRequestData.totalAmount,
      projectId: paymentRequestData.projectId,
    };

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PAYMENT_REQUEST,
      body,
    );
  }

  updatePaymentRequest(paymentRequestData) {
    const body = {
      paymentRequestId: paymentRequestData.paymentRequestId,
      paymentRequestNo: paymentRequestData.paymentRequestNo,
      purchaseRequestId: paymentRequestData.purchaseRequestId,
      vendorId: paymentRequestData.vendorId,
      paymentRequestAmount: paymentRequestData.paymentRequestAmount,
      invoices: paymentRequestData.invoices,
      totalAmount: paymentRequestData.totalAmount,
      createdDate: paymentRequestData.createdDate,
    };

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.UPDATE_PAYMENT_REQUEST,
      body,
    );
  }

  updatePurchaseRequest(purchaseRequestData) {
    let body = new HttpParams()
      .set('purchaseRequestId', purchaseRequestData.purchaseRequestId)
      .set('vendorId', purchaseRequestData.vendorId)
      .set('po_Number', purchaseRequestData.pO_Number)
      .set('projectId', purchaseRequestData.projectId)
      .set('salesTax', purchaseRequestData.salesTax)
      .set('status', purchaseRequestData.status)
      .set('totalAmount', purchaseRequestData.totalAmount)
      .set('purchaseRequestNo', purchaseRequestData.purchaseRequestNo)
      .set('totalODPAmount', purchaseRequestData.totalODPAmount)
      .set('freightAmount', purchaseRequestData.freightAmount)
      .set('SubContractor', purchaseRequestData.subContractor)
      .set('Contractor', purchaseRequestData.contractor)
      .set('requestDate', purchaseRequestData.requestDate);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_PURCHASE_REQUEST,
      body,
    );
  }

  addBulkPurchaseRequestItems(data) {
    const url =
      environment.apiBaseUrl + API_ENDPOINTS.ADD_BULK_PURCHASE_REQUEST;
    return this.coreHttpService.httpPostRequest(url, data);
  }

  addBulkPaymentRequestItems(data) {
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_BULK_PAYMENT_REQUEST,
      data,
    );
  }

  updateBulkPaymentRequestItems(data) {
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_BULK_PAYMENT_REQUEST_ITEMS,
      data,
    );
  }

  updateBulkPurchaseRequestItems(data) {
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_BULK_PURCHASE_REQUEST_ITEMS,
      data,
    );
  }

  addOwnerCode(item) {
    const body = new HttpParams().set('OwnerCodeName', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.CREATE_OWNER_CODE,
      body,
    );
  }

  addCsiDivisionCode(item) {
    const body = new HttpParams().set('Name', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CSI_DIVISION_CODE,
      body,
    );
  }

  addUniformatDivisionCode(item) {
    const body = new HttpParams().set('Name', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_UNIFORMAT_DIVISION_CODE,
      body,
    );
  }

  budgetLineItem(budgetitemIds) {
    const body = new HttpParams().set('budgetitemIds', budgetitemIds);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Report/GetBudgetItemsReport',
      body,
    );
  }

  editBudgetItem(budgetItem, budgetCategoryId) {
    const body = new HttpParams()
      .set('budgetItemName', budgetItem.budgetItemName)
      .set('budgetItemId', budgetItem.budgetItemId)
      .set('budgetCategoryId', budgetCategoryId);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'BudgetItem',
      body,
    );
  }

  editCmGcItem(item) {
    const body = new HttpParams()
      .set('CMGCCodeName', item.cmgcCodeName)
      .set('BudgetXCMGCCodeId', item.budgetXCMGCCodeId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_CM_GC_CODE,
      body,
    );
  }

  editOwnerCode(item) {
    const body = new HttpParams()
      .set('OwnerCodeName', item.ownerCodeName)
      .set('OwnerCodeId', item.ownerCodeId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_OWNER_CODE,
      body,
    );
  }

  editCsiDivisionCode(item) {
    const body = new HttpParams()
      .set('Name', item.cmgcCodeName)
      .set('ID', item.id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_CSI_DIVISION_CODE,
      body,
    );
  }

  editUniFormatDivisionCode(item) {
    const body = new HttpParams()
      .set('Name', item.uniFormatCodeName)
      .set('ID', item.id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_UNIFORMAT_DIVISION_CODE,
      body,
    );
  }

  deleteBudgetItem(item) {
    return this.http.delete(environment.apiBaseUrl + 'BudgetItem/' + item);
  }

  activateBudgetItem(item) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ActivateBudgetItem/' + item,
    );
  }

  deleteCloseOutItem(item) {
    const body = new HttpParams().set('projectXCloseoutItemId', item);
    return this.http.delete(
      environment.apiBaseUrl + '/Closeout/DeleteProjetTaskItem',
      { body },
    );
  }

  // API for Program Type
  getProgramTypes() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'programtype',
    );
  }

  addProgramType(item) {
    const body = new HttpParams().set('name', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProgramType',
      body,
    );
  }
  removeProgramType(id: string) {
    return this.http.delete(environment.apiBaseUrl + 'ProgramType/' + id);
  }

  activateProgramType(id: string) {
    const params = new HttpParams().set('id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProgramType/' + id + '/Activate',
      params,
    );
  }

  editProgramType(item) {
    const body = new HttpParams()
      .set('Name', item.programTypeName)
      .set('id', item.programTypeId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProgramType',
      body,
    );
  }
  // api for project type

  getProjectTypes() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectType',
    );
  }
  addProjectType(item) {
    const body = new HttpParams().set('ProjectTypeName', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectType',
      body,
    );
  }

  removeProjectType(id: string) {
    return this.http.delete(environment.apiBaseUrl + 'ProjectType/' + id);
  }

  activateProjectType(id: string) {
    const params = new HttpParams().set('id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectType/' + id + '/Activate',
      params,
    );
  }
  getProjectTypeDetails(ProjectTypeId) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectType/' + ProjectTypeId,
    );
  }

  editProjectType(item) {
    const body = new HttpParams()
      .set('ProjectTypeId', item.projectTypeId)
      .set('ProjectTypeName', item.projectTypeName);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectType',
      body,
    );
  }

  getTemplates() {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + 'BudgetTemplate',
    );
  }

  getCloseoutTemplates() {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + 'Closeout/GetAllCloseoutTemplate',
    );
  }

  getTemplateItems(templateID) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'BudgetTemplate/' + templateID + '/BudgetItem',
    );
  }

  getCloseoutTemplateItems(CloseoutTemplateId) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'Closeout/CloseoutTemplate/' +
        CloseoutTemplateId,
    );
  }

  createBudgetTemplate(BudgetIds, TemplateName) {
    const body = new HttpParams()
      .set('BudgetItem', BudgetIds)
      .set('BudgetTemplateName', TemplateName);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'BudgetTemplate',
      body,
    );
  }
  createcloseOutTemplate(closeOutIds, TemplateName) {
    const body = new HttpParams()
      .set('closeOutItem', closeOutIds)
      .set('closeOutTemplateName', TemplateName);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Closeout/CreateCloseoutTemplate',
      body,
    );
  }

  editBudgetTemplate(BudgetItem, TemplateName, TemplateId) {
    const body = new HttpParams()
      .set('BudgetItem', BudgetItem)
      .set('BudgetTemplateId', TemplateId)
      .set('BudgetTemplateName', TemplateName);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'BudgetTemplate',
      body,
    );
  }

  editCloseOutTemplate(TemplateItems, TemplateId, TemplateName) {
    const body = new HttpParams()
      .set('closeOutItem', TemplateItems)
      .set('closeOutTemplateId', TemplateId)
      .set('closeOutTemplateName', TemplateName);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Closeout/EditCloseoutTemplate',
      body,
    );
  }

  deleteTemplate(tempId) {
    // return this.http.delete(environment.apiBaseUrl + 'BudgetTemplate/' + tempId);
    return this.http.delete(
      environment.apiBaseUrl + 'BudgetTemplate/' + tempId,
    );
  }

  deactivateCloseOutTemplate(closeOutTemplateId) {
    const body = new HttpParams().set('CloseoutTemplateId', closeOutTemplateId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.DEACTIVATE_CLOSEOUT_TEMPLATE_ENDPOINT,
      body,
    );
  }

  activateTemplate(tempId) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ActivateBudgetTemplate/' + tempId,
    );
  }

  activateCloseOutTemplate(closeoutTemplateId) {
    const body = new HttpParams().set('CloseoutTemplateId', closeoutTemplateId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ACTIVATE_CLOSEOUT_TEMPLATE_ENDPOINT,
      body,
    );
  }

  addFileCategoryItem(item) {
    const body = new HttpParams().set('FileCategoryName', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'File/AddFileCategory/',
      body,
    );
  }

  editFileCategoryItem(id, item): Observable<any> {
    const body = new HttpParams()
      .set('FileCategoryId', id)
      .set('FileCategoryName', item);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'File/UpdateFileCategory/',
      body,
    );
  }

  addTaskItem(taskitemdata) {
    const body = new HttpParams()
      .set('TaskTitle', taskitemdata.TaskTitle)
      .set('AssignTo', taskitemdata.RoleName)
      .set('ItemDescription', taskitemdata.TaskDescription)
      .set('Priority', taskitemdata.Priority)
      .set('CanAssigneeSeeAllTask', taskitemdata.CanAssigneeSeeAllTask)
      .set('DaysTocompleteTask', taskitemdata.EstimatedHours)
      .set('Type', taskitemdata.Type);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/AddProjectTaskItems',
      body,
    );
  }
  editTaskItem(taskitemdata) {
    const body = new HttpParams()
      .set('ItemId', taskitemdata.TaskItemId)
      .set('TaskTitle', taskitemdata.TaskTitle)
      .set('AssignTo', taskitemdata.RoleName)
      .set('ItemDescription', taskitemdata.TaskDescription)
      .set('Priority', taskitemdata.Priority)
      .set('CanAssigneeSeeAllTask', taskitemdata.CanAssigneeSeeAllTask)
      .set('DaysTocompleteTask', taskitemdata.EstimatedHours)
      .set('Type', taskitemdata.Type);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/EditProjectTaskItems',
      body,
    );
  }
  activateTaskItem(id): Observable<any> {
    const params = new HttpParams().set('TaskId', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/ActiveProjectTaskById',
      params,
    );
  }
  deactivatetaskitem(id): Observable<any> {
    const params = new HttpParams().set('TaskId', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/InActiveProjectTaskById',
      params,
    );
  }
  getTaskItems(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetAllProjectItems',
    );
  }
  getTaskTemplates(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetAllProjectTemplates',
    );
  }

  getActiveTaskItems(type: number): Observable<Tasklist> {
    const params = new HttpParams().set('type', type);
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetAllActiveProjectItems?' + params,
    );
  }

  getTaskTemplatesActive(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetActiveProjectTemplates',
    );
  }
  getProjectTaskTemplate(ProjectId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'ProjectTask/GetProjectTaskTeplates/' +
        ProjectId,
    );
  }

  getAssociateGeneralTaskTemplate(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetGeneralTaskTeplates/',
    );
  }

  getTaskTemplateDetailsByid(ProjectTemplateId) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'ProjectTask/ProjectTemplate/' +
        ProjectTemplateId +
        '/ProjectItem',
    );
  }
  deActivateTaskTemplate(tempId) {
    return this.http.delete(
      environment.apiBaseUrl +
        'ProjectTask/InActiveProjectTemplate?ProjectTemplateId=' +
        tempId,
    );
  }
  activateTaskTemplate(tempId: string) {
    return this.http.delete(
      environment.apiBaseUrl +
        'ProjectTask/ActiveProjectTemplate?ProjectTemplateId=' +
        tempId,
    );
  }
  createtaskTemplate(ids, TemplateName, type) {
    const body = new HttpParams()
      .set('ProjectItem', ids)
      .set('ProjectTemplateName', TemplateName)
      .set('Type', type);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/CreateProjectTemplate',
      body,
    );
  }
  editTaskTemplate(BudgetIds, TemplateName, TemplateId, type) {
    const body = new HttpParams()
      .set('ProjectItem', BudgetIds)
      .set('ProjectTemplateName', TemplateName)
      .set('Type', type)
      .set('ProjectTemplateId', TemplateId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/EditProjectTemplate',
      body,
    );
  }
  getgeneralTaskByUser(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetGeneralTaskByUser',
    );
  }
  getTaskTemplateByID(ProjectId, TaskTemplateId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'ProjectTask/AddTaskTemplateToProject/' +
        ProjectId +
        '/' +
        TaskTemplateId,
    );
  }

  addTaskTemplatesToGeneralTask(TaskTemplateId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        'ProjectTask/AddTaskTemplateToGeneralTask/' +
        '/' +
        TaskTemplateId,
    );
  }

  getVirtualProgram() {
    return this.coreHttpService.httpGetRequest<VirtualProgramResponse>(
      environment.apiBaseUrl + 'Program/GetAllVirtualPrograms',
    );
  }

  getAllActiveVirtualProgram() {
    return this.coreHttpService.httpGetRequest<ActiveVirtualProgramResponse>(
      environment.apiBaseUrl + 'Program/GetAllActiveVirtualPrograms',
    );
  }
  removeProjectFromVP(programID, selectedProjects): Observable<any> {
    const body = new HttpParams()
      .set('ProgramID', programID)
      .set('projectIDs', selectedProjects);
    return this.http.post(
      environment.apiBaseUrl + 'Program/RemoveProjectsfromVP',
      body,
    );
  }
  getVirtualProgramDetail(programIds): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Program/GetVPDetails?ProgramID=' + programIds,
    );
  }

  getVirtualProjects(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Program/GetVirtualProjects',
    );
  }
  gettaskPriority() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetTaskPriority',
    );
  }

  addTaskpriority(title, colorCode) {
    const body = new HttpParams()
      .set('Title', title)
      .set('ColourCode', colorCode);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/AddTaskPriority',
      body,
    );
  }

  editTaskPriority(id, title, colorCode) {
    const body = new HttpParams()
      .set('Id', id)
      .set('Title', title)
      .set('ColourCode', colorCode);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/EditTaskPriority',
      body,
    );
  }

  deactivatetaskpriority(id): Observable<any> {
    const params = new HttpParams().set('Id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/' + id + '/Deactivate',
      params,
    );
  }

  gettaskContext() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'ProjectTask/GetTaskContext',
    );
  }

  addTaskContext(Title) {
    const body = new HttpParams().set('ContextTitle', Title);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/AddTaskContext',
      body,
    );
  }

  editTaskContext(id, Title) {
    const body = new HttpParams().set('Id', id).set('ContextTitle', Title);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/EditTaskContext',
      body,
    );
  }

  deactivatetaskContext(id): Observable<any> {
    const params = new HttpParams().set('Id', id);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'ProjectTask/DeactivateContext/' + id,
      params,
    );
  }

  activatePriority(id): Observable<any> {
    const params = new HttpParams().set('Id', id);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/ActivatePriority',
      params,
    );
  }

  activateContext(id): Observable<any> {
    const params = new HttpParams().set('Id', id);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'ProjectTask/ActivateContext',
      params,
    );
  }

  getCloseoutItems() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'Closeout/GetCloseoutItems',
    );
  }

  getBudgetCategories(): Observable<BudgetCategoryResponse> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + 'BudgetCategories',
    );
  }

  addBudgetCategory(budgetCategory) {
    const body = new HttpParams().set('BudgetCategoryName', budgetCategory);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + ROUTE_CONSTANT.ADD_BUDGET_CATEGORY,
      body,
    );
  }

  editBudgetCategory(budgetCategory) {
    const body = new HttpParams()
      .set('budgetCategoryName', budgetCategory.budgetCategoryName)
      .set('budgetCategoryId', budgetCategory.budgetCategoryId);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + ROUTE_CONSTANT.UPDATE_BUDGET_CATEGORY,
      body,
    );
  }

  changesBudgetCategoryStatus(budgetCategoryId, isActive) {
    const body = new HttpParams()
      .set('budgetCategoryId', budgetCategoryId)
      .set('isActive', isActive);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + ROUTE_CONSTANT.UPDATE_BUDGET_CATEGORY,
      body,
    );
  }

  changesCmGcStatus(budgetXCMGCCodeId, isActive) {
    const body = new HttpParams()
      .set('budgetXCMGCCodeId', budgetXCMGCCodeId)
      .set('isActive', isActive);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ACTIVE_DEACTIVE_CM_GC_CODE,
      body,
    );
  }

  changesOwnerStatus(ownerCodeId, isActive) {
    const body = new HttpParams()
      .set('budgetXOwnerCodeId', ownerCodeId)
      .set('isActive', isActive);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ACTIVE_DEACTIVE_OWNER_CODE,
      body,
    );
  }

  changesCsiDivisionStatus(id, isActive) {
    const body = new HttpParams().set('ID', id).set('Active', isActive);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ACTIVE_DEACTIVE_CSI_DIVISION_CODE,
      body,
    );
  }

  changesUniFormatDivisionStatus(id, isActive) {
    const body = new HttpParams().set('ID', id).set('Active', isActive);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ACTIVE_DEACTIVE_UNIFORMAT_DIVISION_CODE,
      body,
    );
  }

  getPayApplicationCategory(): Observable<PayApplicationCategoryResponse> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_PAY_APPLICATION_CATEGORY,
    );
  }

  getPayApplicationTemplateXBudgetItems(templateId: string) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ALL_PAY_APPLICATION_TEMPLATE_WISE_BUDGETITEMS_DETAILS +
        '/' +
        templateId,
    );
  }

  getPayApplicationTemplateList(): Observable<PayApplicationTemplateResponse> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_PAY_APPLICATION_TEMPLATES,
    );
  }

  addPayApplicationCategory(payApplicationCategoryName) {
    const body = new HttpParams().set(
      'categoryName',
      payApplicationCategoryName,
    );

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PAY_APPLICATION_CATEGORY,
      body,
    );
  }

  editPayApplicationCategory(payApplicationCategory) {
    const body = new HttpParams()
      .set('categoryName', payApplicationCategory.categoryName)
      .set('categoryId', payApplicationCategory.categoryId);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_PAY_APPLICATION_CATEGORY,
      body,
    );
  }

  changePayApplicationCategory(payApplicationCategoryId, isActive) {
    const body = new HttpParams()
      .set('categoryId', payApplicationCategoryId)
      .set('isActive', isActive);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_PAY_APPLICATION_CATEGORY,
      body,
    );
  }

  changePayApplicationTemplateCategory(id, isActive) {
    const body = new HttpParams()
      .set('paymentApplicationTemplateId', id)
      .set('isActive', isActive);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.UPDATE_PAY_APPLICATION_CATEGORY,
      body,
    );
  }

  getCCRDetails(ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_CONTINGENCY_CHANGE_REQUEST_DETAIl_BY_ID +
        '?contingencyChangeRequestId=' +
        ccrId,
    );
  }

  private appendCommonParams(
    url: string,
    params: {
      pageNumber: number;
      searchText: string;
      pageSize: number;
      sortBy: string;
      sortOrder: number;
      filter: number;
      isActive?: boolean;
      Id?: string;
    },
  ): string {
    if (params.pageNumber >= 0) {
      url += 'PageNumber=' + params.pageNumber;
    }
    if (params.searchText) {
      url += '&SearchText=' + params.searchText;
    }
    if (params.pageSize >= 0) {
      url += '&PageSize=' + params.pageSize;
    }
    if (params.sortBy) {
      url += '&SortBy=' + params.sortBy;
    }
    if (params.sortOrder >= 0) {
      url += '&SortOrder=' + params.sortOrder;
    }
    if (params.filter >= 0 && params.filter != null) {
      url += '&Filter=' + params.filter;
    }
    if (params.isActive != null) {
      url += '&IsActive=' + params.isActive;
    }
    if (params.Id != null) {
      url += '&Id=' + params.Id;
    }

    return url;
  }

  getAllTradeTypes(
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
    isActive?: boolean,
  ) {
    let url = environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_TRADE_TYPES;
    url = this.appendCommonParams(url, {
      pageNumber,
      searchText,
      pageSize,
      sortBy,
      sortOrder,
      filter,
      isActive,
    });

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  getAllPuchaseRequests(
    Id: string,
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
  ) {
    let url = environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_PURCHASE_REQUESTS;
    url = this.appendCommonParams(url, {
      pageNumber,
      searchText,
      pageSize,
      sortBy,
      sortOrder,
      filter,
      Id,
    });

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  getAllPaymentRequests(
    Id: string,
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
  ) {
    let url = environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_PAYMENT_REQUESTS;
    url = this.appendCommonParams(url, {
      pageNumber,
      searchText,
      pageSize,
      sortBy,
      sortOrder,
      filter,
      Id,
    });

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  addTradeType(tradeTypeName) {
    const body = new HttpParams().set('TradeTypeName', tradeTypeName);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_TRADE_TYPE,
      body,
    );
  }

  editTradeType(tradeType) {
    const body = new HttpParams()
      .set('TradeTypeName', tradeType.tradeTypeName)
      .set('TradeTypeId', tradeType.tradeTypeId);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIt_TRADE_TYPE,
      body,
    );
  }

  activateDeactivateTradeType(tradeTypeId) {
    const body = new HttpParams().set('tradeTypeId', tradeTypeId);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ACTIVATE_DEACTIVATE_TRADE_TYPE,
      body,
    );
  }

  getAllOrganizationTypes(
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
    isActive?: boolean,
  ) {
    let url = environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_ORGANIZATION_TYPES;
    url = this.appendCommonParams(url, {
      pageNumber,
      searchText,
      pageSize,
      sortBy,
      sortOrder,
      filter,
      isActive,
    });

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  addOrganizationType(organizationTypeName) {
    const body = new HttpParams().set(
      'OrganizationTypeName',
      organizationTypeName,
    );

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_ORGANIZATION_TYPE,
      body,
    );
  }

  editOrganizationType(organizationType) {
    const body = new HttpParams()
      .set('OrganizationTypeName', organizationType.organizationTypeName)
      .set('OrganizationTypeId', organizationType.organizationTypeId);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIt_ORGANIZATION_TYPE,
      body,
    );
  }

  activateDeactivateOrganizationType(organizationTypeId) {
    const body = new HttpParams().set('organizationTypeId', organizationTypeId);

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ACTIVATE_DEACTIVATE_ORGANIZATION_TYPE,
      body,
    );
  }

  getOrganizationDetailPDF(organizationTypeId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GENRATE_ORGANIZATION_DETAIL_PDF +
        '/' +
        organizationTypeId,
    );
  }
}
