import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CoreHttpService } from '../../../core/core-http.service';
import {
  DocumentList,
  PayApplicationCoverCalculationResponse,
} from '../projectModel';
import { API_ENDPOINTS } from '../Task-management/constants';
import { PayApplicationListResponse } from './document.model';
import {
  PayApplicationAttachmentSheetListResponse,
  PayApplicationXattachmentResponse,
} from '../../../../app/pages/master/master.model';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class DocumentService {
  @Output()
  currentWarrantyClaimStatus = new EventEmitter();

  constructor(
    private http: HttpClient,
    private coreHttpService: CoreHttpService,
  ) {}

  // GET DUCUMENT LIST(USING TYPE)
  getDucumentList(projectID: string, type?): Observable<any> {
    if (type) {
      return this.coreHttpService.httpGetRequest(
        environment.apiBaseUrl +
          'File/GetDocumentlistByProjID/' +
          projectID +
          '?type=' +
          type,
      );
    } else {
      return this.coreHttpService.httpGetRequest(
        environment.apiBaseUrl + 'File/GetDocumentlistByProjID/' + projectID,
      );
    }
  }

  // GET DOCUMENT DETAILS USING(DocumentlistItemID)
  getDocumentdetails(DocumentListItemID: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'File/GetDocumentlistByID/' + DocumentListItemID,
    );
  }

  // ADD/CREATE DOCUMENT
  addSubmittalDocument(
    description,
    CSI_Division_code,
    Uniformat_Division_code,
    Drawings,
    Specification_Section,
    CostImpact,
    ScheduleImpact,
    Type,
    receivedFrom,
    responsibleContractor,
    ProjectID,
    isCostImpact,
    isScheduleImpact,
    StatusTags
  ): Observable<any> {
    const body = new HttpParams()
      .set('description', description)
      .set('CSI_Division_code', CSI_Division_code)
      .set('Uniformat_Division_code', Uniformat_Division_code)
      .set('Drawings', Drawings)
      .set('Specification_Section', Specification_Section)
      .set('CostImpact', CostImpact)
      .set('ScheduleImpact', ScheduleImpact)
      .set('Type', Type)
      .set('ReceivedFrom', receivedFrom)
      .set('ResponsibleContractor', responsibleContractor)
      // .set('Ball_in_Court', Ball_in_Court)
      .set('ProjectID', ProjectID)
      .set('IsCostImpact', isCostImpact)
      .set('IsScheduleImpact', isScheduleImpact)
      .set('Tags',StatusTags)
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'File/AddDocuments/',
      body,
    );
  }

  // EDIT/UPDATE DOCUMENT
  updateDocmunet(document: DocumentList) {
    const params = new HttpParams()
      .set('ProjectID', document.projectID)
      .set('Type', document.type)
      .set('DocumentID', document.documentId)
      .set('description', document.description)
      .set('CSI_Division_code', document.csi_division)
      .set('Uniformat_Division_code', document.uniFormat_division)
      .set('Drawings', document.drawingDocument)
      .set('Specification_Section', document.specificationDocument)
      .set('CostImpact', document.costImpact)
      .set('ScheduleImpact', document.scheduleImpact)
      .set('ReceivedFrom', document.receivedFrom)
      .set('ResponsibleContractor', document.responsibleContractor)
      .set('Ball_in_Court', document.StatusTags)
      .set('IsCostImpact', document.isCostImpact)
      .set('IsScheduleImpact', document.isScheduleImpact)
      .set('Isactive', document.isactive)
      .set('Tags',document.StatusTags);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'File/EditDocuments',
      params,
    );
  }

  // ACTIVE DOCUMENT LIST
  isActiveDocument(DocumentListItemID: string) {
    const params = new HttpParams().set(
      'DocumentListItemID',
      DocumentListItemID,
    );
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'File/IsActiveDocument',
      params,
    );
  }

  // DEACTIVE DOCUMENT LIST
  deActiveDocument(DocumentListItemID: string) {
    const params = new HttpParams().set(
      'DocumentListItemID',
      DocumentListItemID,
    );
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'File/InActiveDocument',
      params,
    );
  }

  // UPLOAD DOCUMENT-FILE
  Uploadfiles(DocumentId, formData: FormData): Observable<any> {
    let headers = new HttpHeaders();
    // this is the important step. You need to set content type as null
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    let params = new HttpParams();

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'File/AddDocument/' + DocumentId + '/AddFiles',
      formData,
      { params, headers },
    );
  }

  // ADD FILES FOR PUNCHLIST ITEM
  addDocumentFiles(DocumentId, formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequestWithParamsWithoutLoader(
      environment.apiBaseUrl + 'File/AddDocument/' + DocumentId + '/AddFiles',
      formData,
      { params, headers },
    );
  }

  // ADD COMMENT FILES
  // addCommentFiles(punchListItemId, commentId , formData) {
  //   const headers = new HttpHeaders();
  //   headers.set('Content-Type', null);
  //   headers.set('Accept', 'multipart/form-data');
  //   const params = new HttpParams();
  //   return this.coreHttpService.httpPostRequest(environment.apiBaseUrl + 'PunchList/AddPunchList/' + punchListItemId + '/' +  commentId + '/AddCommentFiles',
  //     formData,
  //     { params, headers });
  // }
  // ADD COMMENT
  addPunchListComment(documentId: string, Comment: string): Observable<any> {
    const body = new HttpParams()
      .set('DocumentID', documentId)
      .set('Comment', Comment);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'File/Document/AddDocumentComment',
      body,
    );
  }

  // GET DOCUMENT FILES
  addFiles(DocumentId: string) {
    const body = new HttpParams().set('DocumentID', DocumentId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'File/AddDocument/' + DocumentId + '/AddFiles',
      body,
    );
  }

  // ACTIVE DOCUMENT-FILE
  activeFiles(FileID: string) {
    const params = new HttpParams().set('FileID', FileID);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'File/ActiveFiles',
      params,
    );
  }

  // DEACTIVE DOCUMENT LIST
  deActiveFile(FileID: string) {
    const params = new HttpParams().set('FileID', FileID);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'File/InActiveFiles',
      params,
    );
  }

  // GET CSI DIVISION DATA
  getCSIdivision(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'File/GetCSI_Division_Code',
    );
  }

  // GET UNIFORMATE DIVISION CSI DATA
  getUniFormateDivision(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + 'File/GetUniformat_Division_Code',
    );
  }

  // DOCUMENT APPROVED API
  isDocumentApproved(DocumentListItemID: string, Status: any) {
    const params = new HttpParams()
      .set('DocumentListItemID', DocumentListItemID)
      .set('Status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'File/DocumentApproved',
      params,
    );
  }

  // CLOSEOUT DOCUMENT APPROVED API
  isCloseoutDocumentApproved(projectXCloseOutItemID: string, Status: any) {
    const params = new HttpParams()
      .set('ProjectCloseOutItemId', projectXCloseOutItemID)
      .set('Status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Closeout/CloseOutApprove',
      params,
    );
  }

  isCloseoutDocumentRejected(projectXCloseOutItemID: string, Status: any) {
    const params = new HttpParams()
      .set('ProjectCloseOutItemId', projectXCloseOutItemID)
      .set('Status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + 'Closeout/CloseOutReject',
      params,
    );
  }

  // DOWNLOAD DOCUMENT FILE
  public async downloadResource(FileID: string): Promise<Blob> {
    const file = await this.http
      .get<Blob>(environment.apiBaseUrl + 'File/Document/GetFile/' + FileID, {
        responseType: 'blob' as 'json',
      })
      .toPromise();
    return file;
  }

  getCloseoutItems(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CLOSEOUT_ITEMS,
    );
  }

  getCloseoutTemplates(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ACTIVE_CLOSEOUT_TEMPLATES,
    );
  }

  getCloseoutItemsByProjectId(ProjectId): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_CLOSEOUT_ITEMS_BY_PROJECTID +
        ProjectId,
    );
  }

  getCloseoutTemplateByID(projectId, closeoutTemplateId): Observable<any> {
    const params = new HttpParams().set(
      'CloseoutTemplateId',
      closeoutTemplateId,
    );
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ADD_CLOSEOUT_TO_PROJECT +
        projectId +
        '?' +
        params,
    );
  }

  getCloseoutItemByID(ProjectId, closeOutItemId): Observable<any> {
    const params = new HttpParams().set('closeOutItemId', closeOutItemId);
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ADD_CLOSEOUT_TO_PROJECT +
        ProjectId +
        '?' +
        params,
    );
  }

  getCloseoutItemCommentsWithFiles(projectId, closeoutItemId): Observable<any> {
    const params = new HttpParams()
      .set('projectId', projectId)
      .set('closeOutItemId', closeoutItemId);
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CLOSEOUT_COMMENTS + params,
    );
  }

  addCloseoutItemComment(
    projectId: string,
    closeOutItemId: string,
    comment: string,
  ): Observable<any> {
    const body = new HttpParams()
      .set('ProjectId', projectId)
      .set('CloseoutItemId', closeOutItemId)
      .set('Comment', comment);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CLOSEOUT_COMMENTS,
      body,
    );
  }

  addCloseoutFiles(formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CLOSEOUT_FILES,
      formData,
      { params, headers },
    );
  }

  getCloseoutItemFiles(closeoutItemId): Observable<any> {
    const params = new HttpParams().set('closeOutItemId', closeoutItemId);
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CLOSEOUT_ITEM_FILES + params,
    );
  }

  downloadCloseoutResource(
    fileId: string,
    closeoutItemId: string,
    projectId: string,
  ): Observable<Blob> {
    const url = `${environment.apiBaseUrl}Closeout/GetFile/${fileId}?projectId=${projectId}&closeoutItemId=${closeoutItemId}`;
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  getPayApplicationCategory(
    projectId: string,
  ): Observable<PayApplicationListResponse> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_DOCUMENT_PAY_APPLICATION +
        '/' +
        projectId,
    );
  }

  createDocumentPayApplicationCategory(rowData, projectId, certificate) {
    const params = new HttpParams()
      .set('ApplicationNumber', rowData.paymentApplicationNo)
      .set('ProjectId', projectId)
      .set('PeriodFrom', rowData.periodFrom)
      .set('PeriodTo', rowData.periodTo)
      .set('Certificate', certificate)
      .set('Retainage', rowData.retainage)
      .set('ChangeOrderSummary', rowData.includeChangeOrderSummary)
      .set('ApplicationDate', rowData.applicationDate);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PAY_APPLICATION,
      params,
    );
  }

  editDocumentPayApplicationCategoryWithoutLoader(
    projectId,
    rowData,
    Id,
    certificate,
  ) {
    const params = new HttpParams()
      .set('Id', Id)
      .set('ApplicationNumber', rowData.paymentApplicationNo)
      .set('PeriodFrom', rowData.periodFrom)
      .set('PeriodTo', rowData.periodTo)
      .set('Certificate', certificate)
      .set('Retainage', rowData.retainage)
      .set('ProjectId', projectId)
      .set('ChangeOrderSummary', rowData.includeChangeOrderSummary)
      .set('ApplicationDate', rowData.applicationDate)
      .set('OptionalUserDefined', rowData.optionalUserDefined)
      .set(
        'TotalChange_Approve_PreMonth_Addiotions',
        rowData.totalChangeApprovePreMonthAddiotions,
      )
      .set(
        'TotalChange_Approve_PreMonth_Deductions',
        rowData.totalChangeApprovePreMonthDeductions,
      )
      .set(
        'TotalChange_Approve_CurrentMonth_Addiotions',
        rowData.totalChangeApproveCurrentMonthAddiotions,
      )
      .set(
        'TotalChange_Approve_CurrentMonth_Deductions',
        rowData.totalChangeApproveCurrentMonthDeductions,
      );

    return this.http.put(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_PAY_APPLICATION,
      params,
    );
  }

  editDocumentPayApplicationCategory(projectId, rowData, Id, certificate) {
    const params = new HttpParams()
      .set('Id', Id)
      .set('ApplicationNumber', rowData.paymentApplicationNo)
      .set('PeriodFrom', rowData.periodFrom)
      .set('PeriodTo', rowData.periodTo)
      .set('Certificate', certificate)
      .set('Retainage', rowData.retainage)
      .set('ProjectId', projectId)
      .set('ChangeOrderSummary', rowData.includeChangeOrderSummary)
      .set('ApplicationDate', rowData.applicationDate)
      .set('OptionalUserDefined', rowData.optionalUserDefined)
      .set(
        'TotalChange_Approve_PreMonth_Addiotions',
        rowData.totalChangeApprovePreMonthAddiotions,
      )
      .set(
        'TotalChange_Approve_PreMonth_Deductions',
        rowData.totalChangeApprovePreMonthDeductions,
      )
      .set(
        'TotalChange_Approve_CurrentMonth_Addiotions',
        rowData.totalChangeApproveCurrentMonthAddiotions,
      )
      .set(
        'TotalChange_Approve_CurrentMonth_Deductions',
        rowData.totalChangeApproveCurrentMonthDeductions,
      );

    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.EDIT_PAY_APPLICATION,
      params,
    );
  }

  addPayApplicationComment(
    projectId: string,
    payApplicationId: string,
    comment: string,
  ): Observable<any> {
    const body = new HttpParams()
      .set('ProjectId', projectId)
      .set('PayApplicationId', payApplicationId)
      .set('Comment', comment);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PAY_APP_COMMENTS,
      body,
    );
  }

  addPayApplicationFiles(formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PAY_APP_FILES,
      formData,
      { params, headers },
    );
  }

  getPayApplicationCommentsWithFiles(
    projectId: string,
    payApplicationId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('projectId', projectId)
      .set('payApplicationId', payApplicationId);
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_PAY_APP_COMMENTS + params,
    );
  }

  downloadPayApplication(
    fileId: string,
    payApplicationId: string,
    projectId: string,
  ): Observable<Blob> {
    const url = `${environment.apiBaseUrl}${API_ENDPOINTS.DOWNLOAD_PAY_APPLICATION}${fileId}?projectId=${projectId}&payApplicationId=${payApplicationId}`;
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  approvePayApplication(payApplicationId: string, Status: any) {
    const params = new HttpParams()
      .set('projectPayApplicationId', payApplicationId)
      .set('Status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.APPROVE_PAY_APPLICATION,
      params,
    );
  }

  rejectPayApplication(payApplicationId: string, Status: any) {
    const params = new HttpParams()
      .set('projectPayApplicationId', payApplicationId)
      .set('Status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.REJECT_PAY_APPLICATION,
      params,
    );
  }

  getPayApplicationStatusHistory(payApplicationId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_STATUS_HISTORY +
        payApplicationId,
    );
  }

  createEditPayApplicationAttachmentSheet(
    rowData: string,
    categoryIds: string,
    payApplicationId: string,
    templateId: string,
  ) {
    const params = new HttpParams()
      .set('payApplicationAttachmentSheet', rowData)
      .set('categoryIds', categoryIds)
      .set('payApplicationId', payApplicationId)
      .set('payApplicationId', payApplicationId)
      .set('templateId', templateId);

    return this.http.post(
      environment.apiBaseUrl +
        API_ENDPOINTS.ADD_PAY_APPLICATION_ATTACHMENT_SHEET,
      params,
    );
  }

  addOrUpdatePaymentApplicationTemplate(
    rowData: string,
    categoryrowSumList,
    templateId: string,
  ) {
    const params = new HttpParams()
      .set('PayApplicationTemplateName', rowData)
      .set('PayApplicationItem', JSON.stringify(categoryrowSumList))
      .set('PayApplicationTemplateId', templateId);

    return this.http.post(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_PAY_APPLICATION_TEMPLATE,
      params,
    );
  }

  createEditPayApplicationChangeOrderList(
    payApplicationId: string,
    rowData: string,
  ) {
    const params = new HttpParams()
      .set('PayApplicationId', payApplicationId)
      .set('PayApplicationChangeOrderList', rowData);
    return this.http.post(
      environment.apiBaseUrl +
        API_ENDPOINTS.ADD_PAY_APPLICATION_CHANGE_ORDER_LIST_SHEET,
      params,
    );
  }

  getPayApplicationXattachmentSheet(
    payApplicationId: string,
  ): Observable<PayApplicationXattachmentResponse> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAY_APPLICATION_X_ATTACHMENT_SHEET +
        '/' +
        payApplicationId,
    );
  }

  getFinanceManagers(): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_FINANCE_MANAGERS,
    );
  }

  sendMailToFinanceManagers(
    emailIds: string,
    ccEmailIds: string,
    payApplicationId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('payApplicationId', payApplicationId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_MAIL_TO_FINANCE_MANAGERS,
      params,
    );
  }

  sendCloseOutMail(
    emailIds: string,
    ccEmailIds: string,
    projectId: string,
    closeOutItemId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('projectId', projectId)
      .set('closeOutItemId', closeOutItemId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_CLOSE_OUT_MAIL,
      params,
    );
  }

  sendOrganizationAttachmentEmail(
    emailIds: string,
    ccEmailIds: string,
    organizationId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('organizationId', organizationId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_ORGANIZATION_ATTACHMENT_MAIL,
      params,
    );
  }

  sendOdpPurchaseEmail(
    emailIds: string,
    ccEmailIds: string,
    purchaseId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('purchaseRequestId', purchaseId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ODP_PURCHASE_EMAIL_ATTACHMENT,
      params,
    );
  }

  sendOdpPaymentEmail(
    emailIds: string,
    ccEmailIds: string,
    paymentRequestId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('paymentRequestId', paymentRequestId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ODP_PAYMENT_EMAIL_ATTACHMENT,
      params,
    );
  }

  getAllPayApplicationAttachmentSheet(
    payApplicationId: string,
  ): Observable<PayApplicationAttachmentSheetListResponse> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ALL_PAY_APPLICATION_ATTACHMENT_SHEET +
        '/' +
        payApplicationId,
    );
  }

  getPayApplicationDetails(
    payApplicationId: string,
    projectId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('payApplicationId', payApplicationId)
      .set('projectId', projectId);

    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_DETAILS +
        params,
    );
  }

  getPayApplicationChangeOrderList(payApplicationId: string): Observable<any> {
    const params = new HttpParams().set('payApplicationId', payApplicationId);
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_CHANGE_ORDER_DETAILS +
        params,
    );
  }

  getLiteProjectDetails(projectId: string): Observable<any> {
    const params = new HttpParams().set('projectId', projectId);
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_PROJECT_DETAILS + params,
    );
  }

  getODPReserveReportDetails(
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
  ) {
    let url = environment.apiBaseUrl + API_ENDPOINTS.GET_ODP_RESERVE_SUMMARY;
    url = this.appendCommonParams(url, {
      pageNumber,
      searchText,
      pageSize,
      sortBy,
      sortOrder,
      filter,
    });

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  private appendCommonParams(
    url: string,
    params: {
      pageNumber: number;
      searchText: string;
      pageSize: number;
      sortBy: string;
      sortOrder: number;
      filter: number;
      isActive?: boolean;
      Id?: string;
    },
  ): string {
    if (params.pageNumber >= 0) {
      url += 'PageNumber=' + params.pageNumber;
    }
    if (params.searchText) {
      url += '&SearchText=' + params.searchText;
    }
    if (params.pageSize >= 0) {
      url += '&PageSize=' + params.pageSize;
    }
    if (params.sortBy) {
      url += '&SortBy=' + params.sortBy;
    }
    if (params.sortOrder >= 0) {
      url += '&SortOrder=' + params.sortOrder;
    }
    if (params.filter >= 0 && params.filter != null) {
      url += '&Filter=' + params.filter;
    }
    if (params.isActive != null) {
      url += '&IsActive=' + params.isActive;
    }
    if (params.Id != null) {
      url += '&Id=' + params.Id;
    }

    return url;
  }

  getLitePayApplicationDetails(payApplicationId: string): Observable<any> {
    const params = new HttpParams().set('payApplicationId', payApplicationId);

    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_LIGHT_PAYAPPLICATION_DETAILS +
        params,
    );
  }

  getPayApplicationDetailsWithoutLoader(
    payApplicationId: string,
    projectId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('payApplicationId', payApplicationId)
      .set('projectId', projectId);

    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_DETAILS +
        params,
    );
  }

  getPayApplicationCoverCalculationById(
    payApplicationId: string,
  ): Observable<PayApplicationCoverCalculationResponse> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_CALCULATION_DETAILS +
        '/' +
        payApplicationId,
    );
  }

  getPayApplicationCoverWorksheetPDF(
    payApplicationId: string,
    isWorksheet: boolean,
  ): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_PDF_ATTACHMENT +
        '/' +
        payApplicationId +
        '/' +
        isWorksheet,
    );
  }

  getOdpPurchaseRequestPDF(purchaseRequestId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ODP_PURCHASE_PDF_ATTACHMENT +
        purchaseRequestId,
    );
  }

  getCloseOutPDF(projectId: string, closeOutItemId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GENERATE_CLOSE_OUT_PDF +
        '/' +
        projectId +
        '/' +
        closeOutItemId,
    );
  }

  getPayApplicationChangeOrderListPDF(
    payApplicationId: string,
    isChangeOrderList: boolean,
  ): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAYAPPLICATION_CHANGE_ORDER_PDF_ATTACHMENT +
        '/' +
        payApplicationId +
        '/' +
        isChangeOrderList,
    );
  }

  printPDF(fileURL: string) {
    this.http.get(fileURL, { responseType: 'blob' }).subscribe((blob) => {
      // Create a Blob URL for the PDF
      const file = new Blob([blob], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // Open the PDF in a new window
      const pdfWindow = window.open(fileURL);

      // Once the PDF is opened, trigger the print dialog
      pdfWindow.onload = function () {
        // Set the print stylesheet for landscape orientation
        const style = pdfWindow.document.createElement('style');
        style.innerHTML = '@page { size: landscape; }';
        pdfWindow.document.head.appendChild(style);

        // Trigger the print dialog
        pdfWindow.print();
      };
    });
  }

  sendPayApplicationForApproval(payApplicationId: string, projectId: string) {
    const params = new HttpParams()
      .set('payApplicationId', payApplicationId)
      .set('projectId', projectId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_PAY_APPLICATION_FOR_APPROVAl,
      params,
    );
  }

  getAllPayApplicationTemplate() {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_ALL_PAY_APPLICATION_TEMPLATE,
    );
  }

  getPayApplicationTemplateXBudgetItems(templateId: string, projectId: string) {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_PAY_APPLICATION_TEMPLATE_X_BUDGETITEMS +
        '/' +
        templateId +
        '?' +
        'projectId=' +
        projectId,
    );
  }

  getAllContingencyChangeRequests(projectId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ALL_CONTINGENCY_CHANGE_REQUEST +
        projectId,
    );
  }

  addCCR(rowData, projectId, budgetItemId) {
    const params = new HttpParams()
      .set('ProjectId', projectId)
      .set('Description', rowData.description)
      .set('CostOfDescribedWork', rowData.costOfDescribedWork)
      .set('TimeExtension', rowData.timeExtension)
      .set('AECertificate', rowData.includeAECertificate)
      .set('AvailableContingencyFund', rowData.availableContingencyFund)
      .set('FundRequestedFromContingency', rowData.fundRequestedFromContingency)
      .set('AvailableBuyoutFund', rowData.availableBuyoutFund)
      .set('FundRequestedFromBuyout', rowData.fundRequestedFromBuyout)
      .set('BudgetItemId', budgetItemId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ADD_UPDATE_CONTINGENCY_CHANGE_REQUEST,
      params,
    );
  }

  updateCCR(rowData, projectId, ccrId, budgetItemId) {
    const params = new HttpParams()
      .set('CCRId', ccrId)
      .set('ProjectId', projectId)
      .set('Description', rowData.description)
      .set('CostOfDescribedWork', rowData.costOfDescribedWork)
      .set('TimeExtension', rowData.timeExtension)
      .set('AECertificate', rowData.includeAECertificate)
      .set('AvailableContingencyFund', rowData.availableContingencyFund)
      .set('FundRequestedFromContingency', rowData.fundRequestedFromContingency)
      .set('AvailableBuyoutFund', rowData.availableBuyoutFund)
      .set('FundRequestedFromBuyout', rowData.fundRequestedFromBuyout)
      .set('BudgetItemId', budgetItemId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.ADD_UPDATE_CONTINGENCY_CHANGE_REQUEST,
      params,
    );
  }

  getCCRCommentsWithFiles(projectId, ccrId): Observable<any> {
    const params = new HttpParams()
      .set('projectId', projectId)
      .set('ccrId', ccrId);
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_CCR_COMMENTS_WITH_FILES +
        params,
    );
  }

  getWarrantyClaimHistory(ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_WARRANTY_CLAIM_HISTORY + ccrId,
    );
  }

  getAssetWarrantyHistory(ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ASSETS_WARRANTY_HISTORY +
        ccrId,
    );
  }

  getClaimFile(ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CLAIM_FILE + ccrId,
    );
  }

  getClaimCommentsWithFile(ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_CLAIM_COMMENTS_WITH_FILE +
        ccrId,
    );
  }

  addClaimComments(claimId: string, comment: string): Observable<any> {
    const body = new HttpParams()
      .set('ClaimId', claimId)
      .set('Comment', comment);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CLAIM_COMMENT,
      body,
    );
  }

  addClaimFiles(formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CLAIM_FILES,
      formData,
      { params, headers },
    );
  }

  addCCRComment(
    projectId: string,
    ccrId: string,
    comment: string,
  ): Observable<any> {
    const body = new HttpParams()
      .set('ProjectId', projectId)
      .set('CCRId', ccrId)
      .set('Comment', comment);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CCR_COMMENTS,
      body,
    );
  }

  addCCRFiles(formData) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', null);
    headers.set('Accept', 'multipart/form-data');
    const params = new HttpParams();
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_CCR_FILES,
      formData,
      { params, headers },
    );
  }

  getCCRStatusHistory(ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CCR_STATUS_HISTORY + ccrId,
    );
  }

  approveCCR(ccrId: string, Status: any) {
    const params = new HttpParams().set('ccrId', ccrId).set('status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.APPROVE_CCR,
      params,
    );
  }

  rejectCCR(ccrId: string, Status: any) {
    const params = new HttpParams().set('ccrId', ccrId).set('status', Status);
    return this.coreHttpService.httpPutRequest(
      environment.apiBaseUrl + API_ENDPOINTS.REJECT_CCR,
      params,
    );
  }

  downloadCCR(
    fileId: string,
    ccrId: string,
    projectId: string,
  ): Observable<Blob> {
    const url = `${environment.apiBaseUrl}GetCCRFile/${fileId}?ccrId=${ccrId}&projectId=${projectId}`;
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  downloadWarrantyDocument(
    fileId: string,
    claimId: string,
    projectId: string,
  ): Observable<Blob> {
    const url = `${environment.apiBaseUrl}Asset/GetClaimFile/${fileId}?claimId=${claimId}&projectId=${projectId}`;
    return this.http.get<Blob>(url, {
      responseType: 'blob' as 'json',
    });
  }

  getCCRPDF(projectId: string, ccrId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GENERATE_CCR_PDF +
        '/' +
        projectId +
        '/' +
        ccrId,
    );
  }

  sendCCRMail(
    emailIds: string,
    ccEmailIds: string,
    projectId: string,
    ccrId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('projectId', projectId)
      .set('ccrId', ccrId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_CCR_MAIL,
      params,
    );
  }

  sendClaimAttachmentEmail(
    emailIds: string,
    ccEmailIds: string,
    claimId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('claimId', claimId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_WARRANTY_HISTORY_MAIL,
      params,
    );
  }

  sendProjectAssetAttachmentEmail(
    emailIds: string,
    ccEmailIds: string,
    warrantyId: string,
  ): Observable<any> {
    const params = new HttpParams()
      .set('emailIds', emailIds)
      .set('ccEmailIds', ccEmailIds)
      .set('warrantyId', warrantyId);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.SEND_PROJECT_ASSET_ATTACHMENT_MAIL,
      params,
    );
  }

  getAvailableAssets(): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_AVAILABLE_ASSETS,
    );
  }

  getWarrantyClaimsDetail(id: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_WARRANTY_CLAIM_DETAILS + id,
    );
  }

  getWarrantyClaimDetailPDF(id: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GENERATE_CLAIM_PDF + '/' + id,
    );
  }

  deleteWarrantyClaim(item) {
    const body = new HttpParams().set('id', item);
    return this.http.delete(
      environment.apiBaseUrl + '/Asset/DeleteWarrantyClaim?',
      { body },
    );
  }

  submitClaim(claimData): Observable<any> {
    const params = new HttpParams()
      .set('AssetId', claimData.assetId)
      .set('Assignee', claimData.assignee)
      .set('DueDate', claimData.dueDate)
      .set('ClaimDescription', claimData.description)
      .set('ProjectId', claimData.projectId)
      .set('ClaimNumber', claimData.claimId)
      .set('Type', claimData.type)
      .set('RepairCost', claimData.repairCost);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.ADD_WARRANTY_TO_ASSETS,
      params,
    );
  }

  updateClaim(claimData, claimId: string): Observable<any> {
    const params = new HttpParams()
      .set('ClaimId', claimId)
      .set('AssetId', claimData.assetId)
      .set('Assignee', claimData.assignee)
      .set('DueDate', claimData.dueDate)
      .set('ClaimDescription', claimData.description)
      .set('ProjectId', claimData.projectId)
      .set('ClaimNumber', claimData.claimId)
      .set('Type', claimData.type)
      .set('RepairCost', claimData.repairCost);

    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.UPDATE_WARRANTY_TO_ASSETS +
        claimId,
      params,
    );
  }

  getProjectWarrantiesClaims(
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
    projectId: string,
  ) {
    var url =
      environment.apiBaseUrl +
      'Asset/' +
      API_ENDPOINTS.GET_PROJECT_WARRANTIES_CLAIMS;
    if (pageNumber >= 0) {
      url += 'PageNumber=' + pageNumber;
    }
    if (searchText) {
      url += '&SearchText=' + searchText;
    }
    if (pageSize >= 0) {
      url += '&PageSize=' + pageSize;
    }
    if (sortBy) {
      url += '&SortBy=' + sortBy;
    }
    if (sortOrder >= 0) {
      url += '&SortOrder=' + sortOrder;
    }
    if (filter >= 0 && filter != null) {
      url += '&Filter=' + filter;
    }
    if (projectId) {
      url += '&Id=' + projectId;
    }

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  addAssetToProject(assetId: string, projectId: string) {
    const params = new HttpParams()
      .set('AssetId', assetId)
      .set('ProjectId', projectId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + 'Asset/' + API_ENDPOINTS.ADD_ASSETS_TO_PROJECT,
      params,
    );
  }

  getProjectAsset(
    pageSize: number,
    searchText: string,
    sortBy: string,
    pageNumber: number,
    sortOrder: number,
    filter: number,
    projectId: string,
  ) {
    var url =
      environment.apiBaseUrl + 'Asset/' + API_ENDPOINTS.GET_PROJECT_ASSET;
    if (pageNumber >= 0) {
      url += 'PageNumber=' + pageNumber;
    }
    if (searchText) {
      url += '&SearchText=' + searchText;
    }
    if (pageSize >= 0) {
      url += '&PageSize=' + pageSize;
    }
    if (sortBy) {
      url += '&SortBy=' + sortBy;
    }
    if (sortOrder >= 0) {
      url += '&SortOrder=' + sortOrder;
    }
    if (filter >= 0 && filter != null) {
      url += '&Filter=' + filter;
    }
    if (projectId) {
      url += '&Id=' + projectId;
    }

    return this.coreHttpService.httpGetRequest(url).pipe(
      switchMap((response) => {
        return of(response);
      }),
    );
  }

  removeProjectAsset(id) {
    return this.coreHttpService.httpDeleteRequest(
      environment.apiBaseUrl + 'Asset/' + API_ENDPOINTS.REMOVE_PROJECT_ASSET,
      id,
    );
  }

  getWarrantyClaimStatusHistory(claimId: string): Observable<any> {
    return this.coreHttpService.httpGetWithoutLoaderRequest(
      environment.apiBaseUrl + API_ENDPOINTS.GET_CLAIM_STATUS_HISTORY + claimId,
    );
  }

  approveRejectWarrantyClaim(
    claimId: string,
    previousAssignee: string,
    currentStatus: number,
    Status: number,
    projectId: string,
  ) {
    const params = new HttpParams()
      .set('ClaimId', claimId)
      .set('PreviousAssignee', previousAssignee)
      .set('CurrentStatus', currentStatus)
      .set('ApprovalState', Status)
      .set('ProjectId', projectId);
    return this.coreHttpService.httpPostRequest(
      environment.apiBaseUrl + API_ENDPOINTS.APPROVE_REJECT_WARRANTY_CLAIM,
      params,
    );
  }

  getOdpPaymentRequestPDF(paymentRequestId: string): Observable<any> {
    return this.coreHttpService.httpGetRequest(
      environment.apiBaseUrl +
        API_ENDPOINTS.GET_ODP_PAYMENT_PDF_ATTACHMENT +
        paymentRequestId,
    );
  }
}
